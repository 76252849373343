@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

.social-proof {
    background-color: white;
    padding: 15px 25px 15px 25px;
    border-radius: 5px;
    position: fixed;
    bottom: 80px;
    right: 20px;
    width: 350px;
    height: auto;
    z-index: 999999;
    box-shadow: 0px 2px 22px 1px rgba(51,67,224,0.2);
    font-family: 'Poppins', sans-serif!important;
    cursor: pointer;
    transition: 0.3s;
    opacity: 0.9;
}

@media (max-width: 991px){
    .social-proof {
        top: 20px;
        left: 20px;
        right: 20px;
        width: auto;
        bottom: auto;
    }
}

.social-proof:hover {
    opacity: 1!important;
}

.social-proof-title {
    font-weight: 500;
    font-size: 1em;
    margin-bottom: 15px;
}

.social-proof-flex {
    display: grid;
    align-items: center;
    grid-template-columns: 60px auto;
    column-gap: 15px;
}

.social-proof-image {
    background-size: cover;
    background-position: center;
    width: 60px;
    height: 60px;
    border-radius: 3px;
}

.social-proof-content {
    font-size: 14px;
}

.social-proof-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    background: rgb(151,105,255);
    background: linear-gradient(141deg, rgba(151,105,255,1) 0%, rgba(29,58,255,1) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    color: white;
    font-size: 13px;
    fill: white;
}

.social-proof-control-wrapper {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99999999;;
}

.social-proof-control-button {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 100px;
    background: rgb(151,105,255);
    background: linear-gradient(141deg, rgba(151,105,255,1) 0%, rgba(29,58,255,1) 100%);
}

.social-proof-pause-play {
    position: absolute;
    top: -2px;
    right: -2px;
    background-color: white;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-proof-notifications {
    background-color: white;
    padding: 20px 15px 25px 15px;
    border-radius: 5px;
    position: fixed;
    bottom: 90px;
    right: 20px;
    width: 350px;
    max-width: 100%;
    height: auto;
    z-index: 999999;
    box-shadow: 0px 2px 22px 1px rgba(51,67,224,0.2);
    font-family: 'Poppins', sans-serif!important;
}

.social-proof-notifications .social-proof-flex {
    padding-bottom: 15px;
    padding-top: 15px;
    margin-right: 15px;
    border-bottom: 1px solid rgba(0,0,0,0.05);
    cursor: pointer;
    transition: 0.3s;
    padding-left: 15px;
    padding-right: 15px;
}

.social-proof-notifications .social-proof-content {
    font-size: 0.75em;
}

.social-proof-notifications .social-proof-flex:hover {
    background-color: rgba(0,0,0,.03);
}

.social-proof-notifications-body {
    max-height: 400px;
    overflow-y: scroll;
}

.social-proof-notifications .social-proof-close-button {
    right: 15px;
    top: 15px;
}

.social-proof-notifications-header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
}

.social-proof-notifications-header .social-proof-title {
    margin-left: 3px;
    margin-bottom: 0px;
}