@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap);
.c180-login-logo {
  max-width: 70%;
  margin-bottom: 10px;
}

.login-welcome {
  font-size: 1.8em;
  font-family: "Poppins", sans-serif !important;
  color: #0670E1;
  font-weight: 400;
  margin-top: 30px;
  margin-bottom: 50px;
}

.dashboard-login-wrapper {
  width: 100%;
  overflow: hidden;
  display: flex;
}

.dashboard-login-image {
  width: 70%;
  height: 100vh;
  background-image: url(/static/media/login-image.91a8f988.png);
  background-size: cover;
}

.dashboard-login-form-wrapper {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media screen and (max-width: 768px) {
  .dashboard-login-image {
    display: none;
  }
  .dashboard-login-form-wrapper {
    width: 100%;
    height: 100vh;
  }
}

.dashboard-login-form-wrapper form {
  width: 70%;
}

.login-input {
  width: 100%;
  margin-bottom: 15px;
  border: 0;
  border-bottom: 1px solid #707070;
  color: black;
  font-family: "Poppins", sans-serif !important;
  padding: 10px 0;
  font-size: 14px;
  background-color: transparent !important;
}

.login-input:focus {
  outline: none;
}

.login-input::-webkit-input-placeholder {
  color: #707070;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
}

.login-input:-ms-input-placeholder {
  color: #707070;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
}

.login-input::-ms-input-placeholder {
  color: #707070;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
}

.login-input::placeholder {
  color: #707070;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
}

.dashboard-login-button {
  width: 100%;
  border-radius: 5px;
  background: linear-gradient(120deg, #0676ED, #005AB9);
  color: white;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600;
  margin-top: 30px;
  height: 50px;
  border: 0;
  cursor: pointer;
  box-shadow: 0px 10px 30px #1d3bff5d;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.login-loading {
  margin-top: 45px;
}

.dashboard-login-button:hover {
  background: linear-gradient(120deg, #007bff, #0066d3);
}

.dashboard-login-link {
  color: #0670E1;
  display: block;
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 50px;
  text-decoration: underline;
  font-family: "Poppins", sans-serif !important;
}

.dashboard-help {
  font-weight: 600;
  color: #707070;
  font-family: "Poppins", sans-serif !important;
  text-align: center;
}

.trouble-link {
  font-weight: 400;
  text-decoration: underline;
  display: block;
  color: #707070;
}

.c-sidebar .nav-tabs:first-child .nav-link,
.c-sidebar .c-sidebar-close + .nav-tabs .nav-link {
  display: flex;
  align-items: center;
  height: 56px;
  padding-top: 0;
  padding-bottom: 0;
}

.c-sidebar {
  display: flex;
  flex: 0 0 289px;
  flex-direction: column;
  order: -1;
  width: 289px;
  padding: 0;
  box-shadow: none;
  color: #fff;
  background: #F5F6F9;
  border-right: 1px solid #D9D9D9;
  transition: box-shadow 0.3s 0.15s, margin-left 0.3s, margin-right 0.3s, width 0.3s, z-index 0s ease 0.3s, -webkit-transform 0.3s;
  -webkit-transition: margin-left 0.3s, margin-right 0.3s, width 0.3s, z-index 0s ease 0.3s, -webkit-box-shadow 0.3s 0.15s, -webkit-transform 0.3s;
  -webkit-transition: box-shadow 0.3s 0.15s, margin-left 0.3s, margin-right 0.3s, width 0.3s, z-index 0s ease 0.3s, -webkit-transform 0.3s;
  transition: box-shadow 0.3s 0.15s, margin-left 0.3s, margin-right 0.3s, width 0.3s, z-index 0s ease 0.3s, -webkit-transform 0.3s;
  transition: box-shadow 0.3s 0.15s, transform 0.3s, margin-left 0.3s, margin-right 0.3s, width 0.3s, z-index 0s ease 0.3s;
  transition: box-shadow 0.3s 0.15s, transform 0.3s, margin-left 0.3s, margin-right 0.3s, width 0.3s, z-index 0s ease 0.3s, -webkit-transform 0.3s;
}

@media (max-width: 1440px) and (min-width: 992px) {
  .c-sidebar {
    flex: 0 0 250px;
    width: 250px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper, html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper {
    margin-left: 259px !important;
  }
  .c-sidebar-nav-link {
    font-size: 12px !important;
  }
}

@media (max-width: 991.98px) {
  .c-sidebar {
    --is-mobile: true;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 1031;
  }
}

.c-sidebar[class*="bg-"] {
  border-color: rgba(0, 0, 21, 0.1);
}

.c-sidebar.c-sidebar-sm {
  flex: 0 0 192px;
  width: 192px;
}

html:not([dir="rtl"]) .c-sidebar.c-sidebar-sm:not(.c-sidebar-right) {
  margin-left: -192px;
}

html:not([dir="rtl"]) .c-sidebar.c-sidebar-sm.c-sidebar-right,
*[dir="rtl"] .c-sidebar.c-sidebar-sm:not(.c-sidebar-right) {
  margin-right: -192px;
}

*[dir="rtl"] .c-sidebar.c-sidebar-sm.c-sidebar-right {
  margin-left: -192px;
}

.c-sidebar.c-sidebar-lg {
  flex: 0 0 320px;
  width: 320px;
}

.c-sidebar.c-sidebar-xl {
  flex: 0 0 384px;
  width: 384px;
}

html:not([dir="rtl"]) .c-sidebar.c-sidebar-xl:not(.c-sidebar-right) {
  margin-left: -384px;
}

html:not([dir="rtl"]) .c-sidebar.c-sidebar-xl.c-sidebar-right,
*[dir="rtl"] .c-sidebar.c-sidebar-xl:not(.c-sidebar-right) {
  margin-right: -384px;
}

*[dir="rtl"] .c-sidebar.c-sidebar-xl.c-sidebar-right {
  margin-left: -384px;
}

@media (min-width: 992px) {
  .c-sidebar.c-sidebar-fixed {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 1030;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-fixed:not(.c-sidebar-right) {
    left: 0;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-fixed.c-sidebar-right,
  *[dir="rtl"] .c-sidebar.c-sidebar-fixed:not(.c-sidebar-right) {
    right: 0;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-fixed.c-sidebar-right {
    left: 0;
  }
}

.c-sidebar.c-sidebar-overlaid {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1032;
}

html:not([dir="rtl"]) .c-sidebar.c-sidebar-overlaid:not(.c-sidebar-right) {
  left: 0;
}

html:not([dir="rtl"]) .c-sidebar.c-sidebar-overlaid.c-sidebar-right,
*[dir="rtl"] .c-sidebar.c-sidebar-overlaid:not(.c-sidebar-right) {
  right: 0;
}

*[dir="rtl"] .c-sidebar.c-sidebar-overlaid.c-sidebar-right {
  left: 0;
}

.c-sidebar-close {
  position: absolute;
  width: 56px;
  height: 56px;
  background: transparent;
  border: 0;
}

html:not([dir="rtl"]) .c-sidebar-close {
  right: 0;
}

*[dir="rtl"] .c-sidebar-close {
  left: 0;
}

.c-sidebar-brand {
  background-color: white;
  display: flex;
  flex: 0 0 56px;
  align-items: center;
  justify-content: center;
}

.c-sidebar-brand .c-sidebar-brand-minimized {
  display: none;
}

.c-sidebar-header {
  flex: 0 0 auto;
  padding: 0.75rem 1rem;
  text-align: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.c-sidebar-nav {
  position: relative;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  padding: 0;
  margin-bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  padding: 10px 0;
  scroll-behavior: smooth;
}

.c-sidebar-nav.ps {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

.c-sidebar-nav.ps::-webkit-scrollbar {
  width: 0 !important;
}

.c-sidebar-nav-title {
  padding: 0.75rem 1rem;
  margin-top: 1rem;
  font-size: 80%;
  font-weight: 700;
  text-transform: uppercase;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.c-sidebar-nav-divider {
  height: 10px;
  -webkit-transition: height 0.3s;
  transition: height 0.3s;
}

.c-sidebar-nav-item {
  width: inherit;
  margin-bottom: 15px;
  padding: 0px 15px 0px 15px;
  border-left-width: 3px;
  border-left-style: solid;
  border-left-color: #F5F6F9;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.c-sidebar-nav-link, .c-sidebar-nav-dropdown-toggle {
  border-radius: 10px;
}

.c-sidebar-nav-link,
.c-sidebar-nav-dropdown-toggle {
  display: flex;
  flex: 1 1;
  align-items: center;
  padding: 0.8445rem 1rem;
  text-decoration: none;
  white-space: nowrap;
  -webkit-transition: background 0.3s, color 0.3s;
  transition: background 0.3s, color 0.3s;
}

html:not([dir="rtl"]) .c-sidebar-nav-link .badge,
html:not([dir="rtl"]) .c-sidebar-nav-dropdown-toggle .badge {
  margin-left: auto;
}

*[dir="rtl"] .c-sidebar-nav-link .badge,
*[dir="rtl"] .c-sidebar-nav-dropdown-toggle .badge {
  margin-right: auto;
}

.c-sidebar-nav-link.c-disabled,
.c-disabled.c-sidebar-nav-dropdown-toggle {
  cursor: default;
}

@media (hover: hover), (-ms-high-contrast: none) {
  .c-sidebar-nav-link:hover,
  .c-sidebar-nav-dropdown-toggle:hover {
    text-decoration: none;
  }
}

.c-sidebar-nav-icon {
  flex: 0 0 56px;
  height: 1.09375rem;
  font-size: 1.09375rem;
  text-align: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  fill: #A0A2AE;
  color: #A0A2AE;
}

.c-sidebar-nav-icon-stroked {
  flex: 0 0 56px;
  height: 1.09375rem;
  font-size: 1.09375rem;
  text-align: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  stroke: #A0A2AE;
}

html:not([dir="rtl"]) .c-sidebar-nav-icon:first-child {
  margin-left: -1rem;
}

*[dir="rtl"] .c-sidebar-nav-icon:first-child {
  margin-right: -1rem;
}

.c-sidebar-nav-dropdown {
  position: relative;
  -webkit-transition: background 0.3s ease-in-out;
  transition: background 0.3s ease-in-out;
}

.c-sidebar-nav-dropdown.c-show > .c-sidebar-nav-dropdown-items {
  max-height: 1500px;
}

html:not([dir="rtl"]) .c-sidebar-nav-dropdown.c-show > .c-sidebar-nav-dropdown-toggle::after {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

*[dir="rtl"] .c-sidebar-nav-dropdown.c-show > .c-sidebar-nav-dropdown-toggle::after {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.c-sidebar-nav-dropdown.c-show + .c-sidebar-nav-dropdown.c-show {
  margin-top: 1px;
}

.c-sidebar-nav-dropdown-toggle {
  cursor: pointer;
}

.c-sidebar-nav-dropdown-toggle::after {
  display: block;
  flex: 0 1 8px;
  height: 8px;
  content: "";
  background-repeat: no-repeat;
  background-position: center;
  transition: -webkit-transform 0.3s;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

html:not([dir="rtl"]) .c-sidebar-nav-dropdown-toggle::after {
  margin-left: auto;
}

*[dir="rtl"] .c-sidebar-nav-dropdown-toggle::after {
  margin-right: auto;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

html:not([dir="rtl"]) .c-sidebar-nav-dropdown-toggle .badge {
  margin-right: 1rem;
}

*[dir="rtl"] .c-sidebar-nav-dropdown-toggle .badge {
  margin-left: 1rem;
}

.c-sidebar-nav-dropdown-items {
  max-height: 0;
  padding: 0;
  overflow-y: hidden;
  list-style: none;
  -webkit-transition: max-height 0.3s ease-in-out;
  transition: max-height 0.3s ease-in-out;
}

html:not([dir="rtl"]) .c-sidebar-nav-dropdown-items .c-sidebar-nav-link,
html:not([dir="rtl"]) .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-toggle {
  padding-left: 56px;
}

*[dir="rtl"] .c-sidebar-nav-dropdown-items .c-sidebar-nav-link,
*[dir="rtl"] .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-toggle {
  padding-right: 56px;
}

html:not([dir="rtl"]) .c-sidebar-nav-dropdown-items .c-sidebar-nav-link .c-sidebar-nav-icon,
html:not([dir="rtl"]) .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  margin-left: -56px;
}

*[dir="rtl"] .c-sidebar-nav-dropdown-items .c-sidebar-nav-link .c-sidebar-nav-icon,
*[dir="rtl"] .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  margin-right: -56px;
}

.c-sidebar-nav-label {
  display: flex;
  padding: 0.211125rem 1rem;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.c-sidebar-nav-label:hover {
  text-decoration: none;
}

.c-sidebar-nav-label .c-sidebar-nav-icon {
  margin-top: 1px;
}

.c-sidebar-footer {
  flex: 0 0 auto;
  padding: 0.75rem 1rem;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.c-sidebar-minimizer {
  display: flex;
  flex: 0 0 50px;
  justify-content: flex-end;
  width: inherit;
  padding: 0;
  cursor: pointer;
  border: 0;
}

@media (max-width: 991.98px) {
  .c-sidebar-minimizer {
    display: none;
  }
}

.c-sidebar-minimizer::before {
  display: block;
  width: 50px;
  height: 50px;
  content: "";
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12.5px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

*[dir="rtl"] .c-sidebar-minimizer::before {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.c-sidebar-minimizer:focus,
.c-sidebar-minimizer.c-focus {
  outline: 0;
}

.c-sidebar-right .c-sidebar-minimizer {
  justify-content: flex-start;
}

html:not([dir="rtl"]) .c-sidebar-right .c-sidebar-minimizer::before {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

*[dir="rtl"] .c-sidebar-right .c-sidebar-minimizer::before {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

@media (max-width: 991.98px) {
  .c-sidebar-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1030;
    width: 100vw;
    height: 100vh;
    background-color: #000015;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }
  .c-sidebar-backdrop.c-fade {
    opacity: 0;
  }
  .c-sidebar-backdrop.c-show {
    opacity: 0.5;
  }
}

@media (min-width: 992px) {
  .c-sidebar-minimized {
    z-index: 1031;
    flex: 0 0 56px;
  }
  .c-sidebar-minimized.c-sidebar-fixed {
    z-index: 1031;
    width: 56px;
  }
  html:not([dir="rtl"]) .c-sidebar-minimized:not(.c-sidebar-right) {
    margin-left: -56px;
  }
  *[dir="rtl"] .c-sidebar-minimized:not(.c-sidebar-right) {
    margin-right: -56px;
  }
  html:not([dir="rtl"]) .c-sidebar-minimized.c-sidebar-right {
    margin-right: -56px;
    margin-left: -56px;
  }
  .c-sidebar-minimized .c-sidebar-brand-full {
    display: none;
  }
  .c-sidebar-minimized .c-sidebar-brand-minimized {
    display: block;
  }
  .c-sidebar-minimized .c-sidebar-nav {
    padding-bottom: 50px;
    overflow: visible;
  }
  .c-sidebar-minimized .c-d-minimized-none,
  .c-sidebar-minimized .c-sidebar-nav-divider,
  .c-sidebar-minimized .c-sidebar-nav-label,
  .c-sidebar-minimized .c-sidebar-nav-title,
  .c-sidebar-minimized .c-sidebar-footer,
  .c-sidebar-minimized .c-sidebar-form,
  .c-sidebar-minimized .c-sidebar-header {
    height: 0;
    padding: 0;
    margin: 0;
    visibility: hidden;
    opacity: 0;
  }
  .c-sidebar-minimized .c-sidebar-minimizer {
    position: fixed;
    bottom: 0;
    width: inherit;
  }
  html:not([dir="rtl"]) .c-sidebar-minimized .c-sidebar-minimizer::before {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  *[dir="rtl"] .c-sidebar-minimized .c-sidebar-minimizer::before,
  html:not([dir="rtl"]) .c-sidebar-minimized.c-sidebar-right .c-sidebar-minimizer::before {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  *[dir="rtl"] .c-sidebar-minimized.c-sidebar-right .c-sidebar-minimizer::before {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  html:not([dir="rtl"]) .c-sidebar-minimized.c-sidebar-right .c-sidebar-nav > .c-sidebar-nav-item:hover,
  html:not([dir="rtl"]) .c-sidebar-minimized.c-sidebar-right .c-sidebar-nav > .c-sidebar-nav-dropdown:hover {
    margin-left: -289px;
  }
  *[dir="rtl"] .c-sidebar-minimized.c-sidebar-right .c-sidebar-nav > .c-sidebar-nav-item:hover,
  *[dir="rtl"] .c-sidebar-minimized.c-sidebar-right .c-sidebar-nav > .c-sidebar-nav-dropdown:hover {
    margin-right: -289px;
  }
  .c-sidebar-minimized .c-sidebar-nav-link,
  .c-sidebar-minimized .c-sidebar-nav-dropdown-toggle,
  .c-sidebar-minimized .c-sidebar-nav-dropdown-toggle {
    overflow: hidden;
    white-space: nowrap;
    border-left: 0;
  }
  .c-sidebar-minimized .c-sidebar-nav-link:hover,
  .c-sidebar-minimized .c-sidebar-nav-dropdown-toggle:hover,
  .c-sidebar-minimized .c-sidebar-nav-dropdown-toggle:hover {
    width: 312px;
  }
  .c-sidebar-minimized .c-sidebar-nav-dropdown-toggle::after {
    display: none;
  }
  .c-sidebar-minimized .c-sidebar-nav-dropdown-items .c-sidebar-nav-link,
  .c-sidebar-minimized .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-toggle {
    width: 289px;
  }
  .c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown {
    position: relative;
  }
  .c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown > .c-sidebar-nav-dropdown-items,
  .c-sidebar-minimized
.c-sidebar-nav
> .c-sidebar-nav-dropdown
> .c-sidebar-nav-dropdown-items
.c-sidebar-nav-dropdown:not(.c-show)
> .c-sidebar-nav-dropdown-items {
    display: none;
  }
  .c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown .c-sidebar-nav-dropdown-items {
    max-height: 1500px;
  }
  .c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown:hover {
    width: 312px;
    overflow: visible;
  }
  .c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown:hover > .c-sidebar-nav-dropdown-items {
    position: absolute;
    display: inline;
  }
  html:not([dir="rtl"])
.c-sidebar-minimized
.c-sidebar-nav
> .c-sidebar-nav-dropdown:hover
> .c-sidebar-nav-dropdown-items {
    left: 56px;
  }
  *[dir="rtl"] .c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown:hover > .c-sidebar-nav-dropdown-items {
    right: 56px;
  }
  html:not([dir="rtl"])
.c-sidebar-minimized.c-sidebar-right
> .c-sidebar-nav-dropdown:hover
> .c-sidebar-nav-dropdown-items {
    left: 0;
  }
  *[dir="rtl"] .c-sidebar-minimized.c-sidebar-right > .c-sidebar-nav-dropdown:hover > .c-sidebar-nav-dropdown-items {
    right: 0;
  }
}

@media (min-width: 992px) {
  .c-sidebar-unfoldable:not(:hover) {
    z-index: 1031;
    flex: 0 0 56px;
  }
  .c-sidebar-unfoldable:not(:hover).c-sidebar-fixed {
    z-index: 1031;
    width: 56px;
  }
  html:not([dir="rtl"]) .c-sidebar-unfoldable:not(:hover):not(.c-sidebar-right) {
    margin-left: -56px;
  }
  *[dir="rtl"] .c-sidebar-unfoldable:not(:hover):not(.c-sidebar-right) {
    margin-right: -56px;
  }
  html:not([dir="rtl"]) .c-sidebar-unfoldable:not(:hover).c-sidebar-right {
    margin-right: -56px;
    margin-left: -56px;
  }
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-brand-full {
    display: none;
  }
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-brand-minimized {
    display: block;
  }
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-nav {
    padding-bottom: 50px;
    overflow: visible;
  }
  .c-sidebar-unfoldable:not(:hover) .c-d-minimized-none,
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-nav-divider,
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-nav-label,
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-nav-title,
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-footer,
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-form,
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-header {
    height: 0;
    padding: 0;
    margin: 0;
    visibility: hidden;
    opacity: 0;
  }
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-minimizer {
    position: fixed;
    bottom: 0;
    width: inherit;
  }
  html:not([dir="rtl"]) .c-sidebar-unfoldable:not(:hover) .c-sidebar-minimizer::before {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  *[dir="rtl"] .c-sidebar-unfoldable:not(:hover) .c-sidebar-minimizer::before,
  html:not([dir="rtl"]) .c-sidebar-unfoldable:not(:hover).c-sidebar-right .c-sidebar-minimizer::before {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  *[dir="rtl"] .c-sidebar-unfoldable:not(:hover).c-sidebar-right .c-sidebar-minimizer::before {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  html:not([dir="rtl"]) .c-sidebar-unfoldable:not(:hover).c-sidebar-right .c-sidebar-nav > .c-sidebar-nav-item:hover,
  html:not([dir="rtl"])
.c-sidebar-unfoldable:not(:hover).c-sidebar-right
.c-sidebar-nav
> .c-sidebar-nav-dropdown:hover {
    margin-left: -289px;
  }
  *[dir="rtl"] .c-sidebar-unfoldable:not(:hover).c-sidebar-right .c-sidebar-nav > .c-sidebar-nav-item:hover,
  *[dir="rtl"] .c-sidebar-unfoldable:not(:hover).c-sidebar-right .c-sidebar-nav > .c-sidebar-nav-dropdown:hover {
    margin-right: -289px;
  }
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-nav-link,
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-nav-dropdown-toggle,
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-nav-dropdown-toggle {
    overflow: hidden;
    white-space: nowrap;
    border-left: 0;
  }
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-nav-link:hover,
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-nav-dropdown-toggle:hover,
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-nav-dropdown-toggle:hover {
    width: 312px;
  }
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-nav-dropdown-toggle::after {
    display: none;
  }
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-nav-dropdown-items .c-sidebar-nav-link,
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-toggle {
    width: 289px;
  }
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-nav > .c-sidebar-nav-dropdown {
    position: relative;
  }
  .c-sidebar-unfoldable:not(:hover) .c-sidebar-nav > .c-sidebar-nav-dropdown > .c-sidebar-nav-dropdown-items,
  .c-sidebar-unfoldable:not(:hover)
.c-sidebar-nav
> .c-sidebar-nav-dropdown
> .c-sidebar-nav-dropdown-items
.c-sidebar-nav-dropdown:not(.c-show)
> .c-sidebar-nav-dropdown-items {
    display: none;
  }
}

.c-sidebar-unfoldable:not(:hover) .ps {
  overflow: visible !important;
  overflow: initial !important;
}

@media (min-width: 992px) {
  .c-sidebar-unfoldable {
    z-index: 1031 !important;
  }
  .c-sidebar-unfoldable:hover .c-sidebar-nav-dropdown-items {
    overflow-x: hidden;
  }
  html:not([dir="rtl"]) .c-sidebar-unfoldable:hover .c-sidebar-minimizer::before {
    -webkit-transform: rotate(-180deg);
    transform: rotate(-180deg);
  }
  *[dir="rtl"] .c-sidebar-unfoldable:hover .c-sidebar-minimizer::before {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right),
html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right) {
  margin-left: 0;
}

*[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right),
*[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right) {
  margin-right: 0;
}

@media (min-width: 992px) {
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper {
    margin-left: 289px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper {
    margin-right: 289px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper {
    margin-left: 192px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper {
    margin-right: 192px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper {
    margin-left: 320px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper {
    margin-right: 320px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper {
    margin-left: 384px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper {
    margin-right: 384px;
  }
  html:not([dir="rtl"])
.c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized
~ .c-wrapper,
  html:not([dir="rtl"])
.c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable
~ .c-wrapper,
  html:not([dir="rtl"])
.c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized
~ .c-wrapper,
  html:not([dir="rtl"])
.c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable
~ .c-wrapper {
    margin-left: 56px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper {
    margin-right: 56px;
  }
}

html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right,
html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right {
  margin-right: 0;
}

*[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right,
*[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right {
  margin-left: 0;
}

@media (min-width: 992px) {
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed ~ .c-wrapper {
    margin-right: 289px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed ~ .c-wrapper {
    margin-left: 289px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper {
    margin-right: 192px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper {
    margin-left: 192px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper {
    margin-right: 320px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper {
    margin-left: 320px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper {
    margin-right: 384px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper {
    margin-left: 384px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper {
    margin-right: 56px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper {
    margin-left: 56px;
  }
}

@media (min-width: 576px) {
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-sm-show:not(.c-sidebar-right),
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right) {
    margin-left: 0;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-sm-show:not(.c-sidebar-right),
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right) {
    margin-right: 0;
  }
}

@media (min-width: 576px) and (min-width: 992px) {
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-sm-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper {
    margin-left: 289px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-sm-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper {
    margin-right: 289px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-sm-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper {
    margin-left: 192px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-sm-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper {
    margin-right: 192px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-sm-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper {
    margin-left: 320px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-sm-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper {
    margin-right: 320px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-sm-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper {
    margin-left: 384px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-sm-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper {
    margin-right: 384px;
  }
  html:not([dir="rtl"])
.c-sidebar.c-sidebar-sm-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized
~ .c-wrapper,
  html:not([dir="rtl"])
.c-sidebar.c-sidebar-sm-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable
~ .c-wrapper,
  html:not([dir="rtl"])
.c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized
~ .c-wrapper,
  html:not([dir="rtl"])
.c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable
~ .c-wrapper {
    margin-left: 56px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-sm-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-sm-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper {
    margin-right: 56px;
  }
}

@media (min-width: 576px) {
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-sm-show.c-sidebar-right,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right {
    margin-right: 0;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-sm-show.c-sidebar-right,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right {
    margin-left: 0;
  }
}

@media (min-width: 576px) and (min-width: 992px) {
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-sm-show.c-sidebar-right.c-sidebar-fixed ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed ~ .c-wrapper {
    margin-right: 289px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-sm-show.c-sidebar-right.c-sidebar-fixed ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed ~ .c-wrapper {
    margin-left: 289px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-sm-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper {
    margin-right: 192px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-sm-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper {
    margin-left: 192px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-sm-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper {
    margin-right: 320px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-sm-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper {
    margin-left: 320px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-sm-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper {
    margin-right: 384px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-sm-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper {
    margin-left: 384px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-sm-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-sm-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper {
    margin-right: 56px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-sm-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-sm-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper {
    margin-left: 56px;
  }
}

@media (min-width: 768px) {
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-md-show:not(.c-sidebar-right),
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right) {
    margin-left: 0;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-md-show:not(.c-sidebar-right),
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right) {
    margin-right: 0;
  }
}

@media (min-width: 768px) and (min-width: 992px) {
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-md-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper {
    margin-left: 289px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-md-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper {
    margin-right: 289px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-md-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper {
    margin-left: 192px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-md-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper {
    margin-right: 192px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-md-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper {
    margin-left: 320px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-md-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper {
    margin-right: 320px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-md-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper {
    margin-left: 384px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-md-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper {
    margin-right: 384px;
  }
  html:not([dir="rtl"])
.c-sidebar.c-sidebar-md-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized
~ .c-wrapper,
  html:not([dir="rtl"])
.c-sidebar.c-sidebar-md-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable
~ .c-wrapper,
  html:not([dir="rtl"])
.c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized
~ .c-wrapper,
  html:not([dir="rtl"])
.c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable
~ .c-wrapper {
    margin-left: 56px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-md-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-md-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper {
    margin-right: 56px;
  }
}

@media (min-width: 768px) {
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-md-show.c-sidebar-right,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right {
    margin-right: 0;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-md-show.c-sidebar-right,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right {
    margin-left: 0;
  }
}

@media (min-width: 768px) and (min-width: 992px) {
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-md-show.c-sidebar-right.c-sidebar-fixed ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed ~ .c-wrapper {
    margin-right: 289px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-md-show.c-sidebar-right.c-sidebar-fixed ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed ~ .c-wrapper {
    margin-left: 289px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-md-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper {
    margin-right: 192px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-md-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper {
    margin-left: 192px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-md-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper {
    margin-right: 320px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-md-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper {
    margin-left: 320px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-md-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper {
    margin-right: 384px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-md-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper {
    margin-left: 384px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-md-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-md-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper {
    margin-right: 56px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-md-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-md-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper {
    margin-left: 56px;
  }
}

@media (min-width: 992px) {
  .c-app:not(.c-legacy-theme):not(.c-dark-theme) .c-sidebar.c-sidebar-lg-show.c-sidebar-overlaid,
  .c-app:not(.c-legacy-theme):not(.c-dark-theme) .c-sidebar.c-sidebar-show.c-sidebar-overlaid {
    border: 0;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right),
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right) {
    margin-left: 0;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right),
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right) {
    margin-right: 0;
  }
}

@media (min-width: 992px) and (min-width: 992px) {
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper {
    margin-left: 289px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper {
    margin-right: 289px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper {
    margin-left: 192px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper {
    margin-right: 192px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper {
    margin-left: 320px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper {
    margin-right: 320px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper {
    margin-left: 384px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper {
    margin-right: 384px;
  }
  html:not([dir="rtl"])
.c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized
~ .c-wrapper,
  html:not([dir="rtl"])
.c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable
~ .c-wrapper,
  html:not([dir="rtl"])
.c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized
~ .c-wrapper,
  html:not([dir="rtl"])
.c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable
~ .c-wrapper {
    margin-left: 56px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-lg-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper {
    margin-right: 56px;
  }
}

@media (min-width: 992px) {
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-lg-show.c-sidebar-right,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right {
    margin-right: 0;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-lg-show.c-sidebar-right,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right {
    margin-left: 0;
  }
}

@media (min-width: 992px) and (min-width: 992px) {
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-lg-show.c-sidebar-right.c-sidebar-fixed ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed ~ .c-wrapper {
    margin-right: 289px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-lg-show.c-sidebar-right.c-sidebar-fixed ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed ~ .c-wrapper {
    margin-left: 289px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-lg-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper {
    margin-right: 192px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-lg-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper {
    margin-left: 192px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-lg-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper {
    margin-right: 320px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-lg-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper {
    margin-left: 320px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-lg-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper {
    margin-right: 384px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-lg-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper {
    margin-left: 384px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-lg-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-lg-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper {
    margin-right: 56px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-lg-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-lg-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper {
    margin-left: 56px;
  }
}

@media (min-width: 1200px) and (max-width: 991.98px) {
  .c-app:not(.c-legacy-theme):not(.c-dark-theme) .c-sidebar.c-sidebar-xl-show,
  .c-app:not(.c-legacy-theme):not(.c-dark-theme) .c-sidebar.c-sidebar-show {
    border: 0;
  }
}

@media (min-width: 1200px) {
  .c-app:not(.c-legacy-theme):not(.c-dark-theme) .c-sidebar.c-sidebar-xl-show.c-sidebar-overlaid,
  .c-app:not(.c-legacy-theme):not(.c-dark-theme) .c-sidebar.c-sidebar-show.c-sidebar-overlaid {
    border: 0;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-xl-show:not(.c-sidebar-right),
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right) {
    margin-left: 0;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-xl-show:not(.c-sidebar-right),
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right) {
    margin-right: 0;
  }
}

@media (min-width: 1200px) and (min-width: 992px) {
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-xl-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper {
    margin-left: 289px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-xl-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed ~ .c-wrapper {
    margin-right: 289px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-xl-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper {
    margin-left: 192px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-xl-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper {
    margin-right: 192px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-xl-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper {
    margin-left: 320px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-xl-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper {
    margin-right: 320px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-xl-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper {
    margin-left: 384px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-xl-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper {
    margin-right: 384px;
  }
  html:not([dir="rtl"])
.c-sidebar.c-sidebar-xl-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized
~ .c-wrapper,
  html:not([dir="rtl"])
.c-sidebar.c-sidebar-xl-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable
~ .c-wrapper,
  html:not([dir="rtl"])
.c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized
~ .c-wrapper,
  html:not([dir="rtl"])
.c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable
~ .c-wrapper {
    margin-left: 56px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-xl-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-xl-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show:not(.c-sidebar-right).c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper {
    margin-right: 56px;
  }
}

@media (min-width: 1200px) {
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-xl-show.c-sidebar-right,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right {
    margin-right: 0;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-xl-show.c-sidebar-right,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right {
    margin-left: 0;
  }
}

@media (min-width: 1200px) and (min-width: 992px) {
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-xl-show.c-sidebar-right.c-sidebar-fixed ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed ~ .c-wrapper {
    margin-right: 289px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-xl-show.c-sidebar-right.c-sidebar-fixed ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed ~ .c-wrapper {
    margin-left: 289px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-xl-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper {
    margin-right: 192px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-xl-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-sm ~ .c-wrapper {
    margin-left: 192px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-xl-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper {
    margin-right: 320px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-xl-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-lg ~ .c-wrapper {
    margin-left: 320px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-xl-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper {
    margin-right: 384px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-xl-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-xl ~ .c-wrapper {
    margin-left: 384px;
  }
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-xl-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-xl-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper,
  html:not([dir="rtl"]) .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper {
    margin-right: 56px;
  }
  *[dir="rtl"] .c-sidebar.c-sidebar-xl-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-xl-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-minimized ~ .c-wrapper,
  *[dir="rtl"] .c-sidebar.c-sidebar-show.c-sidebar-right.c-sidebar-fixed.c-sidebar-unfoldable ~ .c-wrapper {
    margin-left: 56px;
  }
}

.c-sidebar .c-sidebar-close {
  color: #fff;
}

.c-sidebar .c-sidebar-brand {
  color: #fff;
}

.c-sidebar .c-sidebar-brand,
.c-sidebar .c-sidebar-header {
  background-color: #F5F6F9;
}

.c-sidebar .c-sidebar-form .c-form-control {
  color: #fff;
  background: rgba(0, 0, 21, 0.1);
  border: 0;
}

.c-sidebar .c-sidebar-form .c-form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.c-sidebar .c-sidebar-form .c-form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.c-sidebar .c-sidebar-form .c-form-control::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.c-sidebar .c-sidebar-form .c-form-control::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.c-sidebar .c-sidebar-nav-title {
  color: rgba(255, 255, 255, 0.6);
}

.c-sidebar .c-sidebar-nav-link,
.c-sidebar .c-sidebar-nav-dropdown-toggle {
  cursor: pointer;
  background: transparent;
  color: #A0A2AE;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 15px;
}

.c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: #A0A2AE;
}

.c-sidebar .c-sidebar-nav-link.c-active,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  color: #fff;
  background: rgba(255, 255, 255, 0.05);
}

.c-sidebar .c-sidebar-nav-link.c-active .c-sidebar-nav-icon,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: #0676ED;
}

/* Hover and active nav item*/
.c-sidebar .c-sidebar-nav-item:hover,
.c-sidebar .c-sidebar-nav-item-active {
  border-left-color: #0676ED;
}

.c-sidebar .c-sidebar-nav-item:hover > .c-sidebar-nav-link,
.c-sidebar .c-sidebar-nav-dropdown-toggle:hover,
.c-sidebar .c-sidebar-nav-item-active .c-sidebar-nav-link {
  color: black;
  fill: black;
  background: white;
}

.c-sidebar .c-sidebar-nav-link:hover > .c-sidebar-nav-icon,
.c-sidebar-nav-dropdown-toggle:hover > .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-item-active .c-sidebar-nav-icon {
  fill: #0676ED;
  color: #0676ED;
}

.c-sidebar .c-sidebar-nav-item-active .c-sidebar-nav-icon-stroked {
  stroke: #0676ED;
}

@media (hover: hover), (-ms-high-contrast: none) {
  .c-sidebar .c-sidebar-nav-item:hover {
    border-left-color: #0676ED;
  }
  .c-sidebar .c-sidebar-nav-item:hover > .c-sidebar-nav-link,
  .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    color: black;
    background: white;
  }
  .c-sidebar .c-sidebar-nav-link:hover > .c-sidebar-nav-icon, .c-sidebar-nav-dropdown-toggle:hover > .c-sidebar-nav-icon {
    fill: #0676ED;
    color: #0676ED;
  }
  .c-sidebar .c-sidebar-nav-link:hover.c-sidebar-nav-dropdown-toggle::after,
  .c-sidebar :hover.c-sidebar-nav-dropdown-toggle::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
  }
}

.c-sidebar .c-sidebar-nav-link.c-disabled,
.c-sidebar .c-disabled.c-sidebar-nav-dropdown-toggle {
  color: #b3b2b2;
  background: transparent;
}

.c-sidebar .c-sidebar-nav-link.c-disabled .c-sidebar-nav-icon,
.c-sidebar .c-disabled.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.5);
}

.c-sidebar .c-sidebar-nav-link.c-disabled:hover,
.c-sidebar .c-disabled.c-sidebar-nav-dropdown-toggle:hover {
  color: #b3b2b2;
}

.c-sidebar .c-sidebar-nav-link.c-disabled:hover .c-sidebar-nav-icon,
.c-sidebar .c-disabled.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.5);
}

.c-sidebar .c-sidebar-nav-link.c-disabled:hover.c-sidebar-nav-dropdown-toggle::after,
.c-sidebar .c-disabled:hover.c-sidebar-nav-dropdown-toggle::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

.c-sidebar .c-sidebar-nav-dropdown-toggle {
  position: relative;
}

.c-sidebar .c-sidebar-nav-dropdown-toggle::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='rgba(255, 255, 255, 0.5)' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

.c-sidebar .c-sidebar-nav-dropdown.c-show {
  background: rgba(0, 0, 0, 0.2);
}

.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link,
.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle {
  cursor: pointer;
  color: #fff;
}

.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link.c-disabled,
.c-sidebar .c-sidebar-nav-dropdown.c-show .c-disabled.c-sidebar-nav-dropdown-toggle {
  color: #b3b2b2;
  background: transparent;
}

.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link.c-disabled:hover,
.c-sidebar .c-sidebar-nav-dropdown.c-show .c-disabled.c-sidebar-nav-dropdown-toggle:hover {
  color: #b3b2b2;
}

.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link.c-disabled:hover .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-dropdown.c-show .c-disabled.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.5);
}

.c-sidebar .c-sidebar-nav-label {
  color: rgba(255, 255, 255, 0.6);
}

.c-sidebar .c-sidebar-nav-label:hover {
  color: #fff;
}

.c-sidebar .c-sidebar-nav-label .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.5);
}

.c-sidebar .c-progress {
  background-color: #596f94 !important;
}

.c-sidebar .c-sidebar-footer {
  background: rgba(0, 0, 21, 0.2);
}

.c-sidebar .c-sidebar-minimizer {
  background-color: rgba(0, 0, 21, 0.2);
}

.c-sidebar .c-sidebar-minimizer::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%238a93a2' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

.c-sidebar .c-sidebar-minimizer:focus,
.c-sidebar .c-sidebar-minimizer.c-focus {
  outline: 0;
}

.c-sidebar .c-sidebar-minimizer:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.c-sidebar .c-sidebar-minimizer:hover::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-link,
.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-dropdown-toggle {
  background: #321fdb;
}

.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-link .c-sidebar-nav-icon,
.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: #fff;
}

.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-link.c-disabled,
.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-disabled.c-sidebar-nav-dropdown-toggle {
  background: #3c4b64;
}

.c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-link.c-disabled .c-sidebar-nav-icon,
.c-sidebar.c-sidebar-minimized
.c-sidebar-nav-item:hover
> .c-disabled.c-sidebar-nav-dropdown-toggle
.c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.5);
}

.c-sidebar.c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown > .c-sidebar-nav-dropdown-items {
  background: #3c4b64;
}

.c-sidebar.c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown:hover {
  background: #321fdb;
}

.c-sidebar.c-sidebar-light {
  color: #4f5d73;
  background: #fff;
  border-right: 1px solid rgba(159, 167, 179, 0.5);
}

html:not([dir="rtl"]) .c-sidebar.c-sidebar-light.c-sidebar-right,
*[dir="rtl"] .c-sidebar.c-sidebar-light {
  border-right: 0;
  border-left: 1px solid rgba(159, 167, 179, 0.5);
}

*[dir="rtl"] .c-sidebar.c-sidebar-light.c-sidebar-right {
  border: 0;
  border-right: 1px solid rgba(159, 167, 179, 0.5);
}

.c-sidebar.c-sidebar-light .c-sidebar-close {
  color: #4f5d73;
}

.c-sidebar.c-sidebar-light .c-sidebar-brand {
  color: #fff;
  background: #321fdb;
}

.c-sidebar.c-sidebar-light .c-sidebar-header {
  background: rgba(0, 0, 21, 0.2);
}

.c-sidebar.c-sidebar-light .c-sidebar-form .c-form-control {
  color: #fff;
  background: rgba(0, 0, 21, 0.1);
  border: 0;
}

.c-sidebar.c-sidebar-light .c-sidebar-form .c-form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.c-sidebar.c-sidebar-light .c-sidebar-form .c-form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.c-sidebar.c-sidebar-light .c-sidebar-form .c-form-control::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.c-sidebar.c-sidebar-light .c-sidebar-form .c-form-control::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-title {
  color: rgba(0, 0, 21, 0.4);
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-link,
.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle {
  color: rgba(0, 0, 21, 0.8);
  background: transparent;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-link .c-sidebar-nav-icon,
.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(0, 0, 21, 0.5);
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-link.c-active,
.c-sidebar.c-sidebar-light .c-active.c-sidebar-nav-dropdown-toggle {
  color: rgba(0, 0, 21, 0.8);
  background: rgba(0, 0, 21, 0.05);
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-link.c-active .c-sidebar-nav-icon,
.c-sidebar.c-sidebar-light .c-active.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: #321fdb;
}

@media (hover: hover), (-ms-high-contrast: none) {
  .c-sidebar.c-sidebar-light .c-sidebar-nav-link:hover,
  .c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle:hover {
    color: #fff;
    background: #321fdb;
  }
  .c-sidebar.c-sidebar-light .c-sidebar-nav-link:hover .c-sidebar-nav-icon,
  .c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
    color: #fff;
  }
  .c-sidebar.c-sidebar-light .c-sidebar-nav-link:hover.c-sidebar-nav-dropdown-toggle::after,
  .c-sidebar.c-sidebar-light :hover.c-sidebar-nav-dropdown-toggle::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
  }
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-link.c-disabled,
.c-sidebar.c-sidebar-light .c-disabled.c-sidebar-nav-dropdown-toggle {
  color: #b3b2b2;
  background: transparent;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-link.c-disabled .c-sidebar-nav-icon,
.c-sidebar.c-sidebar-light .c-disabled.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(0, 0, 21, 0.5);
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-link.c-disabled:hover,
.c-sidebar.c-sidebar-light .c-disabled.c-sidebar-nav-dropdown-toggle:hover {
  color: #b3b2b2;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-link.c-disabled:hover .c-sidebar-nav-icon,
.c-sidebar.c-sidebar-light .c-disabled.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
  color: rgba(0, 0, 21, 0.5);
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-link.c-disabled:hover.c-sidebar-nav-dropdown-toggle::after,
.c-sidebar.c-sidebar-light .c-disabled:hover.c-sidebar-nav-dropdown-toggle::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle {
  position: relative;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='rgba(0, 0, 21, 0.5)' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show {
  background: rgba(0, 0, 0, 0.05);
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link,
.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle {
  color: rgba(0, 0, 21, 0.8);
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link.c-disabled,
.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show .c-disabled.c-sidebar-nav-dropdown-toggle {
  color: #b3b2b2;
  background: transparent;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link.c-disabled:hover,
.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show .c-disabled.c-sidebar-nav-dropdown-toggle:hover {
  color: #b3b2b2;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link.c-disabled:hover .c-sidebar-nav-icon,
.c-sidebar.c-sidebar-light
.c-sidebar-nav-dropdown.c-show
.c-disabled.c-sidebar-nav-dropdown-toggle:hover
.c-sidebar-nav-icon {
  color: rgba(0, 0, 21, 0.5);
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-label {
  color: rgba(0, 0, 21, 0.4);
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-label:hover {
  color: #4f5d73;
}

.c-sidebar.c-sidebar-light .c-sidebar-nav-label .c-sidebar-nav-icon {
  color: rgba(0, 0, 21, 0.5);
}

.c-sidebar.c-sidebar-light .c-sidebar-footer {
  background: rgba(0, 0, 21, 0.2);
}

.c-sidebar.c-sidebar-light .c-sidebar-minimizer {
  background-color: rgba(0, 0, 0, 0.05);
}

.c-sidebar.c-sidebar-light .c-sidebar-minimizer::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%238a93a2' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

.c-sidebar.c-sidebar-light .c-sidebar-minimizer:focus,
.c-sidebar.c-sidebar-light .c-sidebar-minimizer.c-focus {
  outline: 0;
}

.c-sidebar.c-sidebar-light .c-sidebar-minimizer:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.c-sidebar.c-sidebar-light .c-sidebar-minimizer:hover::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23768192' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

.c-sidebar.c-sidebar-light.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-link,
.c-sidebar.c-sidebar-light.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-dropdown-toggle {
  background: #321fdb;
}

.c-sidebar.c-sidebar-light.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-link .c-sidebar-nav-icon,
.c-sidebar.c-sidebar-light.c-sidebar-minimized
.c-sidebar-nav-item:hover
> .c-sidebar-nav-dropdown-toggle
.c-sidebar-nav-icon {
  color: #fff;
}

.c-sidebar.c-sidebar-light.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-link.c-disabled,
.c-sidebar.c-sidebar-light.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-disabled.c-sidebar-nav-dropdown-toggle {
  background: #fff;
}

.c-sidebar.c-sidebar-light.c-sidebar-minimized
.c-sidebar-nav-item:hover
> .c-sidebar-nav-link.c-disabled
.c-sidebar-nav-icon,
.c-sidebar.c-sidebar-light.c-sidebar-minimized
.c-sidebar-nav-item:hover
> .c-disabled.c-sidebar-nav-dropdown-toggle
.c-sidebar-nav-icon {
  color: rgba(0, 0, 21, 0.5);
}

.c-sidebar.c-sidebar-light.c-sidebar-minimized
.c-sidebar-nav
> .c-sidebar-nav-dropdown
> .c-sidebar-nav-dropdown-items {
  background: #fff;
}

.c-sidebar.c-sidebar-light.c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown:hover {
  background: #321fdb;
}

.c-legacy-theme .c-sidebar {
  color: #fff;
  background: #2f353a;
}

*[dir="rtl"] .c-legacy-theme .c-sidebar.c-sidebar-right {
  border: 0;
}

.c-legacy-theme .c-sidebar .c-sidebar-close {
  color: #fff;
}

.c-legacy-theme .c-sidebar .c-sidebar-brand {
  color: #23282c;
  background: #fff;
}

.c-legacy-theme .c-sidebar .c-sidebar-header {
  background: rgba(0, 0, 0, 0.2);
}

.c-legacy-theme .c-sidebar .c-sidebar-form .c-form-control {
  color: #fff;
  background: rgba(0, 0, 0, 0.1);
  border: 0;
}

.c-legacy-theme .c-sidebar .c-sidebar-form .c-form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.c-legacy-theme .c-sidebar .c-sidebar-form .c-form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.c-legacy-theme .c-sidebar .c-sidebar-form .c-form-control::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.c-legacy-theme .c-sidebar .c-sidebar-form .c-form-control::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.c-legacy-theme .c-sidebar .c-sidebar-nav-title {
  color: rgba(255, 255, 255, 0.6);
}

.c-legacy-theme .c-sidebar .c-sidebar-nav-link,
.c-legacy-theme .c-sidebar .c-sidebar-nav-dropdown-toggle {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.8);
  background: transparent;
}

.c-legacy-theme .c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon,
.c-legacy-theme .c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.5);
}

.c-legacy-theme .c-sidebar .c-sidebar-nav-link.c-active,
.c-legacy-theme .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  color: #fff;
  background: rgba(255, 255, 255, 0.05);
}

.c-legacy-theme .c-sidebar .c-sidebar-nav-link.c-active .c-sidebar-nav-icon,
.c-legacy-theme .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: #fff;
}

@media (hover: hover), (-ms-high-contrast: none) {
  .c-legacy-theme .c-sidebar .c-sidebar-nav-link:hover,
  .c-legacy-theme .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    color: #fff;
    background: #20a8d8;
  }
  .c-legacy-theme .c-sidebar .c-sidebar-nav-link:hover .c-sidebar-nav-icon,
  .c-legacy-theme .c-sidebar .c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
    color: #fff;
  }
  .c-legacy-theme .c-sidebar .c-sidebar-nav-link:hover.c-sidebar-nav-dropdown-toggle::after,
  .c-legacy-theme .c-sidebar :hover.c-sidebar-nav-dropdown-toggle::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
  }
}

.c-legacy-theme .c-sidebar .c-sidebar-nav-link.c-disabled,
.c-legacy-theme .c-sidebar .c-disabled.c-sidebar-nav-dropdown-toggle {
  color: #b3b2b2;
  background: transparent;
}

.c-legacy-theme .c-sidebar .c-sidebar-nav-link.c-disabled .c-sidebar-nav-icon,
.c-legacy-theme .c-sidebar .c-disabled.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.5);
}

.c-legacy-theme .c-sidebar .c-sidebar-nav-link.c-disabled:hover,
.c-legacy-theme .c-sidebar .c-disabled.c-sidebar-nav-dropdown-toggle:hover {
  color: #b3b2b2;
}

.c-legacy-theme .c-sidebar .c-sidebar-nav-link.c-disabled:hover .c-sidebar-nav-icon,
.c-legacy-theme .c-sidebar .c-disabled.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.5);
}

.c-legacy-theme .c-sidebar .c-sidebar-nav-link.c-disabled:hover.c-sidebar-nav-dropdown-toggle::after,
.c-legacy-theme .c-sidebar .c-disabled:hover.c-sidebar-nav-dropdown-toggle::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

.c-legacy-theme .c-sidebar .c-sidebar-nav-dropdown-toggle {
  position: relative;
}

.c-legacy-theme .c-sidebar .c-sidebar-nav-dropdown-toggle::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='rgba(255, 255, 255, 0.5)' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

.c-legacy-theme .c-sidebar .c-sidebar-nav-dropdown.c-show {
  background: rgba(0, 0, 0, 0.2);
}

.c-legacy-theme .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link,
.c-legacy-theme .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle {
  color: #fff;
}

.c-legacy-theme .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link.c-disabled,
.c-legacy-theme .c-sidebar .c-sidebar-nav-dropdown.c-show .c-disabled.c-sidebar-nav-dropdown-toggle {
  color: #b3b2b2;
  background: transparent;
}

.c-legacy-theme .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link.c-disabled:hover,
.c-legacy-theme .c-sidebar .c-sidebar-nav-dropdown.c-show .c-disabled.c-sidebar-nav-dropdown-toggle:hover {
  color: #b3b2b2;
}

.c-legacy-theme .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link.c-disabled:hover .c-sidebar-nav-icon,
.c-legacy-theme
.c-sidebar
.c-sidebar-nav-dropdown.c-show
.c-disabled.c-sidebar-nav-dropdown-toggle:hover
.c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.5);
}

.c-legacy-theme .c-sidebar .c-sidebar-nav-label {
  color: rgba(255, 255, 255, 0.6);
}

.c-legacy-theme .c-sidebar .c-sidebar-nav-label:hover {
  color: #fff;
}

.c-legacy-theme .c-sidebar .c-sidebar-nav-label .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.5);
}

.c-legacy-theme .c-sidebar .c-progress {
  background-color: #515c64 !important;
}

.c-legacy-theme .c-sidebar .c-sidebar-footer {
  background: rgba(0, 0, 0, 0.2);
}

.c-legacy-theme .c-sidebar .c-sidebar-minimizer {
  background-color: rgba(0, 0, 0, 0.2);
}

.c-legacy-theme .c-sidebar .c-sidebar-minimizer::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%2373818f' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

.c-legacy-theme .c-sidebar .c-sidebar-minimizer:focus,
.c-legacy-theme .c-sidebar .c-sidebar-minimizer.c-focus {
  outline: 0;
}

.c-legacy-theme .c-sidebar .c-sidebar-minimizer:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.c-legacy-theme .c-sidebar .c-sidebar-minimizer:hover::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

.c-legacy-theme .c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-link,
.c-legacy-theme .c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-dropdown-toggle {
  background: #20a8d8;
}

.c-legacy-theme .c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-link .c-sidebar-nav-icon,
.c-legacy-theme
.c-sidebar.c-sidebar-minimized
.c-sidebar-nav-item:hover
> .c-sidebar-nav-dropdown-toggle
.c-sidebar-nav-icon {
  color: #fff;
}

.c-legacy-theme .c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-link.c-disabled,
.c-legacy-theme .c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-disabled.c-sidebar-nav-dropdown-toggle {
  background: #2f353a;
}

.c-legacy-theme
.c-sidebar.c-sidebar-minimized
.c-sidebar-nav-item:hover
> .c-sidebar-nav-link.c-disabled
.c-sidebar-nav-icon,
.c-legacy-theme
.c-sidebar.c-sidebar-minimized
.c-sidebar-nav-item:hover
> .c-disabled.c-sidebar-nav-dropdown-toggle
.c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.5);
}

.c-legacy-theme
.c-sidebar.c-sidebar-minimized
.c-sidebar-nav
> .c-sidebar-nav-dropdown
> .c-sidebar-nav-dropdown-items {
  background: #2f353a;
}

.c-legacy-theme .c-sidebar.c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown:hover {
  background: #20a8d8;
}

.c-legacy-theme .c-sidebar.c-sidebar-light {
  color: #23282c;
  background: #fff;
  border-right: 1px solid rgba(143, 156, 166, 0.5);
}

html:not([dir="rtl"]) .c-legacy-theme .c-sidebar.c-sidebar-light.c-sidebar-right,
*[dir="rtl"] .c-legacy-theme .c-sidebar.c-sidebar-light {
  border-right: 0;
  border-left: 1px solid rgba(143, 156, 166, 0.5);
}

*[dir="rtl"] .c-legacy-theme .c-sidebar.c-sidebar-light.c-sidebar-right {
  border: 0;
  border-right: 1px solid rgba(143, 156, 166, 0.5);
}

.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-close {
  color: #23282c;
}

.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-brand {
  color: #fff;
  background: #20a8d8;
}

.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-header {
  background: rgba(0, 0, 0, 0.2);
}

.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-form .c-form-control {
  color: #fff;
  background: rgba(0, 0, 0, 0.1);
  border: 0;
}

.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-form .c-form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-form .c-form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-form .c-form-control::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-form .c-form-control::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-nav-title {
  color: rgba(0, 0, 0, 0.4);
}

.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-nav-link,
.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle {
  color: rgba(0, 0, 0, 0.8);
  background: transparent;
}

.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-nav-link .c-sidebar-nav-icon,
.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(0, 0, 0, 0.5);
}

.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-nav-link.c-active,
.c-legacy-theme .c-sidebar.c-sidebar-light .c-active.c-sidebar-nav-dropdown-toggle {
  color: rgba(0, 0, 0, 0.8);
  background: rgba(0, 0, 0, 0.05);
}

.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-nav-link.c-active .c-sidebar-nav-icon,
.c-legacy-theme .c-sidebar.c-sidebar-light .c-active.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: #20a8d8;
}

@media (hover: hover), (-ms-high-contrast: none) {
  .c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-nav-link:hover,
  .c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle:hover {
    color: #fff;
    background: #20a8d8;
  }
  .c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-nav-link:hover .c-sidebar-nav-icon,
  .c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
    color: #fff;
  }
  .c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-nav-link:hover.c-sidebar-nav-dropdown-toggle::after,
  .c-legacy-theme .c-sidebar.c-sidebar-light :hover.c-sidebar-nav-dropdown-toggle::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
  }
}

.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-nav-link.c-disabled,
.c-legacy-theme .c-sidebar.c-sidebar-light .c-disabled.c-sidebar-nav-dropdown-toggle {
  color: #b3b2b2;
  background: transparent;
}

.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-nav-link.c-disabled .c-sidebar-nav-icon,
.c-legacy-theme .c-sidebar.c-sidebar-light .c-disabled.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(0, 0, 0, 0.5);
}

.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-nav-link.c-disabled:hover,
.c-legacy-theme .c-sidebar.c-sidebar-light .c-disabled.c-sidebar-nav-dropdown-toggle:hover {
  color: #b3b2b2;
}

.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-nav-link.c-disabled:hover .c-sidebar-nav-icon,
.c-legacy-theme .c-sidebar.c-sidebar-light .c-disabled.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
  color: rgba(0, 0, 0, 0.5);
}

.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-nav-link.c-disabled:hover.c-sidebar-nav-dropdown-toggle::after,
.c-legacy-theme .c-sidebar.c-sidebar-light .c-disabled:hover.c-sidebar-nav-dropdown-toggle::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle {
  position: relative;
}

.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='rgba(0, 0, 0, 0.5)' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show {
  background: rgba(0, 0, 0, 0.05);
}

.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link,
.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle {
  color: rgba(0, 0, 0, 0.8);
}

.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link.c-disabled,
.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show .c-disabled.c-sidebar-nav-dropdown-toggle {
  color: #b3b2b2;
  background: transparent;
}

.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link.c-disabled:hover,
.c-legacy-theme
.c-sidebar.c-sidebar-light
.c-sidebar-nav-dropdown.c-show
.c-disabled.c-sidebar-nav-dropdown-toggle:hover {
  color: #b3b2b2;
}

.c-legacy-theme
.c-sidebar.c-sidebar-light
.c-sidebar-nav-dropdown.c-show
.c-sidebar-nav-link.c-disabled:hover
.c-sidebar-nav-icon,
.c-legacy-theme
.c-sidebar.c-sidebar-light
.c-sidebar-nav-dropdown.c-show
.c-disabled.c-sidebar-nav-dropdown-toggle:hover
.c-sidebar-nav-icon {
  color: rgba(0, 0, 0, 0.5);
}

.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-nav-label {
  color: rgba(0, 0, 0, 0.4);
}

.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-nav-label:hover {
  color: #23282c;
}

.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-nav-label .c-sidebar-nav-icon {
  color: rgba(0, 0, 0, 0.5);
}

.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-footer {
  background: rgba(0, 0, 0, 0.2);
}

.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-minimizer {
  background-color: rgba(0, 0, 0, 0.05);
}

.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-minimizer::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%2373818f' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-minimizer:focus,
.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-minimizer.c-focus {
  outline: 0;
}

.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-minimizer:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.c-legacy-theme .c-sidebar.c-sidebar-light .c-sidebar-minimizer:hover::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%235c6873' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

.c-legacy-theme .c-sidebar.c-sidebar-light.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-link,
.c-legacy-theme
.c-sidebar.c-sidebar-light.c-sidebar-minimized
.c-sidebar-nav-item:hover
> .c-sidebar-nav-dropdown-toggle {
  background: #20a8d8;
}

.c-legacy-theme
.c-sidebar.c-sidebar-light.c-sidebar-minimized
.c-sidebar-nav-item:hover
> .c-sidebar-nav-link
.c-sidebar-nav-icon,
.c-legacy-theme
.c-sidebar.c-sidebar-light.c-sidebar-minimized
.c-sidebar-nav-item:hover
> .c-sidebar-nav-dropdown-toggle
.c-sidebar-nav-icon {
  color: #fff;
}

.c-legacy-theme
.c-sidebar.c-sidebar-light.c-sidebar-minimized
.c-sidebar-nav-item:hover
> .c-sidebar-nav-link.c-disabled,
.c-legacy-theme
.c-sidebar.c-sidebar-light.c-sidebar-minimized
.c-sidebar-nav-item:hover
> .c-disabled.c-sidebar-nav-dropdown-toggle {
  background: #fff;
}

.c-legacy-theme
.c-sidebar.c-sidebar-light.c-sidebar-minimized
.c-sidebar-nav-item:hover
> .c-sidebar-nav-link.c-disabled
.c-sidebar-nav-icon,
.c-legacy-theme
.c-sidebar.c-sidebar-light.c-sidebar-minimized
.c-sidebar-nav-item:hover
> .c-disabled.c-sidebar-nav-dropdown-toggle
.c-sidebar-nav-icon {
  color: rgba(0, 0, 0, 0.5);
}

.c-legacy-theme
.c-sidebar.c-sidebar-light.c-sidebar-minimized
.c-sidebar-nav
> .c-sidebar-nav-dropdown
> .c-sidebar-nav-dropdown-items {
  background: #fff;
}

.c-legacy-theme .c-sidebar.c-sidebar-light.c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown:hover {
  background: #20a8d8;
}

.c-dark-theme .c-sidebar {
  color: #e1e1e1;
  background: #282933;
  border: 0;
}

html:not([dir="rtl"]) .c-dark-theme .c-sidebar.c-sidebar-right,
*[dir="rtl"] .c-dark-theme .c-sidebar,
*[dir="rtl"] .c-dark-theme .c-sidebar.c-sidebar-right {
  border: 0;
}

.c-dark-theme .c-sidebar .c-sidebar-nav-item {
  border-left-color: #282933;
}

.c-dark-theme .c-sidebar .c-sidebar-nav-item-active {
  border-left-color: #0676ED;
}

.c-dark-theme .c-sidebar .c-sidebar-nav-item-active .c-sidebar-nav-link,
.c-dark-theme .c-sidebar .c-sidebar-nav-item-active .c-sidebar-nav-link:hover {
  border-left-color: #0676ED;
  color: white;
  fill: white;
  background-color: #0676ED;
  opacity: 1 !important;
}

.c-dark-theme .c-sidebar .c-sidebar-brand {
  background: #282933;
}

.c-dark-theme .c-sidebar .c-sidebar-close {
  color: #e1e1e1;
}

.c-dark-theme .c-sidebar .c-sidebar-header {
  background: rgba(0, 0, 21, 0.2);
}

.c-dark-theme .c-sidebar .c-sidebar-form .c-form-control {
  color: #fff;
  background: rgba(0, 0, 21, 0.1);
  border: 0;
}

.c-dark-theme .c-sidebar .c-sidebar-form .c-form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.c-dark-theme .c-sidebar .c-sidebar-form .c-form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.c-dark-theme .c-sidebar .c-sidebar-form .c-form-control::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.c-dark-theme .c-sidebar .c-sidebar-form .c-form-control::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.c-dark-theme .c-sidebar .c-sidebar-nav-title {
  color: rgba(255, 255, 255, 0.6);
}

.c-dark-theme .c-sidebar .c-sidebar-nav-link,
.c-dark-theme .c-sidebar .c-sidebar-nav-dropdown-toggle {
  color: rgba(255, 255, 255, 0.8);
  background: transparent;
}

.c-dark-theme .c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.6);
}

.c-dark-theme .c-sidebar .c-sidebar-nav-link.c-active,
.c-dark-theme .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  color: #fff;
  background: rgba(255, 255, 255, 0.05);
}

.c-dark-theme .c-sidebar .c-sidebar-nav-link.c-active .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: #fff;
}

@media (hover: hover), (-ms-high-contrast: none) {
  .c-dark-theme .c-sidebar .c-sidebar-nav-link:hover,
  .c-dark-theme .c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
    color: #fff;
    background: #0676ED;
    fill: white;
  }
  .c-dark-theme .c-sidebar .c-sidebar-nav-link:hover .c-sidebar-nav-icon,
  .c-dark-theme .c-sidebar .c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
    color: #fff;
  }
  .c-dark-theme .c-sidebar .c-sidebar-nav-link:hover.c-sidebar-nav-dropdown-toggle::after,
  .c-dark-theme .c-sidebar :hover.c-sidebar-nav-dropdown-toggle::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
  }
}

.c-dark-theme .c-sidebar .c-sidebar-nav-link.c-disabled,
.c-dark-theme .c-sidebar .c-disabled.c-sidebar-nav-dropdown-toggle {
  color: #b3b2b2;
  background: transparent;
}

.c-dark-theme .c-sidebar .c-sidebar-nav-link.c-disabled .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar .c-disabled.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.6);
}

.c-dark-theme .c-sidebar .c-sidebar-nav-link.c-disabled:hover,
.c-dark-theme .c-sidebar .c-disabled.c-sidebar-nav-dropdown-toggle:hover {
  color: #b3b2b2;
}

.c-dark-theme .c-sidebar .c-sidebar-nav-link.c-disabled:hover .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar .c-disabled.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.6);
}

.c-dark-theme .c-sidebar .c-sidebar-nav-link.c-disabled:hover.c-sidebar-nav-dropdown-toggle::after,
.c-dark-theme .c-sidebar .c-disabled:hover.c-sidebar-nav-dropdown-toggle::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

.c-dark-theme .c-sidebar .c-sidebar-nav-dropdown-toggle {
  position: relative;
}

.c-dark-theme .c-sidebar .c-sidebar-nav-dropdown-toggle::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='rgba(255, 255, 255, 0.5)' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

.c-dark-theme .c-sidebar .c-sidebar-nav-dropdown.c-show {
  background: rgba(0, 0, 0, 0.2);
}

.c-dark-theme .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link,
.c-dark-theme .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle {
  color: #fff;
}

.c-dark-theme .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link.c-disabled,
.c-dark-theme .c-sidebar .c-sidebar-nav-dropdown.c-show .c-disabled.c-sidebar-nav-dropdown-toggle {
  color: #b3b2b2;
  background: transparent;
}

.c-dark-theme .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link.c-disabled:hover,
.c-dark-theme .c-sidebar .c-sidebar-nav-dropdown.c-show .c-disabled.c-sidebar-nav-dropdown-toggle:hover {
  color: #b3b2b2;
}

.c-dark-theme .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link.c-disabled:hover .c-sidebar-nav-icon,
.c-dark-theme
.c-sidebar
.c-sidebar-nav-dropdown.c-show
.c-disabled.c-sidebar-nav-dropdown-toggle:hover
.c-sidebar-nav-icon,
.c-dark-theme .c-sidebar .c-sidebar-nav-label {
  color: rgba(255, 255, 255, 0.6);
}

.c-dark-theme .c-sidebar .c-sidebar-nav-label:hover {
  color: #e1e1e1;
}

.c-dark-theme .c-sidebar .c-sidebar-nav-label .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.6);
}

.c-dark-theme .c-sidebar .c-progress {
  background-color: #4f505e !important;
}

.c-dark-theme .c-sidebar .c-sidebar-footer {
  background: rgba(0, 0, 21, 0.2);
}

.c-dark-theme .c-sidebar .c-sidebar-minimizer {
  background-color: rgba(0, 0, 21, 0.2);
}

.c-dark-theme .c-sidebar .c-sidebar-minimizer::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='rgba(255, 255, 255, 0.5)' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

.c-dark-theme .c-sidebar .c-sidebar-minimizer:focus,
.c-dark-theme .c-sidebar .c-sidebar-minimizer.c-focus {
  outline: 0;
}

.c-dark-theme .c-sidebar .c-sidebar-minimizer:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.c-dark-theme .c-sidebar .c-sidebar-minimizer:hover::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

.c-dark-theme .c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-link,
.c-dark-theme .c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-dropdown-toggle {
  background: #4638c2;
}

.c-dark-theme .c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-link .c-sidebar-nav-icon,
.c-dark-theme
.c-sidebar.c-sidebar-minimized
.c-sidebar-nav-item:hover
> .c-sidebar-nav-dropdown-toggle
.c-sidebar-nav-icon {
  color: #fff;
}

.c-dark-theme .c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-sidebar-nav-link.c-disabled,
.c-dark-theme .c-sidebar.c-sidebar-minimized .c-sidebar-nav-item:hover > .c-disabled.c-sidebar-nav-dropdown-toggle {
  background: #282933;
}

.c-dark-theme
.c-sidebar.c-sidebar-minimized
.c-sidebar-nav-item:hover
> .c-sidebar-nav-link.c-disabled
.c-sidebar-nav-icon,
.c-dark-theme
.c-sidebar.c-sidebar-minimized
.c-sidebar-nav-item:hover
> .c-disabled.c-sidebar-nav-dropdown-toggle
.c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.6);
}

.c-dark-theme .c-sidebar.c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown > .c-sidebar-nav-dropdown-items {
  background: #282933;
}

.c-dark-theme .c-sidebar.c-sidebar-minimized .c-sidebar-nav > .c-sidebar-nav-dropdown:hover,
.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-primary,
.c-dark-theme .c-sidebar-nav-link-primary.c-sidebar-nav-dropdown-toggle {
  background: #4638c2;
}

.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-primary .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar-nav-link-primary.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.7);
}

.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-primary:hover,
.c-dark-theme .c-sidebar-nav-link-primary.c-sidebar-nav-dropdown-toggle:hover {
  background: #3f32ae;
}

.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-primary:hover .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar-nav-link-primary.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
  color: #fff;
}

.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-secondary,
.c-dark-theme .c-sidebar-nav-link-secondary.c-sidebar-nav-dropdown-toggle {
  background: #4c4f54;
}

.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-secondary .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar-nav-link-secondary.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.7);
}

.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-secondary:hover,
.c-dark-theme .c-sidebar-nav-link-secondary.c-sidebar-nav-dropdown-toggle:hover {
  background: #404247;
}

.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-secondary:hover .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar-nav-link-secondary.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
  color: #fff;
}

.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-success,
.c-dark-theme .c-sidebar-nav-link-success.c-sidebar-nav-dropdown-toggle {
  background: #45a164;
}

.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-success .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar-nav-link-success.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.7);
}

.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-success:hover,
.c-dark-theme .c-sidebar-nav-link-success.c-sidebar-nav-dropdown-toggle:hover {
  background: #3d8f59;
}

.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-success:hover .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar-nav-link-success.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
  color: #fff;
}

.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-info,
.c-dark-theme .c-sidebar-nav-link-info.c-sidebar-nav-dropdown-toggle {
  background: #4799eb;
}

.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-info .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar-nav-link-info.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.7);
}

.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-info:hover,
.c-dark-theme .c-sidebar-nav-link-info.c-sidebar-nav-dropdown-toggle:hover {
  background: #308ce8;
}

.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-info:hover .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar-nav-link-info.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
  color: #fff;
}

.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-warning,
.c-dark-theme .c-sidebar-nav-link-warning.c-sidebar-nav-dropdown-toggle {
  background: #e1a82d;
}

.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-warning .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar-nav-link-warning.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.7);
}

.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-warning:hover,
.c-dark-theme .c-sidebar-nav-link-warning.c-sidebar-nav-dropdown-toggle:hover {
  background: #d69c1f;
}

.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-warning:hover .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar-nav-link-warning.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
  color: #fff;
}

.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-danger,
.c-dark-theme .c-sidebar-nav-link-danger.c-sidebar-nav-dropdown-toggle {
  background: #d16767;
}

.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-danger .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar-nav-link-danger.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.7);
}

.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-danger:hover,
.c-dark-theme .c-sidebar-nav-link-danger.c-sidebar-nav-dropdown-toggle:hover {
  background: #cb5353;
}

.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-danger:hover .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar-nav-link-danger.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
  color: #fff;
}

.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-light,
.c-dark-theme .c-sidebar-nav-link-light.c-sidebar-nav-dropdown-toggle {
  background: #1c1d23;
}

.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-light .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar-nav-link-light.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.7);
}

.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-light:hover,
.c-dark-theme .c-sidebar-nav-link-light.c-sidebar-nav-dropdown-toggle:hover {
  background: #15151c;
}

.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-light:hover .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar-nav-link-light.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
  color: #fff;
}

.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-dark,
.c-dark-theme .c-sidebar-nav-link-dark.c-sidebar-nav-dropdown-toggle {
  background: #181924;
}

.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-dark .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar-nav-link-dark.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.7);
}

.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-dark:hover,
.c-dark-theme .c-sidebar-nav-link-dark.c-sidebar-nav-dropdown-toggle:hover {
  background: #0e0e15;
}

.c-dark-theme .c-sidebar-nav-link.c-sidebar-nav-link-dark:hover .c-sidebar-nav-icon,
.c-dark-theme .c-sidebar-nav-link-dark.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
  color: #fff;
}

.c-legacy-theme .c-sidebar-nav-link.c-sidebar-nav-link-primary,
.c-legacy-theme .c-sidebar-nav-link-primary.c-sidebar-nav-dropdown-toggle {
  background: #20a8d8;
}

.c-legacy-theme .c-sidebar-nav-link.c-sidebar-nav-link-primary .c-sidebar-nav-icon,
.c-legacy-theme .c-sidebar-nav-link-primary.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.7);
}

.c-legacy-theme .c-sidebar-nav-link.c-sidebar-nav-link-primary:hover,
.c-legacy-theme .c-sidebar-nav-link-primary.c-sidebar-nav-dropdown-toggle:hover {
  background: #1d97c2;
}

.c-legacy-theme .c-sidebar-nav-link.c-sidebar-nav-link-primary:hover .c-sidebar-nav-icon,
.c-legacy-theme .c-sidebar-nav-link-primary.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
  color: #fff;
}

.c-legacy-theme .c-sidebar-nav-link.c-sidebar-nav-link-secondary,
.c-legacy-theme .c-sidebar-nav-link-secondary.c-sidebar-nav-dropdown-toggle {
  background: #c8ced3;
}

.c-legacy-theme .c-sidebar-nav-link.c-sidebar-nav-link-secondary .c-sidebar-nav-icon,
.c-legacy-theme .c-sidebar-nav-link-secondary.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.7);
}

.c-legacy-theme .c-sidebar-nav-link.c-sidebar-nav-link-secondary:hover,
.c-legacy-theme .c-sidebar-nav-link-secondary.c-sidebar-nav-dropdown-toggle:hover {
  background: #bac1c8;
}

.c-legacy-theme .c-sidebar-nav-link.c-sidebar-nav-link-secondary:hover .c-sidebar-nav-icon,
.c-legacy-theme .c-sidebar-nav-link-secondary.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
  color: #fff;
}

.c-legacy-theme .c-sidebar-nav-link.c-sidebar-nav-link-success,
.c-legacy-theme .c-sidebar-nav-link-success.c-sidebar-nav-dropdown-toggle {
  background: #4dbd74;
}

.c-legacy-theme .c-sidebar-nav-link.c-sidebar-nav-link-success .c-sidebar-nav-icon,
.c-legacy-theme .c-sidebar-nav-link-success.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.7);
}

.c-legacy-theme .c-sidebar-nav-link.c-sidebar-nav-link-success:hover,
.c-legacy-theme .c-sidebar-nav-link-success.c-sidebar-nav-dropdown-toggle:hover {
  background: #41af67;
}

.c-legacy-theme .c-sidebar-nav-link.c-sidebar-nav-link-success:hover .c-sidebar-nav-icon,
.c-legacy-theme .c-sidebar-nav-link-success.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
  color: #fff;
}

.c-legacy-theme .c-sidebar-nav-link.c-sidebar-nav-link-info,
.c-legacy-theme .c-sidebar-nav-link-info.c-sidebar-nav-dropdown-toggle {
  background: #63c2de;
}

.c-legacy-theme .c-sidebar-nav-link.c-sidebar-nav-link-info .c-sidebar-nav-icon,
.c-legacy-theme .c-sidebar-nav-link-info.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.7);
}

.c-legacy-theme .c-sidebar-nav-link.c-sidebar-nav-link-info:hover,
.c-legacy-theme .c-sidebar-nav-link-info.c-sidebar-nav-dropdown-toggle:hover {
  background: #4ebada;
}

.c-legacy-theme .c-sidebar-nav-link.c-sidebar-nav-link-info:hover .c-sidebar-nav-icon,
.c-legacy-theme .c-sidebar-nav-link-info.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
  color: #fff;
}

.c-legacy-theme .c-sidebar-nav-link.c-sidebar-nav-link-warning,
.c-legacy-theme .c-sidebar-nav-link-warning.c-sidebar-nav-dropdown-toggle {
  background: #ffc107;
}

.c-legacy-theme .c-sidebar-nav-link.c-sidebar-nav-link-warning .c-sidebar-nav-icon,
.c-legacy-theme .c-sidebar-nav-link-warning.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.7);
}

.c-legacy-theme .c-sidebar-nav-link.c-sidebar-nav-link-warning:hover,
.c-legacy-theme .c-sidebar-nav-link-warning.c-sidebar-nav-dropdown-toggle:hover {
  background: #edb100;
}

.c-legacy-theme .c-sidebar-nav-link.c-sidebar-nav-link-warning:hover .c-sidebar-nav-icon,
.c-legacy-theme .c-sidebar-nav-link-warning.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
  color: #fff;
}

.c-legacy-theme .c-sidebar-nav-link.c-sidebar-nav-link-danger,
.c-legacy-theme .c-sidebar-nav-link-danger.c-sidebar-nav-dropdown-toggle {
  background: #f86c6b;
}

.c-legacy-theme .c-sidebar-nav-link.c-sidebar-nav-link-danger .c-sidebar-nav-icon,
.c-legacy-theme .c-sidebar-nav-link-danger.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.7);
}

.c-legacy-theme .c-sidebar-nav-link.c-sidebar-nav-link-danger:hover,
.c-legacy-theme .c-sidebar-nav-link-danger.c-sidebar-nav-dropdown-toggle:hover {
  background: #f75453;
}

.c-legacy-theme .c-sidebar-nav-link.c-sidebar-nav-link-danger:hover .c-sidebar-nav-icon,
.c-legacy-theme .c-sidebar-nav-link-danger.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
  color: #fff;
}

.c-legacy-theme .c-sidebar-nav-link.c-sidebar-nav-link-light,
.c-legacy-theme .c-sidebar-nav-link-light.c-sidebar-nav-dropdown-toggle {
  background: #f0f3f5;
}

.c-legacy-theme .c-sidebar-nav-link.c-sidebar-nav-link-light .c-sidebar-nav-icon,
.c-legacy-theme .c-sidebar-nav-link-light.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.7);
}

.c-legacy-theme .c-sidebar-nav-link.c-sidebar-nav-link-light:hover,
.c-legacy-theme .c-sidebar-nav-link-light.c-sidebar-nav-dropdown-toggle:hover {
  background: #e1e7eb;
}

.c-legacy-theme .c-sidebar-nav-link.c-sidebar-nav-link-light:hover .c-sidebar-nav-icon,
.c-legacy-theme .c-sidebar-nav-link-light.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
  color: #fff;
}

.c-legacy-theme .c-sidebar-nav-link.c-sidebar-nav-link-dark,
.c-legacy-theme .c-sidebar-nav-link-dark.c-sidebar-nav-dropdown-toggle {
  background: #2f353a;
}

.c-legacy-theme .c-sidebar-nav-link.c-sidebar-nav-link-dark .c-sidebar-nav-icon,
.c-legacy-theme .c-sidebar-nav-link-dark.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.7);
}

.c-legacy-theme .c-sidebar-nav-link.c-sidebar-nav-link-dark:hover,
.c-legacy-theme .c-sidebar-nav-link-dark.c-sidebar-nav-dropdown-toggle:hover {
  background: #24282c;
}

.c-legacy-theme .c-sidebar-nav-link.c-sidebar-nav-link-dark:hover .c-sidebar-nav-icon,
.c-legacy-theme .c-sidebar-nav-link-dark.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
  color: #fff;
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-primary,
.c-sidebar .c-sidebar-nav-link-primary.c-sidebar-nav-dropdown-toggle {
  background: #321fdb;
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-primary .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-link-primary.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.7);
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-primary:hover,
.c-sidebar .c-sidebar-nav-link-primary.c-sidebar-nav-dropdown-toggle:hover {
  background: #2d1cc5;
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-primary:hover .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-link-primary.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
  color: #fff;
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-secondary,
.c-sidebar .c-sidebar-nav-link-secondary.c-sidebar-nav-dropdown-toggle {
  background: #ced2d8;
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-secondary .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-link-secondary.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.7);
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-secondary:hover,
.c-sidebar .c-sidebar-nav-link-secondary.c-sidebar-nav-dropdown-toggle:hover {
  background: #c0c5cd;
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-secondary:hover .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-link-secondary.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
  color: #fff;
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-success,
.c-sidebar .c-sidebar-nav-link-success.c-sidebar-nav-dropdown-toggle {
  background: #2eb85c;
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-success .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-link-success.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.7);
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-success:hover,
.c-sidebar .c-sidebar-nav-link-success.c-sidebar-nav-dropdown-toggle:hover {
  background: #29a452;
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-success:hover .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-link-success.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
  color: #fff;
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-info,
.c-sidebar .c-sidebar-nav-link-info.c-sidebar-nav-dropdown-toggle {
  background: #39f;
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-info .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-link-info.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.7);
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-info:hover,
.c-sidebar .c-sidebar-nav-link-info.c-sidebar-nav-dropdown-toggle:hover {
  background: #1a8cff;
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-info:hover .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-link-info.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
  color: #fff;
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-warning,
.c-sidebar .c-sidebar-nav-link-warning.c-sidebar-nav-dropdown-toggle {
  background: #f9b115;
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-warning .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-link-warning.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.7);
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-warning:hover,
.c-sidebar .c-sidebar-nav-link-warning.c-sidebar-nav-dropdown-toggle:hover {
  background: #eea506;
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-warning:hover .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-link-warning.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
  color: #fff;
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-danger,
.c-sidebar .c-sidebar-nav-link-danger.c-sidebar-nav-dropdown-toggle {
  background: #e55353;
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-danger .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-link-danger.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.7);
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-danger:hover,
.c-sidebar .c-sidebar-nav-link-danger.c-sidebar-nav-dropdown-toggle:hover {
  background: #e23d3d;
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-danger:hover .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-link-danger.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
  color: #fff;
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-light,
.c-sidebar .c-sidebar-nav-link-light.c-sidebar-nav-dropdown-toggle {
  background: #ebedef;
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-light .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-link-light.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.7);
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-light:hover,
.c-sidebar .c-sidebar-nav-link-light.c-sidebar-nav-dropdown-toggle:hover {
  background: #dde0e4;
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-light:hover .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-link-light.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
  color: #fff;
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-dark,
.c-sidebar .c-sidebar-nav-link-dark.c-sidebar-nav-dropdown-toggle {
  background: #636f83;
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-dark .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-link-dark.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  color: rgba(255, 255, 255, 0.7);
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-dark:hover,
.c-sidebar .c-sidebar-nav-link-dark.c-sidebar-nav-dropdown-toggle:hover {
  background: #586374;
}

.c-sidebar .c-sidebar-nav-link.c-sidebar-nav-link-dark:hover .c-sidebar-nav-icon,
.c-sidebar .c-sidebar-nav-link-dark.c-sidebar-nav-dropdown-toggle:hover .c-sidebar-nav-icon {
  color: #fff;
}

.c-sidebar .c-sidebar-nav-dropdown.c-show {
  background-color: transparent;
}

.c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle {
  color: #A0A2AE;
}

.c-sidebar-nav-dropdown-items .c-sidebar-nav-link {
  background-color: transparent !important;
  padding-left: 50px !important;
}

.menu-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.c-sidebar {
  z-index: 2;
}

.c-sidebar-nav-link {
  position: relative;
}

.c-sidebar-nav-link .chevron {
  position: absolute;
  right: 10px;
  top: 50%;
}

.c-sidebar-nav-link-small {
  font-size: 0.75em !important;
}

@media (min-width: 1440px) {
  .c-sidebar-nav-link-small {
    font-size: 0.95em !important;
  }
}

.c-sidebar-dropdown-item {
  border-left: 0;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0;
}

.c-sidebar-nav-item-small {
  padding-right: 0;
  border-left: 0;
  padding-left: 10px;
  margin-bottom: 7px;
  margin-top: 7px;
}

.c-sidebar-dropdown-active {
  background-color: transparent !important;
}

.c-header {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-shrink: 0;
  min-height: 56px;
  background: #F5F6F9;
  color: #33323D;
  padding: 0 !important;
}

@media (max-width: 768px) {
  .c-header {
    justify-content: space-between;
  }
  .c-header h1 {
    display: none;
  }
  .c-header #weather-infos {
    display: none;
  }
}

@media (max-width: 1440px) {
  .c-header #weather-infos span {
    font-size: 1em;
  }
  .c-header #weather-infos #weather-subtitle {
    font-size: 1.7em;
  }
}

.c-header .c-header-toggler {
  color: white;
  border-color: rgba(0, 0, 21, 0.1);
}

.c-header-toggler {
  width: 54px;
  height: 54px;
  font-size: 1.09375rem;
  background: #1d3aff;
  background: linear-gradient(148deg, #1d3aff 0%, #9769ff 100%);
  border: 0;
  border-radius: 100px;
}

.c-wrapper {
  padding: 10px 15px 0 15px;
}

.c-subheader {
  width: 100%;
  text-align: right;
  height: 60px;
}

.c-subheader h1 {
  font-size: 35px;
}

@media (max-width: 768px) {
  .c-subheader {
    width: 80%;
    height: auto;
  }
  .c-subheader #weather-title {
    font-size: 0.9em;
  }
}

.header-right-div {
  justify-content: flex-end;
}

.c-body {
  background: #F5F6F9;
  padding: 10px 0 20px;
  font-family: "Poppins", sans-serif !important;
  overflow-x: hidden;
  overflow-y: hidden;
}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#weather-title {
  color: #707070;
  font-size: 24px;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
  text-align: right;
}

#weather-subtitle {
  font-weight: 300;
  font-size: 14px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#weather-svg {
  margin-right: 10px;
}

.weather-image {
  height: 32px;
  margin-right: 5px;
}

#clock {
  line-height: 45px;
  font-size: 15px;
  margin: 0px 20px;
  padding: 0px 20px;
  background: #f3f3f3;
  color: var(--color1);
}

.card {
  background: white;
  background: linear-gradient(156deg, white 0%, #f7f8fa 100%);
}

.c-legacy-theme .card {
  background-color: #fff;
  border-color: #ffffff;
  box-shadow: 0px 0px 20px #0000001f;
}

.c-dark-theme .card {
  background: #282933 !important;
  border-color: #181924;
}

.c-dark-theme .card .card-header {
  color: white;
}

.c-app:not(.c-legacy-theme):not(.c-dark-theme) .card {
  box-shadow: 0 0 20px 0 #3343E015;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card.drag,
.card .drag {
  cursor: move;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 10px !important;
}

@media (min-width: 1400px) {
  .card-body {
    padding: 20px !important;
  }
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
}

.card-subtitle,
.card-text:last-child {
  margin-bottom: 0;
}

@media (hover: hover), (-ms-high-contrast: none) {
  .card-link:hover {
    text-decoration: none;
  }
}

html:not([dir="rtl"]) .card-link + .card-link {
  margin-left: 1.25rem;
}

*[dir="rtl"] .card-link + .card-link {
  margin-right: 1.25rem;
}

.card-header {
  padding: 10px !important;
  margin-bottom: 10px;
  background-color: transparent;
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  font-size: 32px;
  font-weight: 700;
  color: #33323D;
  font-family: "Poppins", sans-serif !important;
}

@media (min-width: 1400px) {
  .card-header {
    padding: 20px !important;
  }
}

#infoDash .card-header {
  padding: 7px 1.25rem;
  height: 51px;
}

.c-legacy-theme .card-header {
  background-color: #f0f3f5;
  border-color: #c8ced3;
}

.c-dark-theme .card-header {
  background-color: #1c1d23;
  border-color: #181924;
}

.card-header:first-child {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-header .c-chart-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  border-top: 1px solid;
  background-color: #fff;
  border-color: #d8dbe0;
}

.c-legacy-theme .card-footer {
  background-color: #f0f3f5;
  border-color: #c8ced3;
}

.c-dark-theme .card-footer {
  background-color: #1c1d23;
  border-color: #181924;
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-bottom: -0.75rem;
  border-bottom: 0;
}

.card-header-tabs,
.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0;
  }
  html:not([dir="rtl"]) .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  *[dir="rtl"] .card-group > .card + .card {
    margin-right: 0;
    border-right: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.card-placeholder {
  background: rgba(0, 0, 21, 0.025);
  border: 1px dashed #c4c9d0;
}

.card-header-title {
  font-size: 18px;
}

@media (min-width: 1600px) {
  .card-header-title {
    font-size: 25px;
  }
}

.card-header-icon-bg {
  display: inline-block;
  width: 2.8125rem;
  padding: 0.75rem 0;
  margin: -0.75rem 1.25rem -0.75rem -1.25rem;
  line-height: inherit;
  color: #4f5d73;
  text-align: center;
  background: transparent;
  border-right: 1px solid;
  border-right: #d8dbe0;
}

.c-legacy-theme .card-header-icon-bg {
  border-right: #c8ced3;
}

.c-dark-theme .card-header-icon-bg {
  border-right: #181924;
}

.card-header-actions {
  display: inline-block;
}

html:not([dir="rtl"]) .card-header-actions {
  float: right;
  margin-right: -0.25rem;
}

*[dir="rtl"] .card-header-actions {
  float: left;
  margin-left: -0.25rem;
}

.card-header-action {
  padding: 0 0.25rem;
  color: #8a93a2;
}

.card-header-action:hover {
  color: #4f5d73;
  text-decoration: none;
}

.c-dark-theme .card-accent-primary {
  border-top: 2px solid #4638c2 !important;
}

.c-dark-theme .card-accent-secondary {
  border-top: 2px solid #4c4f54 !important;
}

.c-dark-theme .card-accent-success {
  border-top: 2px solid #45a164 !important;
}

.c-dark-theme .card-accent-info {
  border-top: 2px solid #4799eb !important;
}

.c-dark-theme .card-accent-warning {
  border-top: 2px solid #e1a82d !important;
}

.c-dark-theme .card-accent-danger {
  border-top: 2px solid #d16767 !important;
}

.c-dark-theme .card-accent-light {
  border-top: 2px solid #1c1d23 !important;
}

.c-dark-theme .card-accent-dark {
  border-top: 2px solid #181924 !important;
}

.c-legacy-theme .card-accent-primary {
  border-top: 2px solid #20a8d8 !important;
}

.c-legacy-theme .card-accent-secondary {
  border-top: 2px solid #c8ced3 !important;
}

.c-legacy-theme .card-accent-success {
  border-top: 2px solid #4dbd74 !important;
}

.c-legacy-theme .card-accent-info {
  border-top: 2px solid #63c2de !important;
}

.c-legacy-theme .card-accent-warning {
  border-top: 2px solid #ffc107 !important;
}

.c-legacy-theme .card-accent-danger {
  border-top: 2px solid #f86c6b !important;
}

.c-legacy-theme .card-accent-light {
  border-top: 2px solid #f0f3f5 !important;
}

.c-legacy-theme .card-accent-dark {
  border-top: 2px solid #2f353a !important;
}

.card-accent-primary {
  border-top: 2px solid #321fdb !important;
}

.card-accent-secondary {
  border-top: 2px solid #ced2d8 !important;
}

.card-accent-success {
  border-top: 2px solid #2eb85c !important;
}

.card-accent-info {
  border-top: 2px solid #39f !important;
}

.card-accent-warning {
  border-top: 2px solid #f9b115 !important;
}

.card-accent-danger {
  border-top: 2px solid #e55353 !important;
}

.card-accent-light {
  border-top: 2px solid #ebedef !important;
}

.card-accent-dark {
  border-top: 2px solid #636f83 !important;
}

.card-full {
  margin-top: -1rem;
  margin-right: -15px;
  margin-left: -15px;
  border: 0;
  border-bottom: 1px solid transparent;
}

@media (min-width: 576px) {
  .card-columns.cols-2 {
    -webkit-column-count: 2;
    column-count: 2;
  }
}

.page-card-subtitle {
  display: inline;
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 30px;
  color: #33323D;
}

@media (max-width: 767px) {
  .page-card-subtitle {
    font-size: 1.5em;
    display: block;
    margin-bottom: 20px;
  }
}

.mobile-card {
  background: transparent !important;
  box-shadow: none !important;
}

.mobile-card .card-body, .mobile-card .card-header {
  padding: 0px;
}

.card-header {
  border-color: transparent !important;
  background-color: transparent !important;
}

.card {
  border-radius: 10px !important;
  border-color: transparent !important;
  margin: 5px;
}

.custom-page-card {
  margin: 0 !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  padding: 40px 30px 40px 30px;
}

@media (max-width: 768px) {
  .custom-page-card {
    padding: 40px 10px 40px 10px;
  }
}

.card-header {
  margin-bottom: 10px;
  padding: 1.25rem;
  font-size: 24px !important;
}

.card-body {
  padding-top: 0;
}

.card-header-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  fill: white;
  color: white;
  font-size: 15px;
  margin-right: 10px;
}

@media (min-width: 1600px) {
  .card-header-icon {
    font-size: 20px;
    width: 50px;
    height: 50px;
    border-radius: 10px;
  }
}

.card-header-icon .icon {
  fill: white;
  color: white;
}

.card-header-icon-pink-purple {
  background: #ce5bcb;
  background: linear-gradient(123deg, #ce5bcb 0%, #8963f3 100%);
}

.card-header-icon-pink {
  background: #EB5C8D;
  background: linear-gradient(123deg, #EB5CC2 0%, #EB5C8D 100%);
}

.card-header-icon-yellow {
  background: #ffcf67;
  background: linear-gradient(149deg, #ffcf67 0%, #f2a600 100%);
}

.card-header-icon-blue {
  background: #1d3aff;
  background: linear-gradient(149deg, #1d3aff 0%, #922eff 100%);
}

/* Bot grid */
#home-grid2 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 25% 27% 26% 22%;
      grid-template-columns: 25% 27% 26% 22%;
  -ms-grid-rows: 1fr auto;
      grid-template-rows: 1fr auto;
  /*0.7fr;*/
      grid-template-areas: "logs reports reports latest" "pages pages pages latest";
  /*"pages pages pages pages";*/
  /*
    grid-template-columns: 24% 25% 25% 26%;
    grid-template-rows: auto 0.7fr;
    grid-template-areas: 
    "competitors reports reports logs"
    "pages pages pages pages";*/
  height: auto;
  padding: 0;
}

@media screen and (max-width: 768px) {
  #home-grid2 {
    display: block;
    height: auto;
  }
}

/*Top grid*/
#home-top-grid1 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 25% 25% 25% 25%;
      grid-template-columns: 25% 25% 25% 25%;
  -ms-grid-rows: 315px;
      grid-template-rows: 315px;
      grid-template-areas: "stats1 my-drive bests bests";
  height: auto;
  padding: 0;
}

@media (max-width: 1400px) {
  #home-top-grid1 {
    -ms-grid-rows: 230px;
        grid-template-rows: 230px;
  }
}

@media (max-width: 1559px) {
  #home-top-grid1 {
    -ms-grid-rows: 260px;
        grid-template-rows: 260px;
  }
}

#home-top-grid2 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 25% 25% 25% 25%;
      grid-template-columns: 25% 25% 25% 25%;
  -ms-grid-rows: 315px 315px;
      grid-template-rows: 315px 315px;
      grid-template-areas: "stats1 stats2 bests bests" "my-drive my-drive bests bests";
  height: auto;
  padding: 0;
}

@media (max-width: 1559px) {
  #home-top-grid2 {
    -ms-grid-rows: 260px 260px;
        grid-template-rows: 260px 260px;
  }
}

@media (max-width: 1400px) {
  #home-top-grid2 {
    -ms-grid-rows: 230px 230px;
        grid-template-rows: 230px 230px;
  }
}

#home-top-grid3 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 25% 25% 25% 25%;
      grid-template-columns: 25% 25% 25% 25%;
  -ms-grid-rows: 315px 315px;
      grid-template-rows: 315px 315px;
      grid-template-areas: "stats1 stats2 bests bests" "stats3 my-drive bests bests";
  height: auto;
  padding: 0;
}

@media (max-width: 1559px) {
  #home-top-grid3 {
    -ms-grid-rows: 260px 260px;
        grid-template-rows: 260px 260px;
  }
}

@media (max-width: 1400px) {
  #home-top-grid3 {
    -ms-grid-rows: 230px 230px;
        grid-template-rows: 230px 230px;
  }
}

#home-top-grid4 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 25% 25% 25% 25%;
      grid-template-columns: 25% 25% 25% 25%;
  -ms-grid-rows: 315px 315px 315px;
      grid-template-rows: 315px 315px 315px;
      grid-template-areas: "stats1 stats2 bests bests" "stats3 stats4 bests bests" "my-drive my-drive bests bests";
  height: auto;
  padding: 0;
}

@media (max-width: 1559px) {
  #home-top-grid4 {
    -ms-grid-rows: 260px 260px 260px;
        grid-template-rows: 260px 260px 260px;
  }
}

@media (max-width: 1400px) {
  #home-top-grid4 {
    -ms-grid-rows: 230px 230px 230px;
        grid-template-rows: 230px 230px 230px;
  }
}

#home-top-grid5 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 25% 25% 25% 25%;
      grid-template-columns: 25% 25% 25% 25%;
  -ms-grid-rows: 315px 315px 315px;
      grid-template-rows: 315px 315px 315px;
      grid-template-areas: "stats1 stats2 bests bests" "stats3 stats4 bests bests" "stats5 my-drive bests bests";
  height: auto;
  padding: 0;
}

@media (max-width: 1559px) {
  #home-top-grid5 {
    -ms-grid-rows: 260px 260px 260px;
        grid-template-rows: 260px 260px 260px;
  }
}

@media (max-width: 1400px) {
  #home-top-grid5 {
    -ms-grid-rows: 230px 230px 230px;
        grid-template-rows: 230px 230px 230px;
  }
}

#home-top-grid6 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 25% 25% 25% 25%;
      grid-template-columns: 25% 25% 25% 25%;
  -ms-grid-rows: 315px 315px 315px;
      grid-template-rows: 315px 315px 315px;
      grid-template-areas: "stats1 stats2 bests bests" "stats3 stats4 bests bests" "stats5 stats6 my-drive my-drive";
  height: auto;
  padding: 0;
}

@media (max-width: 1559px) {
  #home-top-grid6 {
    -ms-grid-rows: 260px 260px 260px;
        grid-template-rows: 260px 260px 260px;
  }
}

@media (max-width: 1400px) {
  #home-top-grid6 {
    -ms-grid-rows: 230px 230px 230px;
        grid-template-rows: 230px 230px 230px;
  }
}

@media screen and (max-width: 768px) {
  #home-top-grid1, #home-top-grid2, #home-top-grid3, #home-top-grid4, #home-top-grid5, #home-top-grid6 {
    display: block;
    height: auto;
    padding: 0;
  }
}

#my-drive {
  -ms-grid-row: 3;
  -ms-grid-column: 3;
  -ms-grid-column-span: 2;
  grid-area: my-drive;
}

#stats1 {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: stats1;
}

#stats2 {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: stats2;
}

#stats3 {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  grid-area: stats3;
}

#stats4 {
  -ms-grid-row: 2;
  -ms-grid-column: 2;
  grid-area: stats4;
}

#stats5 {
  -ms-grid-row: 3;
  -ms-grid-column: 1;
  grid-area: stats5;
}

#bests {
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  -ms-grid-column: 3;
  -ms-grid-column-span: 2;
  grid-area: bests;
}

#calendar {
  grid-area: calendar;
}

#reports {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  -ms-grid-column-span: 2;
  grid-area: reports;
}

#latest {
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  -ms-grid-column: 4;
  grid-area: latest;
}

#home-logs {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: logs;
}

#competitors {
  grid-area: competitors;
}

#home-pages {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  grid-area: pages;
}

#ranking-posts {
  max-height: 320px;
}

.infinite-scroll {
  height: 120%;
  overflow-y: hidden !important;
}

.infinite-scroll::-webkit-scrollbar {
  display: none;
}

.grid-4 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 25% 25% 25% 25%;
      grid-template-columns: 25% 25% 25% 25%;
  grid-row-gap: 15px;
  row-gap: 15px;
  height: auto;
}

@media (max-width: 1024px) {
  .grid-4 {
    -ms-grid-columns: 50% 50%;
        grid-template-columns: 50% 50%;
  }
}

@media (max-width: 767px) {
  .grid-4 {
    display: block;
  }
}

.profile-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 250px 350px auto;
      grid-template-columns: 250px 350px auto;
}

@media (max-width: 1279px) {
  .profile-grid {
    display: block;
  }
}

.profile-label {
  color: #33323D;
  font-size: 12px;
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 0;
  display: block;
}

@media (min-width: 1440px) {
  .profile-label {
    font-size: 15px;
  }
}

.profile-placeholder {
  font-size: 1.1em;
  line-height: 1em;
  font-weight: bold;
  display: block;
  font-family: "Poppins", sans-serif !important;
  color: #33323D;
  margin-right: 10px;
  display: inline;
}

@media (min-width: 1440px) {
  .profile-placeholder {
    font-size: 1.5em;
  }
}

.c-dark-theme .profile-placeholder, .c-dark-theme .profile-label {
  color: #DFDDED;
}

.c-dark-theme .page-card-subtitle {
  color: #DFDDED;
}

.profile-edit-button {
  display: inline-block;
  cursor: pointer;
  stroke: #33323d;
}

.c-dark-theme .profile-edit-button {
  stroke: #DFDDED;
}

.profile-input {
  border: 0;
  border-bottom: 2px solid #33323D;
  padding: 10px;
  font-size: 12px;
  background-color: transparent;
}

@media (min-width: 1440px) {
  .profile-input {
    font-size: 18px;
  }
}

.profile-input:focus {
  outline: none;
}

.profile-input-50-group {
  display: flex;
}

.profile-input-50:nth-child(1) {
  margin-right: 20px;
  max-width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media screen and (max-width: 991px) {
  .profile-input-50 {
    margin-right: 0px;
    width: 100%;
  }
}

.profile-logo-picture img {
  max-height: 100px;
}

.profile-user-logo-button {
  background-color: white;
  border-radius: 100px;
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: -10px;
  right: -10px;
  box-shadow: 0px 0px 20px #0000001f;
  border: 0;
}

.profile-user-picture {
  width: 90%;
  border-radius: 150px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.profile-user-picture::after {
  padding-top: 100%;
  content: '';
}

.profile-user-picture img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  position: absolute;
}

.profile-input-button {
  display: inline-block;
  cursor: pointer;
}

.profile-user-picture-wrapper {
  position: relative;
}

.profile-user-picture-button {
  background-color: white;
  border-radius: 100px;
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: -12px;
  right: -7px;
  box-shadow: 0px 0px 20px #0000001f;
  border: 0;
}

.profile-user-picture-button:focus {
  outline: none;
}

.profile-password-input {
  color: #33323D;
  border: 1px solid #DFDDED;
  padding: 10px 20px;
  font-family: "Poppins", sans-serif !important;
  font-size: 12px;
  margin-right: 5px;
  border-radius: 10px;
  background-color: transparent;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 25%;
}

@media (min-width: 1440px) {
  .profile-password-input {
    font-size: 15px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 991px) {
  .profile-password-input {
    width: 100%;
    margin-bottom: 10px;
  }
  .password-confirm-button {
    width: 100%;
  }
}

@media screen and (max-width: 991px) and (min-width: 1440px) {
  .password-confirm-button {
    height: 45px !important;
  }
}

.profile-password-input:focus {
  outline: none;
  border-color: #b1a9df;
}

.profile-password-input::-webkit-input-placeholder {
  color: #DFDDED;
  font-family: "Poppins", sans-serif !important;
  font-size: 12px;
}

.profile-password-input:-ms-input-placeholder {
  color: #DFDDED;
  font-family: "Poppins", sans-serif !important;
  font-size: 12px;
}

.profile-password-input::-ms-input-placeholder {
  color: #DFDDED;
  font-family: "Poppins", sans-serif !important;
  font-size: 12px;
}

.profile-password-input::placeholder {
  color: #DFDDED;
  font-family: "Poppins", sans-serif !important;
  font-size: 12px;
}

@media (min-width: 1440px) {
  .profile-password-input::-webkit-input-placeholder {
    font-size: 15px;
  }
  .profile-password-input:-ms-input-placeholder {
    font-size: 15px;
  }
  .profile-password-input::-ms-input-placeholder {
    font-size: 15px;
  }
  .profile-password-input::placeholder {
    font-size: 15px;
  }
}

.profile-date-label {
  color: #CCC8E2;
  font-family: "Poppins", sans-serif !important;
  font-size: 14px;
  display: block;
  margin-bottom: 25px;
}

.profile-date-input {
  font-family: "Poppins", sans-serif !important;
  color: #DFDDED;
  font-size: 15px;
  border: 1px solid #DFDDED;
  padding: 10px 15px;
  border-radius: 5px;
  background-color: transparent;
  width: 100%;
}

.profile-date-input::-webkit-input-placeholder {
  color: #33323D;
  font-size: 15px;
  font-family: "Poppins", sans-serif !important;
}

.profile-date-input:-ms-input-placeholder {
  color: #33323D;
  font-size: 15px;
  font-family: "Poppins", sans-serif !important;
}

.profile-date-input::-ms-input-placeholder {
  color: #33323D;
  font-size: 15px;
  font-family: "Poppins", sans-serif !important;
}

.profile-date-input::placeholder {
  color: #33323D;
  font-size: 15px;
  font-family: "Poppins", sans-serif !important;
}

.profile-date-submit-button {
  color: #8963F3;
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
  background-color: transparent;
  border: none;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  display: block;
}

.profile-date-submit-button:focus {
  outline: none;
}

.profile-date-card {
  border-radius: 15px;
  padding-top: 35px;
  padding-bottom: 20px;
}

.profile-data-wrapper {
  position: relative;
  height: 30px;
}

@media (min-width: 1440px) {
  .profile-data-wrapper {
    height: 45px;
  }
}

.profile-placeholder-wrapper, .profile-input-wrapper {
  display: flex;
  align-items: center;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

@media (min-width: 1440px) {
  .profile-placeholder-wrapper, .profile-input-wrapper {
    height: 45px;
  }
}

.view-header {
  padding: 15px 15px;
  color: white;
  font-family: "Poppins", sans-serif !important;
  font-weight: bold;
  margin-bottom: 0px;
  border-radius: 10px 10px 0px 0px;
  text-align: center;
}

.view-header #title_modal {
  font-size: 1.5em;
  margin: 0;
}

.yellow-header {
  background: #f2a600;
  background-image: url(/static/media/page-header-bg.449d65bd.svg), -webkit-gradient(linear, left top, right top, from(#f2a600), to(#ffcf67));
  background-image: url(/static/media/page-header-bg.449d65bd.svg), linear-gradient(90deg, #f2a600 0%, #ffcf67 100%);
  background-size: cover;
}

.pink-header {
  background: #EB5C8D;
  background-image: url(/static/media/page-header-bg.449d65bd.svg), -webkit-gradient(linear, left top, right top, from(#EB5C8D), to(#EB5CC2));
  background-image: url(/static/media/page-header-bg.449d65bd.svg), linear-gradient(90deg, #EB5C8D 0%, #EB5CC2 100%);
  background-size: cover;
}

.purple-header {
  background: #8963F3;
  background-image: url(/static/media/page-header-bg.449d65bd.svg), -webkit-gradient(linear, left top, right top, from(#8963F3), to(#CE5BCB));
  background-image: url(/static/media/page-header-bg.449d65bd.svg), linear-gradient(90deg, #8963F3 0%, #CE5BCB 100%);
  background-size: cover;
}

.green-header {
  background: #14B28E;
  background-image: url(/static/media/page-header-bg.449d65bd.svg), -webkit-gradient(linear, left top, right top, from(#14B28E), to(#14B28E));
  background-image: url(/static/media/page-header-bg.449d65bd.svg), linear-gradient(90deg, #14B28E 0%, #14B28E 100%);
  background-size: cover;
}

.blue-header {
  background: #1D3AFF;
  background-image: url(/static/media/page-header-bg.449d65bd.svg), -webkit-gradient(linear, left top, right top, from(#1D3AFF), to(#922EFF));
  background-image: url(/static/media/page-header-bg.449d65bd.svg), linear-gradient(90deg, #1D3AFF 0%, #922EFF 100%);
  background-size: cover;
}

button {
  border: 0;
}

button:focus {
  outline: none !important;
}

.dashboard-button {
  padding: 8px 10px;
  color: white !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600;
  border-radius: 5px;
  font-size: 13px;
  opacity: 0.9;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  border: 0;
  display: inline-flex;
  align-items: center;
  white-space: nowrap !important;
}

.small-button {
  font-size: 10px;
  padding: 5px;
}

.dashboard-button:hover {
  opacity: 0.8;
  color: white;
}

.dashboard-button:focus {
  /*outline-color: #1D3AFF;*/
  outline: none;
}

.success-button {
  background-color: #59AA61;
}

.danger-button {
  background: linear-gradient(45deg, #EB5C8D 0%, #EB0050 100%);
}

.primary-button {
  background: linear-gradient(45deg, #1D3AFF 0%, #922EFF 100%);
}

.warning-button {
  background: linear-gradient(45deg, #F2A600 0%, #FFCF67 100%);
}

.purple-button {
  background: linear-gradient(45deg, #8963F3 0%, #CE5BCB 100%);
}

.export-button {
  background: linear-gradient(45deg, #8963F3 0%, #CE5BCB 100%);
  padding: auto 20px;
  font-size: 15px;
  font-weight: 400;
  height: 44px;
}

.import-button {
  background: linear-gradient(45deg, #0676ED 0%, #0569D4 30%, #033B77 100%);
  padding: auto 20px;
  height: 44px;
  font-size: 15px;
  font-weight: 400;
  margin-left: 10px;
}

.white-button {
  background-color: white;
  color: #0670E1 !important;
  font-size: 18px;
  box-shadow: 0px 0px 20px #0000001f;
  height: 44px;
  width: 49px;
  margin-left: 10px;
}

.white-button:hover {
  color: #0670E1;
  opacity: 0.8;
}

.white-button-blue {
  background-color: white;
  color: #0670E1 !important;
  font-size: 14px;
  box-shadow: 0px 0px 20px #0000001f;
  height: 44px;
  margin-left: 10px;
  font-weight: 500;
}

.white-button-blue:hover {
  color: #0670E1;
  opacity: 0.8;
}

.white-button-blue:focus {
  outline: none;
}

.icon-button {
  padding: 15px;
  color: #D4D7EC;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0px 5px 15px -6px rgba(51, 67, 224, 0.3);
  width: 32px;
  height: 32px;
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  -webkit-transition: 0.8s;
  transition: 0.8s;
}

.icon-button:focus {
  outline: none;
}

.icon-button:hover {
  box-shadow: 0px 10px 20px -6px rgba(51, 67, 224, 0.4);
  color: white;
  fill: white;
}

.icon-button-all:hover, .icon-button-all-active {
  background: linear-gradient(148deg, #1d3aff 0%, #9769ff 100%);
  color: white;
  fill: white;
}

.icon-button-facebook:hover, .icon-button-facebook-active {
  background-color: #3B5998;
  color: white;
  fill: white;
}

.icon-button-twitter:hover, .icon-button-twitter-active {
  background-color: #00ACED;
  color: white;
  fill: white;
}

.icon-button-pinterest:hover, .icon-button-pinterest-active {
  background-color: #CB2027;
  color: white;
  fill: white;
}

.icon-button-instagram:hover, .icon-button-instagram-active {
  background: #803cb3;
  background: linear-gradient(216deg, #803cb3 0%, #ed0d20 50%, #f8ca01 100%);
  color: white;
  fill: white;
}

.icon-button-youtube:hover, .icon-button-youtube-active {
  background-color: #CB2027;
  color: white;
  fill: white;
}

.icon-button-calendar {
  background-color: white;
  color: #0676ED;
  fill: #0676ED;
}

.icon-button-calendar:hover, .icon-button-calendar-active {
  background-color: #0676ED;
  color: white;
  fill: white;
}

.c-dark-theme .icon-button {
  background-color: #282933 !important;
  box-shadow: none;
}

.modal-function-button {
  padding: 9px 16px !important;
  font-size: 0.95em;
}

.pages-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -webkit-column-gap: 10px;
          grid-column-gap: 10px;
          column-gap: 10px;
  grid-row-gap: 10px;
  row-gap: 10px;
}

@media (min-width: 1200px) {
  .pages-grid {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1600px) {
  .pages-grid {
    -webkit-column-gap: 20px;
            grid-column-gap: 20px;
            column-gap: 20px;
    grid-row-gap: 20px;
    row-gap: 20px;
  }
}

.pages-grid .page-item {
  display: -ms-grid !important;
  display: grid !important;
  -ms-grid-columns: 1fr 3fr;
      grid-template-columns: 1fr 3fr;
  -webkit-column-gap: 20px;
          grid-column-gap: 20px;
          column-gap: 20px;
  align-items: center;
  background: white;
  background: linear-gradient(156deg, white 0%, #f7f8fa 100%);
}

.pages-grid .page-item .page-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.pages-grid .page-item .page-item-header h2 {
  font-weight: bold;
  font-size: 17px;
}

@media (min-width: 1600px) {
  .pages-grid .page-item .page-item-header h2 {
    font-size: 22px;
  }
}

.pages-grid .page-item .page-item-header a {
  display: block;
  padding: 7px 10px;
  background: #1d3aff;
  background: linear-gradient(148deg, #1d3aff 0%, #9769ff 100%);
  color: white;
  border-radius: 3px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font-size: 11px;
}

.pages-grid .page-item .page-item-header a:hover {
  color: white !important;
  opacity: 0.8;
}

.pages-grid .page-item p {
  font-size: 13px;
  margin-bottom: 10px;
}

@media (min-width: 1200px) {
  .pages-grid .page-item p {
    font-size: 11px;
  }
}

@media (min-width: 1600px) {
  .pages-grid .page-item p {
    font-size: 16px;
  }
}

.pages-grid .page-item .page-item-link {
  padding: 5px 10px;
  width: 100%;
  max-width: 100%;
  border: 1px solid #e2e2e2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px;
  background-color: transparent;
  width: 100%;
  font-size: 13px;
}

@media (min-width: 1200px) {
  .pages-grid .page-item .page-item-link {
    font-size: 11px;
  }
}

@media (min-width: 1600px) {
  .pages-grid .page-item .page-item-link {
    font-size: 13px;
  }
}

.pages-grid .page-item .page-item-link:focus {
  outline-color: blueviolet;
}

.pages-grid .page-item .page-item-link-text {
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pages-grid .page-item .page-item-link-icon {
  color: blueviolet;
  font-size: 15px;
}

.page-button {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  fill: white;
  color: white;
  border-radius: 100px;
  margin-right: 5px;
  background: #922eff;
  background: linear-gradient(35deg, #922eff 0%, #1d3aff 100%);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 0.8;
}

.page-button:focus {
  outline: none;
}

.page-button:hover {
  opacity: 1;
  text-decoration: none;
  color: white;
}

.page-types-slide {
  overflow-x: hidden;
  width: 100%;
  white-space: nowrap;
}

.page-type-preview-wrapper {
  width: 19%;
  display: inline-block;
  padding: 5px;
}

@media screen and (max-width: 768px) {
  .page-type-preview-wrapper {
    width: 100%;
    padding: 5px;
  }
}

.page-type-preview-wrapper a {
  text-align: center;
}

.page-type-preview-wrapper a h2 {
  font-size: 14px;
  font-family: "Poppins", sans-serif !important;
  color: #33323D;
  margin-top: 10px;
}

@media (min-width: 1440px) {
  .page-type-preview-wrapper a h2 {
    font-size: 18px;
  }
}

.page-type-preview {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
}

.c-dark-theme .page-type-preview-wrapper h2 {
  color: white !important;
}

a:hover {
  text-decoration: none !important;
}

.home-stats-card {
  background-position: center;
  background-size: cover !important;
  color: white;
  padding-top: 10px !important;
}

.home-stats-card .metrics {
  font-size: 13px;
}

.home-stats-card .value {
  font-weight: 600;
  font-size: 1.8em;
  line-height: 1em;
  margin: 0;
  padding: 0;
}

@media (min-width: 1600px) {
  .home-stats-card .value {
    font-size: 2.2em;
  }
}

@media (max-width: 1599px) {
  .home-stats-card {
    max-height: 160px !important;
  }
}

.media-stats-card-purple-blue {
  background: #922eff;
  background-image: url(/static/media/followers-bg.1becbc01.svg);
  background-image: url(/static/media/followers-bg.1becbc01.svg), linear-gradient(149deg, #922eff 0%, #1d3aff 100%);
  background-position: center;
  background-size: cover !important;
}

.media-stats-card-pink-purple {
  background: #ce5bcb;
  background-image: url(/static/media/likes-bg.6f8e6bd0.svg);
  background-image: url(/static/media/likes-bg.6f8e6bd0.svg), linear-gradient(211deg, #ce5bcb 0%, #8963f3 100%);
  background-position: center;
  background-size: cover !important;
}

.media-stats-card-pink {
  background: #eb5cc2;
  background-image: url(/static/media/shares-bg.7d044368.svg);
  background-image: url(/static/media/shares-bg.7d044368.svg), linear-gradient(51deg, #eb5cc2 0%, #eb5c8d 100%);
  background-position: center;
  background-size: cover !important;
}

.media-stats-card-yellow {
  background: #f2a600;
  background-image: url(/static/media/comments-bg.4e50a40d.svg);
  background-image: url(/static/media/comments-bg.4e50a40d.svg), linear-gradient(176deg, #f2a600 0%, #ffcf67 100%);
  background-position: center;
  background-size: cover !important;
}

.social-stats-card {
  margin-top: 30px !important;
}

@media (max-width: 767px) {
  .social-stats-card {
    margin-top: 35px !important;
  }
}

.social-stats-card .card-header {
  height: 0px;
  margin-bottom: 13px;
}

.social-stats-card-icon {
  width: 60px;
  height: 60px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  fill: white;
  color: white;
  font-size: 25px;
  padding: 0;
  margin: -40px auto 0 auto;
}

@media (min-width: 1400px) {
  .social-stats-card-icon {
    width: 70px;
    height: 70px;
    font-size: 30px;
  }
}

@media (min-width: 1600px) {
  .social-stats-card-icon {
    margin: -45px auto 0 auto;
  }
}

.social-starts-card-icon-bg-facebook {
  background-color: #415A93;
}

.social-starts-card-icon-bg-twitter {
  background-color: #4AABE7;
}

.social-starts-card-icon-bg-instagram {
  background: #803cb3;
  background: linear-gradient(216deg, #803cb3 0%, #ed0d20 50%, #f8ca01 100%);
}

.social-starts-card-icon-bg-pinterest {
  background-color: #CB2027;
}

.social-starts-card-icon-bg-youtube {
  background-color: #F61400;
}

.social-starts-card-icon-bg-google {
  background-color: #47A758;
}

.social-stats-card-body {
  padding: 5px;
}

.social-stats-card-grid {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 45% 45%;
      grid-template-columns: 45% 45%;
  -webkit-column-gap: 10px;
          grid-column-gap: 10px;
          column-gap: 10px;
  grid-row-gap: 10px;
  row-gap: 10px;
}

.social-stats-card-item {
  background-color: white;
  display: inline-block;
  padding: 5px;
  border-radius: 12px;
  box-shadow: 2px 3px 10px 0px rgba(89, 52, 255, 0.1);
  text-align: center;
  font-family: "Poppins", sans-serif !important;
}

@media (min-width: 1600px) {
  .social-stats-card-item {
    padding: 15px 5px;
  }
}

.social-stats-card-item .social-stats-card-value {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: -6px;
}

@media (min-width: 1400px) {
  .social-stats-card-item .social-stats-card-value {
    font-size: 17px;
  }
}

@media (min-width: 1600px) {
  .social-stats-card-item .social-stats-card-value {
    font-size: 22px;
  }
}

.social-stats-card-item .social-stats-card-text {
  font-size: 9px;
  font-weight: 600;
}

@media (min-width: 1400px) {
  .social-stats-card-item .social-stats-card-text {
    font-size: 11px;
  }
}

.social-stats-card-item .social-stats-card-title {
  font-weight: 700;
  font-size: 15px;
  font-family: "Poppins", sans-serif !important;
}

.social-stats-card-paragraph {
  font-size: 12px;
  font-family: "Poppins", sans-serif !important;
}

.social-stats-card-login-button {
  color: white;
  font-weight: 600;
  font-size: 12px;
  background-color: #EEBA28;
  border-radius: 10px;
  padding: 10px 30px;
  box-shadow: 0px 5px 15px -6px rgba(51, 67, 224, 0.3);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.social-stats-card-login-button:hover {
  box-shadow: 0px 5px 20px -6px rgba(51, 67, 224, 0.4);
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.social-stats-card-username {
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
  font-size: 12px;
  text-align: center;
  margin-top: 16px;
}

@media (min-width: 1400px) {
  .social-stats-card-username {
    font-size: 14px;
  }
}

.social-stats-card-username-facebook {
  color: #415A93;
}

.social-stats-card-username-twitter {
  color: #4AABE7;
}

.social-stats-card-username-instagram {
  color: #BC307E;
}

.social-stats-card-username-pinterest {
  color: #CB2027;
}

.social-stats-card-username-youtube {
  color: #F61400;
}

.c-dark-theme .social-stats-card-item {
  background-color: #1c1d23;
  color: white;
  box-shadow: none;
}

.scheduler-container {
  height: 100vh !important;
  width: 100% !important;
}

.dhx_cal_event_clear {
  color: white !important;
}

.dhx_cal_data div.dhx_cal_event_clear, .dhx_cal_data div.dhx_cal_event_line, .dhx_multi_day div.dhx_cal_event_clear, .dhx_multi_day div.dhx_cal_event_line {
  color: white !important;
}

.logs-table {
  overflow-x: auto;
}

.logs-table th {
  border-top: 0 !important;
  border-bottom: 2px dotted #EBECF1 !important;
  padding: 10px 0 !important;
  font-size: 0.8em !important;
}

@media (min-width: 1600px) {
  .logs-table th {
    font-size: 1em;
  }
}

.logs-table td {
  border-top: 0 !important;
  border-bottom: 2px dotted #EBECF1 !important;
  color: #14121E;
  font-size: 11px;
  line-height: 18px;
  padding: 10px 0 !important;
  vertical-align: middle;
}

@media (min-width: 1400px) {
  .logs-table td {
    font-size: 13px;
  }
}

.logs-table td > * {
  vertical-align: middle;
}

#home-log-card {
  overflow-x: auto;
}

.see-delivery {
  color: #8963f3;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  display: block;
  margin-left: auto;
}

.see-delivery:hover {
  color: #654ab1;
}

.modal {
  border: none;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
}

.modal-header {
  background: #8963f3;
  background: linear-gradient(156deg, #8963f3 0%, #ce5bcb 100%);
  color: white;
  border-radius: 20px 20px 0 0;
  display: flex;
  align-items: center;
  fill: white;
  padding: 20px 10px !important;
  position: relative;
  border-bottom: 0;
}

.modal-header h2 {
  font-size: 27px;
}

#modal-header-close-button {
  position: absolute;
  right: 20px;
}

.modal-content {
  padding-bottom: 20px;
  border-radius: 20px !important;
  overflow: hidden;
  border: 0 !important;
}

.modal-backdrop {
  background-color: transparent;
}

.modal-body {
  padding: 50px 40px 20px 40px !important;
}

.modal-footer {
  border-top: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.close {
  opacity: 0.96;
}

.close:focus {
  outline: none;
}

.modal-close-button {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 100px;
  opacity: 1 !important;
}

.modal h2 {
  font-weight: 600;
}

.modal h2, p, span, h3 {
  font-family: "Poppins", sans-serif !important !important;
}

.modal p {
  font-size: 0.97em;
}

.social-media-modal-option {
  display: inline-block;
  background-color: #E9E9E9;
  border-radius: 10px;
  color: white;
  text-align: center;
  width: 120px;
  height: 120px;
  margin: 7px;
}

@media screen and (max-width: 768px) {
  .social-media-modal-option {
    width: 100%;
    height: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .social-media-modal-option-icon {
    font-size: 35px !important;
  }
  .social-media-modal-option h3 {
    margin-left: 10px !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }
  #social-medias-modal-title {
    font-size: 20px;
  }
}

.c-dark-theme .social-media-modal-option {
  background-color: #1c1d23;
  color: #d6d6d6;
}

.social-media-modal-option:focus {
  outline: none;
}

.social-media-modal-option-facebook-active, .social-media-modal-option-facebook:hover {
  background-color: #415A93;
  color: white;
}

.social-media-modal-option-twitter-active, .social-media-modal-option-twitter:hover {
  background-color: #00A2DF;
  color: white;
}

.social-media-modal-option-pinterest-active, .social-media-modal-option-pinterest:hover {
  background-color: #CB2027;
  color: white;
}

.social-media-modal-option-youtube-active, .social-media-modal-option-youtube:hover {
  background-color: #FF0000;
  color: white;
}

.social-media-modal-option-instagram-active, .social-media-modal-option-instagram:hover {
  background: linear-gradient(216deg, #803cb3 0%, #ed0d20 50%, #f8ca01 100%);
  color: white;
}

.social-media-modal-option-icon {
  font-size: 45px;
}

.social-media-modal-option h3 {
  font-size: 17px;
  font-weight: 600;
  margin: -10px 0 0 0;
}

.social-media-modal-form label {
  font-family: 'Poppins';
  font-weight: 600;
  margin: 0;
}

.social-media-modal-form .form-control {
  border: 1px solid #A0A2AE;
  padding: 10px;
  color: #33323D;
  max-width: 200px;
  font-family: "Poppins", sans-serif !important;
}

.social-media-modal-form .form-control::-webkit-input-placeholder {
  color: #A0A2AE;
}

.social-media-modal-form .form-control:-ms-input-placeholder {
  color: #A0A2AE;
}

.social-media-modal-form .form-control::-ms-input-placeholder {
  color: #A0A2AE;
}

.social-media-modal-form .form-control::placeholder {
  color: #A0A2AE;
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 900px;
  }
}

#best-posts .table th {
  font-family: "Poppins" sans-serif;
  color: #1e1c4e !important;
  font-weight: 600 !important;
  background-color: #f7f7f7 !important;
  text-transform: capitalize !important;
}

.table-main {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#best-posts .table .table-description {
  color: #0676ED;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
}

#best-posts .table .table-post-date {
  font-size: 11px;
  color: #C5C5C5 !important;
}

#best-posts .table .table-engagement {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#best-posts .table .table-social-media {
  padding-right: 20px;
}

/* Latest posts */
.feed-page {
  font-family: "Poppins", sans-serif !important;
}

.feed-page-title {
  text-align: center;
  font-weight: 500;
  color: #0676ED;
  font-size: 15px;
}

.latest-posts-content-wrapper {
  text-align: center;
  margin-bottom: 30px;
}

.latest-posts-post-legend {
  color: #0676ED;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 60%;
}

.latests-posts-post-wrapper {
  position: relative;
  overflow-x: hidden;
  border-radius: 15px;
  height: 290px;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.latest-posts-post-image {
  height: 290px;
}

.latest-posts-post-social {
  color: black;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 10px;
  font-size: 13px;
  box-shadow: 2px 6px 11px #DFDDED48;
}

/* Square feed */
.latests-posts-feed-squares {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  max-height: 280px;
  overflow-y: auto;
  padding-left: 10px;
}

.latest-posts-feed-square {
  position: relative;
  width: 30%;
  overflow: hidden;
  border-radius: 5px;
  margin: 1.1%;
}

.latest-posts-feed-square:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.latest-posts-feed-square-content {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.latest-posts-feed-square img {
  height: 100%;
}

.latest-posts-feed-square a {
  opacity: 1 !important;
}

.message {
  text-align: center;
  font-family: "Poppins", sans-serif !important;
  color: #33323D !important;
  padding: 20px 10px;
  font-size: 1.1em;
}

.message:nth-child(2n) {
  background-color: #F5F6FD;
}

.notification-description {
  color: #33323D !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600;
}

.notification-date {
  color: #33323D !important;
  font-family: "Poppins", sans-serif !important;
}

.notification-title {
  color: #33323D !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 1.5em;
}

#notification-items {
  height: 67vh;
  overflow-y: scroll;
}

.change-password-notification-button {
  color: #6C32FF;
  font-family: "Poppins", sans-serif !important;
  border: 1px solid #6C32FF;
  padding: 10px 20px;
  background: none;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-top: 20px;
  fill: #6C32FF;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.change-password-notification-button:hover {
  color: white;
  background-color: #6C32FF;
  fill: white;
}

.logout-notification-button {
  color: #B55ED9;
  border: 1px solid #B55ED9;
  padding: 10px 20px;
  background: none;
  border-radius: 5px;
  fill: #B55ED9;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.logout-notification-button:hover {
  color: white;
  background-color: #B55ED9;
  fill: white;
}

#sidebar-user-name {
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
  color: #272727;
}

#sidebar-subtitle {
  font-family: "Poppins", sans-serif !important;
  font-size: 13px;
  color: #A0A2AE;
}

#notification {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 20px;
}

.archiving-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  -webkit-column-gap: 10px;
          grid-column-gap: 10px;
          column-gap: 10px;
  grid-row-gap: 10px;
  row-gap: 10px;
  overflow-y: hidden !important;
  height: auto;
}

.archiving-grid::-webkit-scrollbar {
  display: none;
}

.archiving-item {
  width: 100%;
  border: 1px solid #cdd1df;
  border-radius: 10px;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj4KICA8ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQgMSkiPgogICAgPHBhdGggZmlsbD0iIzM5QyIgZD0iTTIuOTk5OTE0LC0zLjVlLTA1IEMxLjM0OTk3MSwtMy41ZS0wNSAwLjAxNDkyOSwxLjM0OTk2MiAwLjAxNDkyOSwyLjk5OTk2MiBMLTguMjk5OTk5OTllLTA1LDI3LjAwMDAwNCBDLTguMjk5OTk5OTllLTA1LDI4LjY0OTkzOCAxLjMzNDkzMiwyOS45OTk5NjggMi45ODQ5ODMsMjkuOTk5OTY4IEwyMC45OTk4OTYsMjkuOTk5OTY4IEMyMi42NDk5NDcsMjkuOTk5OTY4IDIzLjk5OTk3NCwyOC42NDk5MzggMjMuOTk5OTc0LDI3LjAwMDAwNCBMMjMuOTk5OTc0LDYuOTk5OTU4IEwxNi45OTk5ODEsLTMuNWUtMDUgTDIuOTk5OTE0LC0zLjVlLTA1IFoiLz4KICAgIDxwb2x5Z29uIGZpbGw9IiMwMDAiIGZpbGwtb3BhY2l0eT0iLjE1IiBwb2ludHM9IjE4IDcgMjQgNyAyNCAxNCIvPgogICAgPHBhdGggZmlsbD0iIzZBQzBFQiIgZD0iTTE4Ljk5OTg5LDYuOTk5OTcgQzE3Ljg5NTQsNi45OTk5NyAxNi45OTk5OCw2LjEwNDUzIDE2Ljk5OTk4LDQuOTk5OTcgTDE2Ljk5OTk4LC01ZS0wNSBMMjMuOTk5OTgsNi45OTk5NyBMMTguOTk5ODksNi45OTk5NyBaIi8+CiAgICA8cGF0aCBmaWxsPSIjRkZGIiBkPSJNNS45OTk5NjUsOS45OTk5OTggTDExLjk5OTk2NSw5Ljk5OTk5OCBMMTEuOTk5OTY1LDExLjk5OTk4MyBMNS45OTk5NjUsMTEuOTk5OTgzIEw1Ljk5OTk2NSw5Ljk5OTk5OCBaIE01Ljk5OTk2NSwxMy45OTk5ODEgTDE3Ljk5OTkzNSwxMy45OTk5ODEgTDE3Ljk5OTkzNSwxNS45OTk5NzkgTDUuOTk5OTY1LDE1Ljk5OTk3OSBMNS45OTk5NjUsMTMuOTk5OTgxIFogTTUuOTk5OTY1LDE3Ljk5OTk3NyBMMTcuOTk5OTM1LDE3Ljk5OTk3NyBMMTcuOTk5OTM1LDE5Ljk5OTk3NSBMNS45OTk5NjUsMTkuOTk5OTc1IEw1Ljk5OTk2NSwxNy45OTk5NzcgWiIvPgogIDwvZz4KPC9zdmc+Cg==) no-repeat center center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  overflow: hidden;
  position: relative;
  background-color: white;
  cursor: pointer;
}

.archiving-item::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.archiving-preview {
  width: 100%;
  height: 100%;
  display: block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.archiving-functions {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.archiving-item:hover > .archiving-functions {
  visibility: visible;
  opacity: 1;
}

.archiving-loading {
  display: block;
  margin: 30px auto 0px auto;
  padding: 20px;
  color: #a7abb3;
  border-radius: 10px;
}

.skeleton-wrapper {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.skeleton-wrapper::after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.lightbox-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999999;
  color: white;
}

.lightbox-image img {
  max-height: 70vh;
  max-width: 90vw;
}

.lightbox-functions {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  font-size: 1.4em;
}

.lightbox-function-icon {
  width: 100px;
  height: 100px;
  border-radius: 60px;
  background-color: rgba(255, 255, 255, 0.2);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font-size: 2.5em;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 20px;
}

.lightbox-function-icon:hover, .lightbox-remove-icon:hover {
  opacity: 0.7;
  color: white;
}

.lightbox-remove-icon {
  width: 40px;
  height: 40px;
  border-radius: 60px;
  background-color: rgba(255, 255, 255, 0.2);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font-size: 1em;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 20px;
}

.download-text {
  margin-top: 10px;
  font-size: 0.95em;
}

.reports-sm-card-account {
  font-family: "Poppins", sans-serif !important;
  font-weight: 600;
}

.reports-sm-card {
  width: 100%;
  padding: 40px;
  font-size: 1.1em;
  margin: 10px;
}

.c-dark-theme .reports-sm-card, .c-dark-theme .reports-sm-card-square {
  color: #DFDDED;
  background: #1C1D23 !important;
}

.reports-sm-card-square {
  padding: 40px;
  font-size: 1.1em;
  margin: 10px;
}

.inline-custom-input-forms label {
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
}

.c-dark-theme .inline-custom-input-forms label {
  color: #DFDDED;
}

.inline-custom-input-forms select {
  font-family: "Poppins", sans-serif !important;
}

.inline-custom-input-forms label, .inline-custom-input-forms select, .inline-custom-input-forms input {
  margin-right: 10px;
}

.chart-card-header {
  text-align: center;
}

.chart-card-header {
  font-weight: 700;
}

.c-dark-theme .chart-card-header {
  color: white;
}

#reports_ranking_posts {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

@media screen and (max-width: 768px) {
  #reports_ranking_posts {
    position: relative;
    max-width: 400px;
  }
}

.c-dark-theme .reports-chart {
  background-color: #DFDDED;
  border-radius: 10px;
  padding: 20px 10px;
  margin-top: 15px;
}

.post-blog-card-title {
  font-weight: 600;
  font-size: 16px;
}

@media (max-width: 767px) {
  .social-media-post {
    margin-bottom: 20px;
  }
}

.post-blog-image {
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 30px;
  position: relative;
}

.post-blog-image::after {
  padding-top: 100%;
  content: '';
}

.post-blog-image img {
  width: 100%;
}

.post-blog-button {
  width: 35px;
  height: 35px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 20px #0000001f;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1559px) {
  .post-blog-button {
    width: 30px;
    height: 30px;
    font-size: 13px;
  }
}

.post-approve-button {
  color: #099B1A;
}

.post-refuse-button {
  color: #F794A6;
}

.post-hold-button {
  color: #d1b202;
}

.post-approved-button {
  background-color: #C9FFD8;
}

.post-refused-button {
  background-color: #FEEEEF;
}

.post-holded-button {
  background-color: #fcf5ce;
}

.post-comment-button {
  height: 35px;
  padding-left: 9px;
  padding-right: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  box-shadow: 0px 0px 20px #dfdded44;
  font-size: 12px;
  background-color: #E8EDFF;
  color: #577BF9;
}

@media (max-width: 1559px) {
  .post-comment-button {
    height: 30px;
    font-size: 11px;
  }
}

.post-view-button {
  height: 35px;
  font-size: 14px;
  color: #8F62EF;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 20px #0000001f;
  position: absolute;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media (max-width: 1559px) {
  .post-view-button {
    height: 30px;
    font-size: 12px;
  }
}

.post-view-button:hover, .post-comment-button:hover, .post-blog-button:hover {
  opacity: 0.8;
}

.post-view-button:focus, .post-comment-button:focus, .post-blog-button:focus {
  outline: none;
}

.post-button-view {
  width: auto;
  padding: 0 15px;
}

.button-text {
  font-size: 13px;
}

.post-view-image img {
  width: 100%;
  border-radius: 5px;
}

.post-view-data {
  text-align: left;
}

.post-comment-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.post-comment-form textarea {
  height: 60px;
  border: 1px solid #0670E1;
  color: #0670E1;
  width: 84%;
  border-radius: 5px;
  padding: 15px;
  font-family: "Poppins", sans-serif !important;
}

.post-view-comment-submit {
  height: 60px;
  color: white;
  background-color: #0670E1;
  font-size: 18px;
  width: 15%;
  border-radius: 5px;
  font-family: "Poppins", sans-serif !important;
}

.post-comments-title {
  color: #0670E1;
  font-size: 1.15em;
  font-family: "Poppins", sans-serif !important;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 30px;
}

.post-comment {
  display: block;
  width: 100%;
  padding: 25px 20px;
  box-shadow: 0px 3px 7px #0000001f;
  text-align: left;
  color: #0670E1;
  margin-bottom: 10px;
  border-radius: 5px;
}

.post-comment p {
  padding: 0;
  margin: 0;
}

.post-social-media {
  width: 35px;
  height: 35px;
  display: inline-flex;
  margin-right: 5px;
  box-shadow: 0px 3px 7px #0000001f;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 16px;
}

.leads-page-card-approved::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 9px;
  background: -webkit-gradient(linear, left top, right top, from(#099B1A), to(#00C516)) !important;
  background: linear-gradient(to right, #099B1A, #00C516) !important;
  top: -4px;
  left: 0;
  border-radius: 10px 10px 0 0;
}

.leads-page-card-refused::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 9px;
  background: -webkit-gradient(linear, left top, right top, from(#F26A83), to(#FFAEB3)) !important;
  background: linear-gradient(to right, #F26A83, #FFAEB3) !important;
  top: -4px;
  left: 0;
  border-radius: 10px 10px 0 0;
}

.leads-page-card-on-hold::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 9px;
  background: -webkit-gradient(linear, left top, right top, from(#d4c70d), to(#dbce11)) !important;
  background: linear-gradient(to right, #d4c70d, #dbce11) !important;
  top: -4px;
  left: 0;
  border-radius: 10px 10px 0 0;
}

.post-view-comment-submit:disabled {
  opacity: 0.7;
}

.post-blog-grid {
  grid-row-gap: 15px;
  row-gap: 15px;
  display: -ms-grid;
  display: grid;
}

@media (min-width: 768px) {
  .post-blog-grid {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1300px) {
  .post-blog-grid {
    -ms-grid-columns: 25% 25% 25% 25%;
        grid-template-columns: 25% 25% 25% 25%;
  }
}

@media (min-width: 1600px) {
  .post-blog-grid {
    -ms-grid-columns: 20% 20% 20% 20% 20%;
        grid-template-columns: 20% 20% 20% 20% 20%;
  }
}

.leads-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  max-width: 100%;
}

@media (min-width: 768px) {
  .leads-grid {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: 1200px) {
  .leads-grid {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (min-width: 1440px) {
  .leads-grid {
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.leads-page-card {
  background-color: white;
  text-align: center;
  box-shadow: 0px 0px 20px #0000001f;
  font-family: "Poppins", sans-serif !important;
  border-radius: 0px 0px 10px 10px;
  padding: 10px 10px 10px;
  position: relative;
  height: 100%;
  margin: 5px;
}

.leads-page-card::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 6px;
  background: -webkit-gradient(linear, right top, left top, from(#8963F3), to(#CE5BCB));
  background: linear-gradient(to left, #8963F3, #CE5BCB);
  top: -4px;
  left: 0;
  border-radius: 10px 10px 0 0;
}

@media (min-width: 1440px) {
  .leads-page-card::before {
    height: 9px;
  }
}

.c-dark-theme .leads-page-card {
  background-color: #1c1d23 !important;
  color: #DFDDED;
  box-shadow: 0 0 20px 0 #3343E015;
}

.leads-page-card-title {
  font-weight: bold;
  font-size: 1.1em;
  text-align: center;
  color: #33323D;
}

@media (min-width: 1600px) {
  .leads-page-card-title {
    font-size: 1.7em;
  }
}

.c-dark-theme .leads-page-card-title {
  color: #DFDDED;
}

.leads-page-card-info {
  color: #a8a8a8;
  margin-bottom: 5px;
  font-size: 11px;
}

@media (min-width: 1440px) {
  .leads-page-card-info {
    font-size: 14px;
  }
}

.c-dark-theme .leads-page-card-info {
  color: #DFDDED;
}

.leads-page-card-social-media-icon {
  color: #33323D;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 15px;
  margin-top: 20px;
  margin-right: 5px;
  margin-left: 5px;
}

.leads-page-card-social-media-icon:hover {
  opacity: 0.9;
}

.leads-page-card-image-wrapper {
  width: 70px;
  height: 70px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10px;
}

@media (min-width: 1600px) {
  .leads-page-card-image-wrapper {
    width: 100px;
    height: 100px;
  }
}

.leads-page-card-image-wrapper img {
  width: 100%;
}

.leads-page-card-menu-button {
  margin-left: auto;
  background: none;
  border: none;
  color: #33323D;
  display: block;
  margin-top: 5px;
}

.c-dark-theme .leads-page-card-menu-button {
  color: #DFDDED;
}

.leads-actions-wrappper {
  display: block;
  margin-left: auto;
  position: relative;
}

.leads-action-div {
  background-color: white;
  border-radius: 5px;
  padding: 10px 0px;
  color: #33323D;
  position: absolute;
  right: 20px;
  top: 0;
  box-shadow: 0px 0px 20px #0000001f;
  z-index: 2;
}

.leads-action-div-button {
  background-color: white;
  color: #33323D;
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
  display: block;
  width: 100%;
  text-align: left;
  margin-bottom: 0px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  padding: 5px 20px;
  font-size: 12px;
}

@media (min-width: 1440px) {
  .leads-action-div-button {
    font-size: 15px;
  }
}

.leads-page-card-menu-button:focus {
  outline: none;
}

.leads-action-div-button:hover {
  background-color: #e9e9e9;
}

#leads-page-search-wrapper {
  position: relative;
  text-align: right;
}

#leads-page-search {
  height: 45px;
  background-color: #ECEDF6;
  color: #0e0e0e;
  font-family: "Poppins", sans-serif !important;
  border-radius: 5px;
  padding: 10px;
  border: none;
  width: 300px;
  max-width: 100%;
  margin-right: 0 !important;
}

@media screen and (max-width: 768px) {
  #leads-page-search {
    width: 100%;
    margin: 0 !important;
  }
}

#leads-page-search::-webkit-input-placeholder {
  color: #D0D2E1;
}

#leads-page-search:-ms-input-placeholder {
  color: #D0D2E1;
}

#leads-page-search::-ms-input-placeholder {
  color: #D0D2E1;
}

#leads-page-search::placeholder {
  color: #D0D2E1;
}

#leads-page-search:focus {
  outline: none;
}

#leads-page-search-button {
  position: absolute;
  right: 24px;
  top: 10px;
  cursor: pointer;
  opacity: 0.9;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  background-color: transparent;
  border: 0;
}

#leads-page-search-button:hover {
  opacity: 1;
}

.c-dark-theme #leads-page-search {
  background-color: #1c1d23 !important;
}

.leads-page-forms {
  font-family: "Poppins", sans-serif !important;
  font-family: 1.1em;
}

.leads-page-forms label {
  font-weight: 600;
}

.leads-page-forms select {
  background-color: transparent;
  border: 1px solid white;
  margin-right: 10px;
}

#leads-page-sort-select-genrer, #leads-page-sort-select-position, .custom-select-input {
  height: 43px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 5px;
  color: #34345B;
  background-color: white;
  box-shadow: 0px 0px 20px #dfdded8a;
  border-color: white !important;
}

#searchVault {
  border: 0;
}

.c-dark-theme #leads-page-sort-select-genrer, .c-dark-theme #leads-page-sort-select-position, .c-dark-theme .custom-select-input {
  color: #DFDDED;
  background-color: #1C1D23;
  box-shadow: none;
  border-color: #1C1D23;
}

#leads-page-sort-select-genrer:focus, #leads-page-sort-select-position:focus, .custom-select-input:focus {
  background-color: #E8EDFF;
  color: #577BF9;
  outline: none;
  border: 1px solid #E8EDFF;
}

.leads-page-selector {
  background-color: #ECEDF6;
  border-radius: 7px;
  padding: 5px;
  height: 44px;
  display: inline-block;
}

.c-dark-theme .leads-page-selector {
  background-color: #1c1d23 !important;
}

.leads-page-selector-button {
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 40px;
  border-radius: 5px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.leads-page-selector-button:focus {
  outline: none;
}

.leads-page-selector-button-active {
  background-color: white;
  box-shadow: 0px 0px 20px #dfdded46;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.c-dark-theme .leads-page-selector-button-active {
  background-color: #282933;
  box-shadow: none;
}

.leads-page-selector-button:first-child {
  margin-right: 10px;
}

.leads-page-table-image-wrapper {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50px;
}

.leads-page-table-image-wrapper img {
  height: 100%;
}

.add-lead-form-icon {
  width: 40px;
  text-align: center;
  margin-right: 10px;
}

.add-lead-form-input {
  height: 45px;
  border-radius: 5px;
  border: 1px solid #D0D2E1;
  color: #424242;
  width: 90%;
  padding-left: 15px;
  padding-right: 15px;
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
}

.add-lead-form-input::-webkit-input-placeholder {
  font-family: "Poppins", sans-serif !important;
  color: #D0D2E1;
  font-size: 16px;
}

.add-lead-form-input:-ms-input-placeholder {
  font-family: "Poppins", sans-serif !important;
  color: #D0D2E1;
  font-size: 16px;
}

.add-lead-form-input::-ms-input-placeholder {
  font-family: "Poppins", sans-serif !important;
  color: #D0D2E1;
  font-size: 16px;
}

.add-lead-form-input::placeholder {
  font-family: "Poppins", sans-serif !important;
  color: #D0D2E1;
  font-size: 16px;
}

.add-lead-form-input-required {
  border: 1px solid #737186;
}

#leads-page-search-form {
  position: relative;
}

@media (max-width: 767px) {
  #leads-page-search-form {
    display: none;
  }
}

.add-lead-button {
  height: 45px !important;
  padding-left: 15px;
  padding-right: 15px;
}

.table-list-leads {
  width: 100%;
  overflow-y: hidden;
  height: 100%;
}

.table-list-leads td {
  font-size: 0.96em !important;
  white-space: nowrap;
}

.table-list-leads .breakable-td {
  white-space: pre-wrap;
}

.user-label {
  width: 50px;
  font-size: 16px;
  display: block;
  padding-bottom: 5px;
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
}

.label {
  font-family: "Poppins", sans-serif !important;
}

.recharts-legend-item {
  background-color: white;
  color: #33323D;
  font-size: 13px;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: "Poppins", sans-serif !important;
  box-shadow: 0px 5px 15px -6px rgba(51, 67, 224, 0.3);
  margin-right: 5px;
  margin-bottom: 10px;
}

.recharts-legend-wrapper {
  width: auto !important;
}

.recharts-default-legend {
  text-align: left !important;
  width: auto !important;
}

.recharts-default-tooltip {
  background-color: white;
}

.home-stats-card .recharts-default-tooltip {
  background-color: rgba(0, 0, 0, 0.7) !important;
  border-radius: 5px;
  border: none !important;
}

.home-stats-card .recharts-default-tooltip .recharts-tooltip-label {
  color: white;
}

.home-stats-card .recharts-default-tooltip .recharts-tooltip-item {
  color: white !important;
}

.competitors-page-wrapper {
  background-color: #F5F6F9;
  padding: 70px 30px;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 #3343E015;
  margin-bottom: 40px;
  position: relative;
}

@media screen and (max-width: 768px) {
  .competitors-page-wrapper {
    background-color: #F5F6F9;
    padding: 70px 5px 30px;
  }
}

.c-dark-theme .competitors-page-wrapper {
  background-color: #1c1d23 !important;
  color: #DFDDED;
}

.competitor-action-buttons {
  position: absolute;
  top: 20px;
  right: 20px;
}

.competitor-delete-button {
  width: 30px;
  height: 30px;
  background-color: #FEEEEF;
  border-radius: 100px;
}

.competitor-edit-button {
  width: 30px;
  height: 30px;
  background-color: #C9FFD8;
  border-radius: 100px;
  margin-right: 7px;
}

.competitor-edit-button:focus, .competitor-delete-button:focus {
  outline: none;
}

.competitors-page-logo-wrapper {
  text-align: center;
  margin-bottom: 30px;
  height: 80px;
}

.competitors-page-logo {
  max-height: 80px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  max-width: 100%;
}

.competitors-page-stats-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px;
  border-radius: 10px;
  background-image: url(/static/media/page-header-bg.449d65bd.svg), linear-gradient(45deg, #1D3AFF 0%, #922EFF 100%);
  background-size: cover;
  color: white;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600;
  margin: 5px;
}

.competitors-page-stats-card-pink {
  background-image: url(/static/media/page-header-bg.449d65bd.svg), linear-gradient(45deg, #EB5C8D 0%, #EB5CC2 100%);
  background-size: cover;
}

.competitors-page-stats-card-purple {
  background-image: url(/static/media/page-header-bg.449d65bd.svg), linear-gradient(45deg, #8963F3 0%, #CE5BCB 100%);
  background-size: cover;
}

.competitors-page-stats-card-yellow {
  background-image: url(/static/media/page-header-bg.449d65bd.svg), linear-gradient(45deg, #F2A600 0%, #FFCF67 100%);
  background-size: cover;
}

.versus-x {
  font-weight: bold;
  font-family: "Poppins", sans-serif !important;
  font-size: 3em;
  color: #33323D;
}

.c-dark-theme .versus-x {
  color: #DFDDED;
}

.competitors-page-text {
  font-size: 0.9em;
}

.competitors-page-number {
  font-size: 1.3em;
}

.modal-confirm-button {
  background-color: #C9FFD8;
  border-radius: 5px;
  color: #009A1A;
  padding: 10px 20px;
  font-size: 19px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.modal-confirm-button:hover {
  background-color: #b8facb;
  color: #008116;
}

.modal-cancel-button {
  background-color: #FEEEEF;
  border-radius: 5px;
  color: #F78892;
  padding: 10px 20px;
  font-size: 19px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.modal-cancel-button:hover {
  background-color: #fddee0;
  color: #f36873;
}

#confirm-modal-title {
  font-size: 24px;
  font-weight: 400;
}

.boards {
  display: inline-flex;
  flex: 1 1;
  height: 100%;
  width: 100%;
  border-top: 1px solid #d4d4d4;
}

.board {
  margin: 0px .5rem 0;
  padding: 10px;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  scroll-behavior: smooth;
}

.board h3 {
  padding: 15px 10px !important;
  min-width: 300px;
  width: 100%;
  margin: 20px 0 0;
  font-weight: bold;
  font-size: 16px;
  border-radius: 5px;
}

.dropzone {
  padding: 8px 16px 30px;
  min-width: 300px;
  min-height: 200px;
  justify-content: center;
  height: 100%;
  max-height: 68vh;
  overflow-y: scroll;
  margin-top: 20px;
}

.kanbanCard {
  background-color: #FFFF;
  padding: 27px 25px 20px;
  width: 100%;
  margin: auto;
  margin-bottom: 2rem;
  border-radius: 3px;
  font-weight: 600;
  font-size: 16px;
  position: relative;
}

.kanban-image-wrapper {
  height: 150px;
  width: 150px;
  margin-right: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.deleteBtn {
  position: absolute;
  bottom: 10px;
  right: 15px;
  color: #E2163B;
  font-size: 1.1em;
}

.editBtn {
  position: absolute;
  bottom: 10px;
  right: 40px;
  font-size: 1.1em;
}

.description {
  color: #434343;
  font-weight: normal;
  font-size: 14px;
}

.title {
  font-weight: 700;
  font-size: 1.2em;
  color: #1C1C2E;
  margin-bottom: 20px;
}

.task-status-area {
  font-size: 0.95em;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.red {
  border-bottom: 4px solid #E2163B;
}

.purple {
  border-bottom: 4px solid #4515CF;
}

.blue {
  border-bottom: 4px solid #6DDAF7;
}

.yellow {
  border-bottom: 4px solid #F5D202;
}

.green {
  border-bottom: 4px solid #0CBC20;
}

.blue-bg {
  background-color: #6DDAF7;
  color: white;
}

.yellow-bg {
  background-color: #F5D202;
  color: white;
}

.green-bg {
  background-color: #0CBC20;
  color: white;
}

.blue-border {
  border: 1px solid #6DDAF7;
}

.yellow-border {
  border: 1px solid #F5D202;
}

.green-border {
  border: 1px solid #0CBC20;
}

.highlight {
  background-color: #D7D7D7cc;
}

.kanbanCard, .dropzone {
  -webkit-transition: 400ms;
  transition: 400ms;
}

.is-dragging, .darkmode .is-dragging {
  cursor: move !important;
  cursor: -webkit-grabbing;
  opacity: 0.3;
}

.over {
  background: #E9E9E9;
}

.form-inline {
  display: flex;
  align-items: center;
  justify-content: center;
}

label {
  margin: 0 !important;
}

input {
  margin: 0rem 1rem 0rem 0.5rem !important;
}

.priority {
  cursor: pointer;
  color: #989898;
}

.is-priority, .darkmode .is-priority {
  cursor: pointer;
  color: #FF7A00;
}

.delete {
  color: #E2163B;
  cursor: pointer;
}

.invisibleBtn:focus, #theme-btn:focus {
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: none !important;
}

.invisibleBtn {
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  cursor: pointer;
}

/* dark mode */
.darkmode {
  background-color: #1B1C28;
}

.darkmode * {
  color: white;
  background-color: #1B1C28;
}

.darkmode .kanbanCard {
  background-color: #1B1C28;
}

.darkmode .controls {
  background-color: #1B1C28;
}

.darkmode .form-control {
  box-shadow: none;
  border: none;
  background-color: #252632;
  color: white;
}

.darkmode .form-control:focus {
  box-shadow: none;
  border: none;
  background-color: #252632;
  color: white;
}

.darkmode .form-inline {
  background-color: #1B1C28;
}

.darkmode .btn-dark {
  background-color: #252632;
  border: none;
}

.darkmode .btn-dark:hover {
  background-color: #292A36 !important;
}

.darkmode .dropzone {
  background-color: #292A36 !important;
}

.darkmode .boards {
  border-top: 1px solid #252632;
  scrollbar-color: dark;
}

.darkmode h3 {
  background-color: #1B1C28;
}

.darkmode .over {
  background-color: #15151D !important;
}

.darkmode .delete {
  color: #dc3545;
}

.darkmode #theme-btn {
  color: white !important;
}

.darkmode h3 {
  color: white !important;
}

.drive-photos-grid {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  max-height: 280px;
  overflow-y: auto;
  margin-bottom: 20px;
  justify-content: center;
}

/*
.drive-photos-grid::after {
    content: "";
    flex: auto;
    justify-content: center;
}*/
.drive-photo {
  position: relative;
  width: 15%;
  overflow: hidden;
  border-radius: 5px;
  margin: 1.4%;
  margin-bottom: 1.4%;
}

.drive-photo:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.drive-photo-content {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.drive-photos-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.drive-phoyos-control span {
  font-family: "Poppins", sans-serif !important;
  font-size: 10px;
  text-transform: uppercase;
}

.drive-photo-content img {
  height: 100%;
}

.my-drive-upload {
  height: 100%;
}

.my-drive-upload-box {
  width: 100%;
  border: 2px dashed #CAC9D4;
  padding: 20px;
  text-align: center;
  color: #CAC9D4;
  border-radius: 10px;
  margin-top: 15px;
  background-color: transparent;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.submit-drive-wrapper {
  width: 70px;
}

.select-file-drive {
  border: 1px dashed #CAC9D4;
  background-color: transparent;
  padding: 5px 10px;
  width: 170px;
  margin-right: 10px;
  border-radius: 5px;
  color: #9796a0;
}

.my-drive-upload-box:focus {
  outline: none;
}

.my-drive-upload-button {
  color: white;
  background: #2487f1;
  background: linear-gradient(149deg, #2487f1 0%, #0e6bcf 100%);
  width: 100%;
  padding: 5px;
  height: 35px;
  border-radius: 10px;
}

.billing-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1.4fr 1.4fr 1fr;
      grid-template-columns: 1.4fr 1.4fr 1fr;
  -ms-grid-rows: 220px auto;
      grid-template-rows: 220px auto;
      grid-template-areas: "header header aside" "body body aside";
  height: auto;
  padding: 0;
  -webkit-column-gap: 20px;
          grid-column-gap: 20px;
          column-gap: 20px;
}

@media (max-width: 1279px) {
  .billing-grid {
    display: block;
  }
}

@media (max-width: 1440px) {
  .billing-grid {
    -ms-grid-rows: 190px auto;
        grid-template-rows: 190px auto;
    grid-row-gap: 20px;
    row-gap: 20px;
  }
}

#billing-header {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: header;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

@media (max-width: 1279px) {
  #billing-header {
    display: block;
  }
}

#billing-header .billing-header-card {
  border: 1px solid #E1E1E1;
  border-radius: 15px;
  padding: 30px 40px 20px 40px;
  background-color: white;
  background-image: url(/static/media/card-bg.c8adf61a.svg);
  background-size: cover;
  background-position: right top;
  color: white;
  width: 49%;
}

@media (max-width: 1366px) {
  #billing-header .billing-header-card {
    padding: 20px 20px 20px 20px;
  }
}

@media (max-width: 1279px) {
  #billing-header .billing-header-card {
    width: 100%;
    margin-bottom: 10px;
    padding: 20px;
    font-size: 0.9em;
  }
}

#billing-header .billing-header-card .billing-plan {
  border: 1px solid white;
  border-radius: 100px;
  display: inline-block;
  padding: 10px 15px;
  margin-bottom: 20px;
}

#billing-header .billing-header-card .billing-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#billing-header .billing-header-card .billing-footer h2 {
  font-weight: 600;
  font-size: 3em;
}

#billing-header .billing-header-card .billing-footer .cents {
  font-weight: normal;
  font-size: 0.6em;
}

#billing-header .billing-header-card .billing-footer .change-plan-button {
  background-color: transparent;
  border-bottom: 1px solid white;
  padding: 0 0 3px 0;
  color: white;
}

#billing-header .next-payment-card {
  border: 1px solid #E1E1E1;
  border-radius: 15px;
  padding: 30px 40px 20px 40px;
  color: #1E2438;
  width: 49%;
}

@media (max-width: 1366px) {
  #billing-header .next-payment-card {
    padding: 20px 20px 20px 20px;
  }
}

@media (max-width: 1279px) {
  #billing-header .next-payment-card {
    width: 100%;
    margin-bottom: 10px;
    padding: 20px;
    font-size: 0.9em;
  }
}

#billing-header .next-payment-card .next-payment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#billing-header .next-payment-card .next-payment-header .payment-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 60px;
  height: 60px;
  border-radius: 100px;
  background: linear-gradient(45deg, #1D3AFF 0%, #922EFF 100%);
}

#billing-header .next-payment-card .bar {
  letter-spacing: -4px;
}

#billing-header .next-payment-card h3 {
  font-size: 1.4em;
  font-weight: 400;
  position: relative;
}

#billing-header .next-payment-card h2 {
  font-weight: 600;
  font-size: 3em;
}

#billing-header .next-payment-card .cents {
  font-weight: normal;
  font-size: 0.6em;
}

#billing-header .next-payment-card .next-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#billing-header .next-payment-card .next-footer p {
  margin: 0;
  font-size: 1.1em;
}

#billing-header .next-payment-card .next-footer .manage-button {
  padding: 10px;
  color: white;
  border-radius: 5px;
  background-color: #1E2438;
  font-size: 0.7em;
}

#billing-body {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: body;
  padding-top: 20px;
}

@media (max-width: 1440px) {
  #billing-body {
    padding-top: 0px;
  }
}

#billing-body .table-wrapper {
  background-color: white;
  border-radius: 15px;
  overflow: hidden;
}

#billing-body h2 {
  font-weight: 600;
  margin-top: 0px;
}

#billing-body table {
  width: 100%;
  overflow: hidden;
  box-shadow: 0px 0px 20px #0000001f;
}

@media (max-width: 1279px) {
  #billing-body table {
    display: block;
    overflow-x: scroll;
    margin-top: 20px;
  }
}

@media (max-width: 1440px) {
  #billing-body table {
    font-size: 0.8em;
  }
}

#billing-body table thead {
  background: linear-gradient(45deg, #0676ED 0%, #0569D4 20%, #033B77 100%);
  color: white;
}

#billing-body table thead th {
  font-weight: 500;
  padding: 13px 15px;
}

#billing-body table thead th:first-child {
  padding-left: 30px;
}

#billing-body table tbody td {
  padding: 25px 15px;
  color: #33323D;
  font-weight: 500;
  font-size: 0.95em;
}

#billing-body table tbody td .download {
  color: #33323D;
  text-decoration: underline;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

#billing-body table tbody td .download:hover {
  text-decoration: underline !important;
  color: #033B77;
}

#billing-body table tbody td .payment-collapse-button {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background-color: #F5F6FA;
  color: #0957AB;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

#billing-body table tbody td .payment-collapse-button:hover {
  background-color: #edeff5;
}

#billing-body table tbody td .status {
  display: inline-block;
  padding: 7px 10px;
  border-radius: 5px;
  font-size: 10px;
}

#billing-body table tbody td .pending {
  background-color: #FEF4D8;
  color: #f1b716;
}

#billing-body table tbody td .paid {
  background-color: #C6E9CA;
  color: #099B1A;
}

#billing-body table tbody td:first-child {
  padding-left: 30px;
}

#billing-body table tbody .collapse-td {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
  padding: 20px 0;
}

@media (max-width: 1279px) {
  #billing-body table tbody .collapse-td {
    font-size: 0.8em;
  }
}

#billing-body table tbody .collapse-td div {
  margin: 0 15px;
}

#billing-body table tbody .collapse-td .amount {
  background-color: #F5F6FA;
  border-radius: 5px;
  padding: 10px;
}

#billing-body table tbody .collapse-td .td-plan {
  text-transform: uppercase;
  padding-bottom: 3px;
  border-bottom: 2px solid #707070;
  font-weight: bold;
}

#billing-aside, #plans-aside {
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  -ms-grid-column: 3;
  grid-area: aside;
}

#billing-aside .aside-card, #plans-aside .aside-card {
  background-color: #F8F9FB;
  text-align: center;
  border-radius: 15px;
  position: relative;
  padding: 40px 20px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 20px #9394c010;
}

#billing-aside .aside-card .header, #plans-aside .aside-card .header {
  margin-bottom: 20px;
}

#billing-aside .aside-card .header .image, #plans-aside .aside-card .header .image {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  margin-left: auto;
  margin-right: auto;
  background-size: cover;
  background-position: center;
  margin-bottom: 10px;
}

#billing-aside .aside-card .header .name, #plans-aside .aside-card .header .name {
  font-weight: 700;
  font-size: 1.5em;
}

#billing-aside .aside-card p, #plans-aside .aside-card p {
  margin-bottom: 10px;
}

#billing-aside .aside-card .billing-period, #plans-aside .aside-card .billing-period {
  margin-top: 10px;
  background-color: white;
  box-shadow: 0px 0px 20px #0000001f;
  border-radius: 5px;
  padding: 20px 30px;
  text-align: center;
  display: inline-block;
}

#billing-aside .aside-card .billing-period p, #plans-aside .aside-card .billing-period p {
  margin-bottom: 0;
  font-size: 0.8em;
}

#billing-aside .payment-information, #plans-aside .payment-information {
  padding-top: 20px;
}

#billing-aside .payment-information .header, #plans-aside .payment-information .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#billing-aside .payment-information .header .title, #plans-aside .payment-information .header .title {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

#billing-aside .payment-information .header .title h2, #plans-aside .payment-information .header .title h2 {
  font-size: 0.9em;
  margin-left: 10px;
  font-weight: 600;
  margin-bottom: 0;
}

#billing-aside .payment-information .header button, #plans-aside .payment-information .header button {
  width: 35px;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #0957AB;
  border-radius: 10px;
  background-color: white;
  margin-left: 5px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

#billing-aside .payment-information .header .checked, #plans-aside .payment-information .header .checked {
  background-color: #E6F6EA;
  border: 1px solid #099B1A;
  color: #099B1A;
}

#billing-aside .payment-information .header button:hover, #plans-aside .payment-information .header button:hover {
  opacity: 0.8;
}

#billing-aside .payment-information .credit-card, #plans-aside .payment-information .credit-card {
  display: inline-block;
  width: 170px;
  padding: 15px;
  border-radius: 20px;
  background: linear-gradient(110deg, #4E4D4D 0%, #000000 100%);
  color: white;
}

@media (max-width: 1279px) {
  #billing-aside .payment-information .credit-card, #plans-aside .payment-information .credit-card {
    margin-bottom: 15px;
  }
}

#billing-aside .payment-information .credit-card .header, #plans-aside .payment-information .credit-card .header {
  margin-bottom: 7px;
}

#billing-aside .payment-information .credit-card .card-number, #plans-aside .payment-information .credit-card .card-number {
  font-size: 9px;
  text-align: left;
  margin-bottom: 0px;
}

#billing-aside .payment-information .credit-card .footer, #plans-aside .payment-information .credit-card .footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

#billing-aside .payment-information .credit-card .footer .date-label, #plans-aside .payment-information .credit-card .footer .date-label {
  font-size: 7px;
  margin-bottom: 0px;
}

#billing-aside .payment-information .credit-card .footer .text, #plans-aside .payment-information .credit-card .footer .text {
  font-size: 9px;
  margin: 0;
}

#billing-aside .payment-information .content, #plans-aside .payment-information .content {
  text-align: center;
}

@media (min-width: 1366px) {
  #billing-aside .payment-information .content, #plans-aside .payment-information .content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

#billing-aside .payment-information .content .card-data, #plans-aside .payment-information .content .card-data {
  font-weight: 500;
  font-size: 0.8em;
  margin-left: 10px;
  text-align: center;
}

@media (min-width: 1366px) {
  #billing-aside .payment-information .content .card-data, #plans-aside .payment-information .content .card-data {
    text-align: left;
  }
}

@media (min-width: 1440px) {
  #billing-aside .payment-information .content .card-data, #plans-aside .payment-information .content .card-data {
    font-size: 0.9em;
    text-align: left;
  }
}

#billing-aside .payment-information .content .card-data p, #plans-aside .payment-information .content .card-data p {
  margin: 0;
}

#billing-aside .payment-information .content .card-data span, #plans-aside .payment-information .content .card-data span {
  color: #8C9CAB;
}

.services-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 60% 40%;
      grid-template-columns: 60% 40%;
  -webkit-column-gap: 30px;
          grid-column-gap: 30px;
          column-gap: 30px;
}

@media (max-width: 1279px) {
  .services-grid {
    display: block;
  }
}

.services-grid .list .active {
  box-shadow: 0px 0px 20px #8963f327;
  background-color: white;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.services-grid .list .service {
  padding: 25px 20px;
  border-radius: 15px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 30% 70%;
      grid-template-columns: 30% 70%;
  -webkit-column-gap: 40px;
          grid-column-gap: 40px;
          column-gap: 40px;
  margin-bottom: 10px;
}

@media (max-width: 1279px) {
  .services-grid .list .service {
    display: block;
  }
}

@media (max-width: 767px) {
  .services-grid .list .service {
    box-shadow: 0px 0px 20px #8963f327;
    background-color: white;
  }
}

.services-grid .list .service .image {
  height: 150px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  border-radius: 15px;
  box-shadow: 0px 0px 20px #a352a223;
  overflow: hidden;
  position: relative;
  background-position: center;
  background-size: cover;
}

.services-grid .list .service .image img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: auto;
  width: 100%;
}

.services-grid .list .service .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.services-grid .list .service .content .topbar {
  height: 6px;
  border-radius: 10px;
  width: 100px;
  background-color: #8963F3;
  content: '';
}

@media (max-width: 1279px) {
  .services-grid .list .service .content .topbar {
    margin-top: 20px;
  }
}

.services-grid .list .service .content h1 {
  font-size: 1.7em;
  margin-top: 15px;
  margin-bottom: 15px;
  font-weight: 600;
  color: #8963F3;
}

.services-grid .list .service .content p {
  margin-bottom: 0;
  color: #33323D;
}

.services-grid .info {
  position: relative;
  padding-right: 20px;
}

@media (max-width: 767px) {
  .services-grid .info {
    padding-right: 0;
  }
}

.services-grid .info .placeholder {
  padding: 40px;
  border-radius: 15px;
  background-color: #F4F4FB;
}

.services-grid .info .placeholder .inner {
  padding: 50px;
  border: 2px dashed #b9b8ba38;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 70vh;
  font-size: 2.3em;
  font-weight: 600;
  line-height: 1em;
  color: #D4D5DD;
}

.service-info {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.service-info .period-change {
  cursor: pointer;
}

.service-info img {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 15px;
}

.service-info p {
  word-wrap: break-word;
  max-width: 100%;
  text-align: center;
}

.service-info .price {
  font-size: 1.3em;
  padding: 10px 15px;
  background: linear-gradient(176deg, #8963F3 0%, #6E44E3 100%);
  color: white;
  display: inline-block;
  border-radius: 5px;
  font-weight: 300;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  margin-top: 10px;
}

.service-info .price span {
  font-weight: 600;
}

.service-info .form-group label {
  font-weight: bold;
  color: #AA2378;
  margin-right: 10px;
}

.service-info .form-group input[type="radio"] {
  background-color: #AA2378;
  margin-right: 5px !important;
}

.service-info .form-group input[type="radio"]:after {
  background-color: #AA2378;
  border-color: #AA2378;
}

.service-info .plans-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .service-info .plans-buttons {
    display: block;
    text-align: center;
  }
}

.service-info .plans-buttons button {
  margin: 0 10px;
  height: 58px;
  width: 170px;
  text-align: center;
  border-radius: 15px;
  color: #EB5CA7;
  border: 1px solid #EB5CA7;
  font-size: 1.2em;
  background-color: transparent;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media (max-width: 767px) {
  .service-info .plans-buttons button {
    display: inline-block;
    margin-bottom: 10px;
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1.1em;
    height: 50px;
  }
}

.service-info .plans-buttons button:hover {
  color: white;
  background: #eb5c8d;
  background: linear-gradient(176deg, #eb5c8d 0%, #eb5cc2 100%);
}

.service-info .plans-buttons .active {
  color: white;
  background: #eb5c8d;
  background: linear-gradient(176deg, #eb5c8d 0%, #eb5cc2 100%);
}

.service-info .plan-qtd {
  font-size: 1.1em;
  color: #33323D;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #DFDDED;
}

.service-info .plan-qtd .qtd {
  display: flex;
  align-items: center;
  margin-left: 15px;
  justify-content: center;
}

.service-info .plan-qtd .qtd span {
  text-align: center;
}

.service-info .benefits {
  margin-bottom: 20px;
  text-align: center;
}

.service-info .benefits .benefit {
  margin: 0 10px 10px;
  font-size: 0.9em;
  display: inline-block;
}

.service-info .adds {
  text-align: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #DFDDED;
  margin-top: 10px;
}

.service-info .adds div {
  display: inline-block;
  margin: 0 10px;
}

.service-info .cta {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

.service-info .cta button {
  width: 49%;
  height: 58px;
  color: #5242C2;
  border: 1px solid #5242C2;
  border-radius: 15px;
  background-color: transparent;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.service-info .cta button:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.service-info .cta .fill {
  background-color: #5242C2;
  color: white !important;
}

.roles-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 50px calc(100% - 140px) 90px;
      grid-template-columns: 50px calc(100% - 140px) 90px;
}

@media (max-width: 767px) {
  .roles-grid {
    display: block;
  }
}

.roles-grid .controls {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}

@media (max-width: 767px) {
  .roles-grid .controls {
    display: none;
  }
}

.roles-grid .controls button {
  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 40px;
  margin-bottom: 10px;
  box-shadow: 0px 0px 20px #6389f327;
}

.roles-grid .roles {
  overflow-x: hidden;
  white-space: nowrap;
  padding: 0px 20px 30px 20px;
}

@media (max-width: 767px) {
  .roles-grid .roles {
    padding: 0;
  }
}

.roles-grid .roles .role:hover {
  box-shadow: 0px 0px 20px #6389f359;
}

.roles-grid .roles .role {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 20px #6389f327;
  text-align: center;
  padding: 0 20px 40px 20px;
  margin-top: 35px;
  margin-right: 20px;
  display: inline-block;
  width: 220px;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media (max-width: 767px) {
  .roles-grid .roles .role {
    display: block;
    width: 100%;
    margin-bottom: 50px;
  }
}

.roles-grid .roles .role .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  -webkit-transform: translateY(-35px);
          transform: translateY(-35px);
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  width: 70px;
  height: 70px;
  border: 1px solid #0676ED;
  color: #0676ED;
  margin-bottom: -20px;
  position: relative;
}

.roles-grid .roles .role .icon span {
  position: absolute;
  top: 52%;
  left: 52%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #9769FF;
  border: 1px solid white;
  color: white;
  font-size: 11px;
  width: 21px;
  height: 21px;
  border-radius: 105px;
  font-weight: 600;
}

.roles-grid .roles .role h1 {
  font-weight: 600;
  font-size: 1.1em;
  margin: 0;
  color: #33323D;
}

.roles-grid .add {
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .roles-grid .add {
    display: none;
  }
}

.roles-grid .add .add-role {
  width: 70px;
  height: 70px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 70px;
  border: 1px solid #DFDDED;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  stroke: #DFDDED;
  margin-left: auto;
}

.roles-grid .add .add-role:hover {
  background-color: transparent;
  stroke: #0676ED;
  border-color: #0676ED;
}

.roles-table {
  background-color: white;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0px 0px 20px #6389f327;
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}

@media (max-width: 1279px) {
  .roles-table {
    font-size: 0.8em;
    padding: 15px 15px 15px 30px;
    margin-top: -20px;
  }
}

.roles-table table {
  table-layout: fixed;
  width: 100%;
  *margin-left: -130px;
  /*ie7*/
}

.roles-table td, .roles-table th {
  vertical-align: center;
  padding: 10px;
  width: 100px;
  font-weight: 500;
  text-align: center;
}

@media (max-width: 1279px) {
  .roles-table td, .roles-table th {
    width: 70px;
    padding: 10px;
  }
}

.roles-table .fix {
  position: absolute;
  *position: relative;
  /*ie7*/
  margin-left: -150px;
  width: 150px;
  text-align: right;
}

@media (max-width: 1279px) {
  .roles-table .fix {
    margin-left: -130px;
    width: 150px;
  }
}

.roles-table .outer {
  position: relative;
}

.roles-table .inner {
  overflow-x: scroll;
  overflow-y: visible;
  width: calc(100% - 130px);
  margin-left: 130px;
}

@media (max-width: 1279px) {
  .roles-table .inner {
    margin-left: 90px;
    width: calc(100% - 90px);
  }
}

.roles-table .inner::-webkit-scrollbar {
  height: 6px;
}

.roles-table .inner::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.roles-table .inner::-webkit-scrollbar-thumb {
  background: #888888;
}

.roles-table .inner::-webkit-scrollbar-thumb:hover {
  background: #555555;
}

.roles-table .permission-input {
  -webkit-transform: translateX(5px);
          transform: translateX(5px);
}

.plans-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1.4fr 1.4fr 1fr;
      grid-template-columns: 1.4fr 1.4fr 1fr;
  -ms-grid-rows: 80px auto;
      grid-template-rows: 80px auto;
      grid-template-areas: "header header aside" "body body aside";
  height: auto;
  padding: 0;
  -webkit-column-gap: 20px;
          grid-column-gap: 20px;
          column-gap: 20px;
}

@media (max-width: 1279px) {
  .plans-grid {
    display: block;
  }
}

.plans-grid #plans-header {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: header;
  display: flex;
}

.plans-grid #plans-header .back-button {
  background-color: white;
  border-radius: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  box-shadow: 0px 5px 20px #6389f327;
  margin: 0 10px 0 0 !important;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.plans-grid #plans-header .back-button:hover {
  box-shadow: 0px 5px 20px #6389f365;
}

.plans-grid #plans-header .hero {
  width: calc(100% - 90px);
  color: white;
  border-radius: 10px;
  padding: 0px 30px;
  height: 80px;
  font-size: 1.3em;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 300;
}

.plans-grid #plans-header .hero .plan {
  margin-left: 30px;
  border-radius: 100px;
  border: 1px solid white;
  color: white;
  padding: 5px 15px;
  font-size: 0.8em;
}

.plans-grid #plans-header .hero .value {
  display: flex;
  align-items: center;
}

.plans-grid #plans-header .hero .value span {
  margin-right: 20px;
}

.plans-grid #plans-header .hero .value .price {
  font-weight: 600;
  font-size: 2.1em;
}

.plans-grid #plans-body {
  -ms-grid-row: 2;
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-area: body;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
  padding: 30px 0 0 10px;
}

.plans-grid #plans-body .plan-block {
  text-align: center;
  max-width: 330px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 10px 40px 10px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  color: #1E2438;
  display: block;
}

.plans-grid #plans-body .plan-block:hover {
  border-radius: 10px;
  box-shadow: -1px 10px 30px 0px #6389f327;
  background-color: white;
}

.plans-grid #plans-body .plan-block img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  height: 150px;
}

.plans-grid #plans-body .plan-block .plan {
  border-radius: 100px;
  border: 1px solid #1E2438;
  color: #1E2438;
  padding: 5px 15px;
  font-size: 0.8em;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 600;
}

.plans-grid #plans-body .plan-block .price {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 20px;
}

.plans-grid #plans-body .plan-block .price .price-flex {
  display: inline-flex;
  align-items: flex-start;
}

.plans-grid #plans-body .plan-block .price .price-flex .dolar {
  color: #1E2438;
  font-weight: 600;
  font-size: 1em;
}

.plans-grid #plans-body .plan-block .price .price-flex .value {
  color: #0676ED;
  font-size: 3.5em;
  line-height: 0.9em;
  font-weight: 600;
}

.plans-grid #plans-body .plan-block .price .period {
  font-weight: 300;
  color: #1E2438;
}

.plans-grid #plans-body .plan-block .title {
  margin-top: 20px;
  margin-bottom: 0px;
}

.plans-grid #plans-body .plan-block .subtitle {
  margin: 0 0 10px;
  padding: 0 20px 10px 20px;
  border-bottom: 1px solid #DFDDED;
  display: inline-block;
}

.plans-grid #plans-body .plan-block .button {
  background-color: #0676ED;
  border-radius: 100px;
  padding: 10px 30px;
  color: white;
  margin-top: 50px;
  box-shadow: -1px 5px 15px 0px #cac6e9;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  display: inline-block;
}

.plans-grid #plans-body .plan-block .button:hover {
  box-shadow: -1px 10px 15px 0px #cac6e9;
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

.plans-grid #plans-aside {
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  -ms-grid-column: 3;
  grid-area: aside;
}

.tabs {
  margin-bottom: 20px;
}

.tabs .tab {
  display: inline-block;
  font-weight: 600;
  font-size: 1.2em;
  background-color: transparent;
  color: #33323D;
  opacity: 0.5;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin-right: 20px;
}

.tabs .tab:hover {
  opacity: 1;
}

@media (max-width: 768px) {
  .tabs .tab {
    margin-bottom: 10px;
  }
}

@media (min-width: 1440px) {
  .tabs .tab {
    font-size: 1.5em;
  }
}

.tabs .active {
  opacity: 1;
  position: relative;
}

.tabs .active::after {
  bottom: -5px;
  height: 4px;
  border-radius: 10px;
  background-color: #8963F3;
  width: 100%;
  content: ' ';
  position: absolute;
  left: 0;
}

.tasks-board .grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 2.2fr 1fr;
      grid-template-columns: 2.2fr 1fr;
  -webkit-column-gap: 20px;
          grid-column-gap: 20px;
          column-gap: 20px;
      grid-template-areas: "left right";
}

@media (max-width: 768px) {
  .tasks-board .grid {
    display: block;
  }
}

.tasks-board .grid .left {
  -ms-grid-row: 1;
  -ms-grid-column: 1;
  grid-area: left;
}

.tasks-board .grid .right {
  -ms-grid-row: 1;
  -ms-grid-column: 2;
  grid-area: right;
}

.tasks-board .grid .right .header {
  margin-top: 15px;
  margin-bottom: 20px;
  margin-left: -5px;
  display: flex;
  align-items: center;
}

.tasks-board .grid .right .header h1 {
  font-size: 1.3em;
  font-weight: 600;
  margin-left: 5px;
  margin-bottom: 0;
}

@media (min-width: 1440px) {
  .tasks-board .grid .right .header h1 {
    font-size: 1.5em;
  }
}

.tasks-board .grid .right .days {
  max-height: 80vh;
  overflow-y: scroll;
  margin-bottom: 20px;
}

.tasks-board .grid .right .days .day {
  margin-bottom: 30px;
  padding-right: 10px;
}

.tasks-board .grid .right .days .day h2 {
  color: #8C9CAB;
  font-size: 1.1em;
  margin-bottom: 20px;
}

@media (min-width: 1440px) {
  .tasks-board .grid .right .days .day h2 {
    font-size: 1.1em;
  }
}

.tasks-board .grid .right .days .day .tasks .task {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.tasks-board .grid .right .days .day .tasks .task .group {
  display: flex;
  align-items: stretch;
}

.tasks-board .grid .right .days .day .tasks .task .group .hour {
  font-weight: 600;
  color: #33323D;
  font-size: 1.2em;
  padding-right: 20px;
  border-left: 0;
  border-right: 1px solid;
  display: flex;
  align-items: center;
}

@media (min-width: 1440px) {
  .tasks-board .grid .right .days .day .tasks .task .group .hour {
    font-size: 1.7em;
  }
}

.tasks-board .grid .right .days .day .tasks .task .group .description {
  padding-left: 20px;
  border-left: 1px solid;
  border-right: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.tasks-board .grid .right .days .day .tasks .task .group .description span {
  color: #B8BFCD;
  font-size: 0.8em;
  font-weight: 500;
}

@media (min-width: 1440px) {
  .tasks-board .grid .right .days .day .tasks .task .group .description span {
    font-size: 0.9em;
  }
}

.tasks-board .grid .right .days .day .tasks .task .group .description h3 {
  font-size: 1.1em;
  color: #8C9CAB;
  font-weight: 600;
}

@media (min-width: 1440px) {
  .tasks-board .grid .right .days .day .tasks .task .group .description h3 {
    font-size: 1.3em;
  }
}

.tasks-board .grid .right .days .day .tasks .task .group .yellow {
  border-color: #EC9B22;
  border-bottom: 0;
}

.tasks-board .grid .right .days .day .tasks .task .group .blue {
  border-color: #EC9B22;
  border-bottom: 0;
}

.tasks-board .grid .right .days .day .tasks .task .group .green {
  border-color: #0FBC9F;
  border-bottom: 0;
}

.tasks-board .grid .right .days .day .tasks .task button {
  border-radius: 10px;
  color: white;
  background: linear-gradient(45deg, #1D3AFF 0%, #922EFF 100%);
  padding: 10px 10px;
  font-size: 0.7em;
  font-weight: 600;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media (min-width: 1440px) {
  .tasks-board .grid .right .days .day .tasks .task button {
    padding: 12px 12px;
    font-size: 0.9em;
  }
}

.tasks-board .grid .right .days .day .tasks .task button:hover {
  opacity: 0.8;
}

.tasks-board .board {
  padding: 10px 0 0 0;
}

.tasks-board .board .yellow {
  border-top: 7px solid #F5D202;
  border-bottom: 0;
}

.tasks-board .board .yellow .category {
  background-color: #ECAF56;
}

.tasks-board .board .yellow .icon {
  background-color: #F5D202;
}

.tasks-board .board .blue {
  border-top: 7px solid #6DDAF7;
  border-bottom: 0;
}

.tasks-board .board .blue .category {
  background-color: #5127ED;
}

.tasks-board .board .blue .icon {
  background-color: #6DDAF7;
}

.tasks-board .board .green {
  border-top: 7px solid #0FBC9F;
  border-bottom: 0;
}

.tasks-board .board .green .category {
  background-color: #0FBC9F;
}

.tasks-board .board .green .icon {
  background-color: #0FBC9F;
}

.tasks-board .board .task {
  background-color: white;
  padding: 30px 30px;
  color: #33323D;
  border-radius: 5px;
  box-shadow: 0px 0px 20px #6389f327;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto auto auto auto;
      grid-template-columns: auto auto auto auto;
  -webkit-column-gap: 20px;
          grid-column-gap: 20px;
          column-gap: 20px;
  align-items: center;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .tasks-board .board .task {
    display: block;
    padding: 30px 30px;
  }
}

@media (max-width: 768px) {
  .tasks-board .board .task .column {
    margin-bottom: 20px;
  }
}

.tasks-board .board .task .header {
  margin-bottom: 15px;
}

.tasks-board .board .task .header .name {
  font-size: 0.9em;
  margin: 0;
  padding: 0;
}

.tasks-board .board .task .header .role {
  font-size: 0.8em;
  color: #7F858E;
  margin: 0;
  padding: 0;
}

.tasks-board .board .task h1 {
  font-size: 1.1em;
  margin-bottom: 10px;
}

@media (min-width: 1440px) {
  .tasks-board .board .task h1 {
    font-size: 1.4em;
  }
}

.tasks-board .board .task p {
  font-size: 0.75em;
  margin: 0;
}

@media (min-width: 1440px) {
  .tasks-board .board .task p {
    font-size: 0.9em;
  }
}

.tasks-board .board .task .second-column {
  -ms-grid-column-align: center;
      justify-self: center;
}

.tasks-board .board .task .second-column .category {
  color: white;
  padding: 6px 25px;
  border-radius: 100px;
  margin-bottom: 15px;
  display: inline-block;
  font-size: 0.8em;
}

.tasks-board .board .task .second-column .delivery {
  font-weight: 600;
}

.tasks-board .board .task .second-column .time {
  text-decoration: underline;
}

.tasks-board .board .task .third-column {
  -ms-grid-column-align: center;
      justify-self: center;
}

.tasks-board .board .task .third-column .status {
  border: 1px solid #E2E2E2;
  color: #33323D;
  text-transform: uppercase;
  padding: 9px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  font-weight: 600;
  font-size: 0.7em;
}

@media (min-width: 1440px) {
  .tasks-board .board .task .third-column .status {
    font-size: 0.9em;
  }
}

.tasks-board .board .task .third-column .status .icon {
  width: 10px;
  height: 10px;
  margin-right: 7px;
  display: block;
  border-radius: 100px;
}

.tasks-board .board .task .last-column {
  justify-self: flex-end;
}

.tasks-board .board .task .last-column .comment {
  margin-right: 5px;
  background-color: transparent;
}

.tasks-board .board .task .last-column .options {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  box-shadow: 0px 0px 20px #6389f32f;
  background-color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.tasks-board .board .task .last-column .options:hover {
  box-shadow: 0px 0px 20px #6389f360;
}

.tasks-board .board .task .last-column .options .icon {
  width: 5px;
  height: 5px;
  border-radius: 100px;
  background-color: #BCC4CF;
  margin: 0;
  margin-left: 1px;
  margin-right: 1px;
  content: '';
  display: inline-block;
}

@media (max-width: 1440px) and (min-width: 768px) {
  .c-subheader h1 div, .c-subheader h1 strong, .c-subheader h1 span {
    font-size: 30px;
  }
}

i {
  font-size: 1em;
}

.social-media-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  -webkit-column-gap: 20px;
          grid-column-gap: 20px;
          column-gap: 20px;
  grid-row-gap: 20px;
  row-gap: 20px;
}

@media (min-width: 1200px) {
  .social-media-grid {
    -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
  }
}

.social-media-grid .social-media-list-card {
  display: flex;
  padding: 10px;
  border-radius: 5px;
  align-items: stretch;
  color: #33323D;
  background-color: #F7F8FB;
  position: relative;
}

.social-media-grid .social-media-list-card .content {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.social-media-grid .social-media-list-card .content span {
  font-size: 13px;
  font-weight: 400;
}

@media (max-width: 1440px) {
  .social-media-grid .social-media-list-card .content span {
    font-size: 10px !important;
  }
}

.social-media-grid .social-media-list-card .content h2 {
  font-size: 1.4em;
  font-weight: bold;
  margin-bottom: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 250px;
}

@media (max-width: 1440px) {
  .social-media-grid .social-media-list-card .content h2 {
    font-size: 0.9em;
    max-width: 150px;
  }
}

.social-media-grid .social-media-list-card .social-media {
  width: 130px;
  height: 130px;
  border-radius: 10px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
}

@media (max-width: 1440px) {
  .social-media-grid .social-media-list-card .social-media {
    width: 60px;
    height: 60px;
    border-radius: 6px;
    font-size: 9px !important;
  }
  .social-media-grid .social-media-list-card .social-media i {
    font-size: 22px !important;
    margin-bottom: 5px;
  }
}

.social-media-grid .social-media-list-card .youtube {
  background-color: #FF0000;
}

.social-media-grid .social-media-list-card .facebook {
  background-color: #415A93;
}

.social-media-grid .social-media-list-card .twitter {
  background-color: #21ABE2;
}

.social-media-grid .social-media-list-card .pinterest {
  background-color: #CB2027;
}

.social-media-grid .social-media-list-card .instagram {
  background: #803cb3;
  background: linear-gradient(210deg, #803cb3 0%, #b3368f 16%, #ee0c20 58%, #f8ca01 100%);
}

.social-media-grid .social-media-list-card i {
  font-size: 3em;
  margin-bottom: 5px;
}

.social-media-grid .social-media-list-card .buttons {
  position: absolute;
  top: 10px;
  right: 10px;
}

.social-media-grid .social-media-list-card .buttons .smbutton {
  display: inline-block;
  color: #0676ED;
  font-size: 5px;
  background-color: transparent;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.social-media-grid .social-media-list-card .buttons .smbutton:hover {
  opacity: 0.6;
}

@media (max-width: 1440px) {
  .social-media-grid .social-media-list-card .buttons .smbutton i {
    font-size: 12px;
  }
}

.social-proof .Toastify__toast {
  border-radius: 10px !important;
  padding: 15px !important;
}

.comparison-scroll {
  max-width: 100%;
  overflow-x: auto;
}

.comparison-grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 400px 250px 250px;
      grid-template-columns: 400px 250px 250px;
  -webkit-column-gap: 10px;
          grid-column-gap: 10px;
          column-gap: 10px;
  font-size: 12px;
}

@media (min-width: 991px) {
  .comparison-grid {
    -ms-grid-columns: 2fr 1fr 1fr;
        grid-template-columns: 2fr 1fr 1fr;
  }
}

@media (min-width: 1400px) {
  .comparison-grid {
    font-size: 14px;
  }
}

.comparison-grid .comparison-column-2 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto auto;
      grid-template-columns: auto auto;
}

@media (min-width: 991px) {
  .comparison-grid .comparison-column-2 {
    -ms-grid-columns: 50% 50%;
        grid-template-columns: 50% 50%;
  }
}

.comparison-grid .comparison-heading .comparison-row {
  font-weight: bold;
  justify-content: flex-end !important;
  font-size: 1.1em;
  text-align: right;
  border-radius: 10px 0px 0px 10px !important;
}

@media (min-width: 1600px) {
  .comparison-grid .comparison-heading .comparison-row {
    font-size: 1.3em;
  }
}

.comparison-grid .comparison-user .comparison-row {
  border-radius: 0px 10px 10px 0px !important;
}

.comparison-grid .comparison-column .comparison-row {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.comparison-grid .comparison-column .comparison-row:nth-child(2n) {
  background-color: #dfdded5b;
  border-radius: 10px;
}

.comparison-grid .comparison-column .comparison-row .comparison-logo {
  max-height: 98%;
}

.comparison-grid .comparison-column .comparison-row .comparison-metric-primary {
  color: #0676ED;
}

.comparison-grid .comparison-column .comparison-row .comparison-metric-secondary {
  color: #8963F3;
}

.comparison-grid .comparison-column .comparison-row .comparison-metric-primary, .comparison-grid .comparison-column .comparison-row .comparison-metric-secondary {
  text-align: center;
}

.comparison-grid .comparison-column .comparison-row .comparison-metric-primary .metric-value, .comparison-grid .comparison-column .comparison-row .comparison-metric-secondary .metric-value {
  font-size: 2em;
  line-height: 1em;
  font-weight: bold;
  margin: 0;
}

.comparison-grid .comparison-column .comparison-row .comparison-metric-primary .metric-label, .comparison-grid .comparison-column .comparison-row .comparison-metric-secondary .metric-label {
  font-size: 0.8em;
  font-weight: 500;
}

.comparison-grid .comparison-column .comparison-row .comparison-metric-primary .metric-text, .comparison-grid .comparison-column .comparison-row .comparison-metric-secondary .metric-text {
  font-size: 1.3em;
  font-weight: 600;
}

.comparison-grid .comparison-column .comparison-row .comparison-inner-flex-50 {
  width: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.comparison-grid .comparison-column .comparison-row .comparison-icon {
  height: 20px;
  display: inline-block;
  margin-left: 5px;
}

.comparison-grid .comparison-column .comparison-row .comparison-engagement {
  display: flex;
  align-items: center;
}

.comparison-grid .comparison-column .comparison-row .comparison-engagement img {
  height: 30px;
  margin-right: 10px;
}

.comparison-grid .comparison-column .comparison-row .comparison-engagement span {
  font-size: 1.2em;
  color: #0676ED;
  font-weight: 500;
}

.comparison-grid .comparison-column .comparison-row .comparison-sm {
  display: flex;
  align-items: center;
  justify-content: center;
}

.comparison-grid .comparison-column .comparison-row .comparison-sm img {
  height: 30px;
}

.comparison-grid .comparison-column .comparison-row .comparison-sm .comparison-sm-text {
  color: #0676ED;
  font-size: 0.9em;
  line-height: 1.05em;
  margin-left: 5px;
  font-weight: 500;
  max-width: 115px;
  margin-bottom: 0;
  margin-top: 2px;
}

.comparison-grid .comparison-column .comparison-row .comparison-post {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr auto;
      grid-template-columns: 1fr auto;
  justify-content: center;
  align-items: center;
  -webkit-column-gap: 10px;
          grid-column-gap: 10px;
          column-gap: 10px;
}

@media (min-width: 1400px) {
  .comparison-grid .comparison-column .comparison-row .comparison-post {
    max-width: 270px;
  }
}

@media (min-width: 1600px) {
  .comparison-grid .comparison-column .comparison-row .comparison-post {
    max-width: 300px;
  }
}

.comparison-grid .comparison-column .comparison-row .comparison-post .comparison-post-info {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 40px auto;
      grid-template-columns: 40px auto;
  align-items: center;
  -webkit-column-gap: 5px;
          grid-column-gap: 5px;
          column-gap: 5px;
}

@media (min-width: 1400px) {
  .comparison-grid .comparison-column .comparison-row .comparison-post .comparison-post-info {
    -ms-grid-columns: 50px auto;
        grid-template-columns: 50px auto;
    -webkit-column-gap: 10px;
            grid-column-gap: 10px;
            column-gap: 10px;
  }
}

@media (min-width: 1600px) {
  .comparison-grid .comparison-column .comparison-row .comparison-post .comparison-post-info {
    -ms-grid-columns: 55px auto;
        grid-template-columns: 55px auto;
  }
}

.comparison-grid .comparison-column .comparison-row .comparison-post .comparison-post-info .comparison-post-image {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-size: cover;
  background-position: center;
  background-color: #0676ED;
  margin: 0;
}

@media (min-width: 1400px) {
  .comparison-grid .comparison-column .comparison-row .comparison-post .comparison-post-info .comparison-post-image {
    width: 50px;
    height: 50px;
  }
}

@media (min-width: 1600px) {
  .comparison-grid .comparison-column .comparison-row .comparison-post .comparison-post-info .comparison-post-image {
    width: 55px;
    height: 55px;
  }
}

.comparison-grid .comparison-column .comparison-row .comparison-post .comparison-post-info .comparison-post-content .comparison-post-text {
  font-size: 0.70em;
  line-height: 1em;
}

@media (min-width: 1600px) {
  .comparison-grid .comparison-column .comparison-row .comparison-post .comparison-post-info .comparison-post-content .comparison-post-text {
    font-size: 0.8em;
  }
}

.comparison-grid .comparison-column .comparison-row .comparison-post .comparison-post-info .comparison-post-content .comparison-post-reactions {
  display: flex;
  font-size: 0.9em;
  line-height: 1em;
  margin-top: 5px;
}

.comparison-grid .comparison-column .comparison-row .comparison-post .comparison-post-info .comparison-post-content .comparison-post-reactions .comparison-post-likes {
  margin-right: 7px;
  color: #EC5569;
  display: flex;
}

.comparison-grid .comparison-column .comparison-row .comparison-post .comparison-post-info .comparison-post-content .comparison-post-reactions .comparison-post-likes span {
  margin-left: 3px;
}

.comparison-grid .comparison-column .comparison-row .comparison-post .comparison-post-info .comparison-post-content .comparison-post-reactions .comparison-post-comments {
  color: #60B8FE;
  display: flex;
}

.comparison-grid .comparison-column .comparison-row .comparison-post .comparison-post-info .comparison-post-content .comparison-post-reactions .comparison-post-comments span {
  margin-left: 3px;
}

.comparison-grid .comparison-column .comparison-row .comparison-post .comparison-post-action {
  text-align: center;
}

.comparison-grid .comparison-column .comparison-row .comparison-post .comparison-post-action .comparison-post-value {
  font-size: 1.1em;
  line-height: 1em;
  font-weight: bold;
}

@media (min-width: 1600px) {
  .comparison-grid .comparison-column .comparison-row .comparison-post .comparison-post-action .comparison-post-value {
    font-size: 1.2em;
  }
}

.comparison-grid .comparison-column .comparison-row .comparison-post .comparison-post-action .comparison-post-link {
  white-space: nowrap;
  font-size: 0.65em;
  line-height: 1em;
  padding: 7px 6px;
  border-radius: 5px;
  margin-top: 5px;
  display: block;
  color: white;
  background: #8963f3;
  background: linear-gradient(141deg, #8963f3 0%, #ce5bcb 100%);
}

@media (min-width: 1600px) {
  .comparison-grid .comparison-column .comparison-row .comparison-post .comparison-post-action .comparison-post-link {
    padding: 7px 10px;
  }
}

#persona-sms .chakra-checkbox__control {
  background-color: white;
}

.persona-placeholder {
  font-size: 18px;
  font-weight: bold;
  display: block;
  font-family: "Poppins", sans-serif !important;
  color: #33323D;
  margin-right: 10px;
  display: inline;
}

.persona-value-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
/*# sourceMappingURL=style.css.map */
.social-proof {
    background-color: white;
    padding: 15px 25px 15px 25px;
    border-radius: 5px;
    position: fixed;
    bottom: 80px;
    right: 20px;
    width: 350px;
    height: auto;
    z-index: 999999;
    box-shadow: 0px 2px 22px 1px rgba(51,67,224,0.2);
    font-family: 'Poppins', sans-serif!important;
    cursor: pointer;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    opacity: 0.9;
}

@media (max-width: 991px){
    .social-proof {
        top: 20px;
        left: 20px;
        right: 20px;
        width: auto;
        bottom: auto;
    }
}

.social-proof:hover {
    opacity: 1!important;
}

.social-proof-title {
    font-weight: 500;
    font-size: 1em;
    margin-bottom: 15px;
}

.social-proof-flex {
    display: grid;
    align-items: center;
    grid-template-columns: 60px auto;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
}

.social-proof-image {
    background-size: cover;
    background-position: center;
    width: 60px;
    height: 60px;
    border-radius: 3px;
}

.social-proof-content {
    font-size: 14px;
}

.social-proof-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    background: rgb(151,105,255);
    background: linear-gradient(141deg, rgba(151,105,255,1) 0%, rgba(29,58,255,1) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    color: white;
    font-size: 13px;
    fill: white;
}

.social-proof-control-wrapper {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99999999;;
}

.social-proof-control-button {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 100px;
    background: rgb(151,105,255);
    background: linear-gradient(141deg, rgba(151,105,255,1) 0%, rgba(29,58,255,1) 100%);
}

.social-proof-pause-play {
    position: absolute;
    top: -2px;
    right: -2px;
    background-color: white;
    width: 20px;
    height: 20px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.social-proof-notifications {
    background-color: white;
    padding: 20px 15px 25px 15px;
    border-radius: 5px;
    position: fixed;
    bottom: 90px;
    right: 20px;
    width: 350px;
    max-width: 100%;
    height: auto;
    z-index: 999999;
    box-shadow: 0px 2px 22px 1px rgba(51,67,224,0.2);
    font-family: 'Poppins', sans-serif!important;
}

.social-proof-notifications .social-proof-flex {
    padding-bottom: 15px;
    padding-top: 15px;
    margin-right: 15px;
    border-bottom: 1px solid rgba(0,0,0,0.05);
    cursor: pointer;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    padding-left: 15px;
    padding-right: 15px;
}

.social-proof-notifications .social-proof-content {
    font-size: 0.75em;
}

.social-proof-notifications .social-proof-flex:hover {
    background-color: rgba(0,0,0,.03);
}

.social-proof-notifications-body {
    max-height: 400px;
    overflow-y: scroll;
}

.social-proof-notifications .social-proof-close-button {
    right: 15px;
    top: 15px;
}

.social-proof-notifications-header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
}

.social-proof-notifications-header .social-proof-title {
    margin-left: 3px;
    margin-bottom: 0px;
}
